import HttpStatusCodes from "./httpStatusCodes";
export default (function (statusCode) {
    switch (statusCode) {
        case HttpStatusCodes.SUCCESS_OK:
        case HttpStatusCodes.SUCCESS_CREATED:
        case HttpStatusCodes.SUCCESS_ACCEPTED:
        case HttpStatusCodes.SUCCESS_NONAUTHORITATIVE_INFORMATION:
        case HttpStatusCodes.SUCCESS_NO_CONTENT:
        case HttpStatusCodes.SUCCESS_RESET_CONTENT:
        case HttpStatusCodes.SUCCESS_PARTIAL_CONTENT:
            return HttpStatusCodes.SUCCESS_OK;
        case HttpStatusCodes.REDIRECT_MOVED_PERMANENTLY:
        case HttpStatusCodes.REDIRECT_OBJECT_MOVED:
        case HttpStatusCodes.REDIRECT_NOT_MODIFIED:
        case HttpStatusCodes.REDIRECT_TEMPORARY_REDIRECT:
            return HttpStatusCodes.REDIRECT;
        case HttpStatusCodes.BAD_REQUEST:
        case HttpStatusCodes.BAD_REQUEST_INVALID_DESTINATION_HEADER:
        case HttpStatusCodes.BAD_REQUEST_INVALID_DEPTH_HEADER:
        case HttpStatusCodes.BAD_REQUEST_INVALID_IF_HEADER:
        case HttpStatusCodes.BAD_REQUEST_INVALID_OVERWRITE_HEADER:
        case HttpStatusCodes.BAD_REQUEST_INVALID_TRANSLATE_HEADER:
        case HttpStatusCodes.BAD_REQUEST_INVALID_REQUEST_BODY:
        case HttpStatusCodes.BAD_REQUEST_INVALID_CONTENT_LENGTH:
        case HttpStatusCodes.BAD_REQUEST_INVALID_TIMEOUT:
        case HttpStatusCodes.BAD_REQUEST_INVALID_LOCK_TOKEN:
            return HttpStatusCodes.BAD_REQUEST;
        case HttpStatusCodes.ACCESS_DENIED:
        case HttpStatusCodes.ACCESS_DENIED_LOGON_FAILED_SERVER_CONFIGURATION:
        case HttpStatusCodes.ACCESS_DENIED_UNAUTHORIZED_DUE_TO_ACL:
        case HttpStatusCodes.ACCESS_DENIED_AUTHORIZATION_FAILED_FILTER:
        case HttpStatusCodes.ACCESS_DENIED_AUTHORIZATION_FAILED_ISAPI_APPLICATION:
        case HttpStatusCodes.ACCESS_DENIED_TOO_MANY_REQUESTS:
        case HttpStatusCodes.ACCESS_DENIED_TOO_MANY_REQUESTS_ALT:
        case HttpStatusCodes.ACCESS_DENIED_IP_RESTRICTED:
        case HttpStatusCodes.ACCESS_DENIED_HOST_NAME_RESTRICTED:
            return HttpStatusCodes.ACCESS_DENIED;
        case HttpStatusCodes.FORBIDDEN:
        case HttpStatusCodes.FORBIDDEN_EXECUTE_ACCESS_FORBIDDEN:
        case HttpStatusCodes.FORBIDDEN_READ_ACCESS_FORBIDDEN:
        case HttpStatusCodes.FORBIDDEN_WRITE_ACCESS_FORBIDDEN:
        case HttpStatusCodes.FORBIDDEN_SSL_REQUIRED:
        case HttpStatusCodes.FORBIDDEN_SSL_128_REQUIRED:
        case HttpStatusCodes.FORBIDDEN_IP_ADDRESS_REJECTED:
        case HttpStatusCodes.FORBIDDEN_CLIENT_CERTIFICATE_REQUIRED:
        case HttpStatusCodes.FORBIDDEN_SITE_ACCESS_DEINED:
        case HttpStatusCodes.FORBIDDEN_TO_MANY_CLIENT_CONNECTIONS:
        case HttpStatusCodes.FORBIDDEN_SERVER_DENIED_EXECUTE_ACCESS:
        case HttpStatusCodes.FORBIDDEN_PASSWORD_HAS_BEEN_CHANGED:
        case HttpStatusCodes.FORBIDDEN_MAPPER_DENIED_ACCESS:
        case HttpStatusCodes.FORBIDDEN_CLIENT_CERTIFICATE_REVOKED:
        case HttpStatusCodes.FORBIDDEN_DIRECTORY_LISTING_DENIED:
        case HttpStatusCodes.FORBIDDEN_CLIENT_ACCESS_LICENSES_EXCEEDED:
        case HttpStatusCodes.FORBIDDEN_CLIENT_CERTIFICATE_NOT_ACCEPTED:
        case HttpStatusCodes.FORBIDDEN_CLIENT_CERTIFICATE_EXPIRED:
        case HttpStatusCodes.FORBIDDEN_CANNOT_EXECUTE_URL_IN_CURRENT_APPLICATION_POOL:
        case HttpStatusCodes.FORBIDDEN_CANNOT_EXECUTE_CGI_APPLICATIONS_IN_APPLICATION_POOL:
        case HttpStatusCodes.FORBIDDEN_PASSPORT_LOGON_FAILED:
        case HttpStatusCodes.FORBIDDEN_SOURCE_ACCESS_DENIED:
        case HttpStatusCodes.FORBIDDEN_INFINITE_DEPTH_DENIED:
        case HttpStatusCodes.FORBIDDEN_TOO_MANY_REQUESTS:
        case HttpStatusCodes.FORBIDDEN_TOO_MANY_REQUESTS_ALT:
        case HttpStatusCodes.FORBIDDEN_IP_RESTRICTED:
        case HttpStatusCodes.FORBIDDEN_HOST_NAME_RESTRICTED:
            return HttpStatusCodes.FORBIDDEN;
        case HttpStatusCodes.NOT_FOUND:
        case HttpStatusCodes.NOT_FOUND_SITE_NOT_FOUND:
        case HttpStatusCodes.NOT_FOUND_ISAPI_OR_CGI_RESTRICTION:
        case HttpStatusCodes.NOT_FOUND_MIME_TYPE_RESTRICTION:
        case HttpStatusCodes.NOT_FOUND_NO_HANDLER_CONFIGURED:
        case HttpStatusCodes.NOT_FOUND_DENIED_BY_REQUEST_FILTERING:
        case HttpStatusCodes.NOT_FOUND_VERB_DENIED:
        case HttpStatusCodes.NOT_FOUND_FILE_EXTENSION_DENIED:
        case HttpStatusCodes.NOT_FOUND_HIDDEN_NAMESPACE:
        case HttpStatusCodes.NOT_FOUND_FILE_ATTRIBUTE_HIDDEN:
        case HttpStatusCodes.NOT_FOUND_REQUEST_HEADER_TOO_LONG:
        case HttpStatusCodes.NOT_FOUND_REQUEST_CONTAINS_DOUBLE_ESCAPE_SEQUENCE:
        case HttpStatusCodes.NOT_FOUND_REQUEST_CONTAINS_HIGH_BIT_CHARACTERS:
        case HttpStatusCodes.NOT_FOUND_CONTENT_TOO_LARGE:
        case HttpStatusCodes.NOT_FOUND_REQUEST_URL_TOO_LONG:
        case HttpStatusCodes.NOT_FOUND_QUERY_STRING_TOO_LONG:
        case HttpStatusCodes.NOT_FOUND_DAV_REQUEST_SENT_TO_STATIC_FILE_HANDLER:
        case HttpStatusCodes.NOT_FOUND_DYNAMIC_CONTENT_MAPPED_TO_STATIC_FILE_HANDLER_VIA_WILDCARD_MIME_MAPPING:
        case HttpStatusCodes.NOT_FOUND_QUERY_STRING_SEQUENCE_DENIED:
        case HttpStatusCodes.NOT_FOUND_DENIED_BY_FILTERING_RULE:
        case HttpStatusCodes.NOT_FOUND_TOO_MANY_URL_SEGMENTS:
        case HttpStatusCodes.NOT_FOUND_TOO_MANY_REQUESTS:
        case HttpStatusCodes.NOT_FOUND_TOO_MANY_REQUESTS_ALT:
        case HttpStatusCodes.NOT_FOUND_IP_RESTRICTED:
        case HttpStatusCodes.NOT_FOUND_HOST_NAME_RESTRICTED:
        case HttpStatusCodes.NOT_FOUND_METHOD_NOT_ALLOWED:
        case HttpStatusCodes.NOT_FOUND_CLIENT_BROWSER_DOES_NOT_ACCEPT_MIME_TYPE_REQUESTED:
        case HttpStatusCodes.NOT_FOUND_REQUEST_TIMED_OUT:
        case HttpStatusCodes.NOT_FOUND_PRECONDITION_FAILED:
            return HttpStatusCodes.NOT_FOUND;
        case HttpStatusCodes.SERVER_ERROR:
        case HttpStatusCodes.SERVER_ERROR_APPLICATION_SHUTTING_DOWN:
        case HttpStatusCodes.SERVER_ERROR_APPLICATION_RESTARTING:
        case HttpStatusCodes.SERVER_ERROR_SERVER_TOO_BUSY:
        case HttpStatusCodes.SERVER_ERROR_DIRECT_REQUEST_NOT_ALLOWED:
        case HttpStatusCodes.SERVER_ERROR_CONFIGURATION_DATA_INVALID:
        case HttpStatusCodes.SERVER_ERROR_MODULE_NOT_RECOGNIZED:
        case HttpStatusCodes.SERVER_ERROR_ASPNET_HTTPMODULES_CONFIGURATION_DOES_NOT_APPLY:
        case HttpStatusCodes.SERVER_ERROR_ASPNET_HTTPHANDLERS_CONFIGURATION_DOES_NOT_APPLY:
        case HttpStatusCodes.SERVER_ERROR_ASPNET_IMPERSONATION_CONFIGURATION_DOES_NOT_APPLY:
        case HttpStatusCodes.SERVER_ERROR_DURING_REWRITE_RQ_BEGIN_REQUEST:
        case HttpStatusCodes.SERVER_ERROR_DURING_REWRITE_GL_PRE_BEGIN_REQUEST:
        case HttpStatusCodes.SERVER_ERROR_DURING_REWRITE_RQ_SEND_RESPONSE:
        case HttpStatusCodes.SERVER_ERROR_DURING_REWRITE_RQ_RELEASE_REQUEST_STATE:
        case HttpStatusCodes.SERVER_ERROR_INTERNAL_APS_ERROR:
            return HttpStatusCodes.SERVER_ERROR;
        case HttpStatusCodes.SERVICE_UNAVAILABLE:
        case HttpStatusCodes.SERVICE_UNAVAILABLE_CONCURRENT_REQUEST_LIMIT_EXCEEDED:
        case HttpStatusCodes.SERVICE_UNAVAILABLE_ASPNET_QUEUE_FULL:
        case HttpStatusCodes.SERVICE_UNAVAILABLE_FAST_CGI_QUEUE_FULL:
            return HttpStatusCodes.SERVICE_UNAVAILABLE;
        default:
            return statusCode;
    }
});
