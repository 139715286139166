var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import Button from '../../button/Button';
import Dropdown from '../../forms/Dropdown';
import TextField from '../../forms/TextField';
import QueryBuilderOperator from './queryBuilderOperator';
import QueryConditionPropertyTypes from './queryConditionPropertyTypes';
import MoneyField from '../../forms/MoneyField';
import IntField from '../../forms/IntField';
import classNames from 'classnames';
import DateField from '../../forms/DateField';
import DecimalField from '../../forms/DecimalField';
import { getOperatorsByPropertyType } from './queryBuilderOperatorManager';
export default (function (_a) {
    var props = __rest(_a, []);
    var propertyChanged = function (selectedValue) {
        var _a;
        var newQueryCondition;
        if (selectedValue) {
            newQueryCondition = __assign(__assign({}, props.queryCondition), { property: selectedValue, propertyType: (_a = props.propertyOptions.find(function (type) { return type.value === selectedValue; })) === null || _a === void 0 ? void 0 : _a.type, operator: undefined, values: [] });
        }
        else {
            newQueryCondition = __assign(__assign({}, props.queryCondition), { property: undefined, propertyType: undefined, operator: undefined, values: [] });
        }
        props.onChange(newQueryCondition);
    };
    var operatorChanged = function (selectedValue) {
        var newQueryCondition;
        if (selectedValue) {
            newQueryCondition = __assign(__assign({}, props.queryCondition), { operator: selectedValue });
        }
        else {
            newQueryCondition = __assign(__assign({}, props.queryCondition), { operator: undefined });
        }
        props.onChange(newQueryCondition);
    };
    var valueFieldChanged = function (index, newValue) {
        var newQueryCondition = __assign(__assign({}, props.queryCondition), { values: __spreadArray([], props.queryCondition.values, true) });
        newQueryCondition.values[index] = newValue;
        props.onChange(newQueryCondition);
    };
    var getField = function (index) {
        if ([
            undefined,
            QueryBuilderOperator.NotBlank,
            QueryBuilderOperator.Blank,
            QueryBuilderOperator.IsTrue,
            QueryBuilderOperator.NotTrue
        ].indexOf(props.queryCondition.operator) >= 0) {
            return null;
        }
        switch (props.queryCondition.propertyType) {
            case QueryConditionPropertyTypes.Money:
                return React.createElement(MoneyField, { className: classNames("inline-label v-center text-small fill", { 'ml-1': index > 0 }), name: "values[".concat(index, "]"), value: props.queryCondition.values[index], onChange: function (newValue) { valueFieldChanged(index, newValue); } });
            case QueryConditionPropertyTypes.Decimal:
                return React.createElement(DecimalField, { className: classNames("inline-label v-center text-small fill", { 'ml-1': index > 0 }), name: "values[".concat(index, "]"), value: props.queryCondition.values[index], onChange: function (newValue) { valueFieldChanged(index, newValue); } });
            case QueryConditionPropertyTypes.Integer:
                return React.createElement(IntField, { className: classNames("inline-label v-center text-small fill", { 'ml-1': index > 0 }), name: "values[".concat(index, "]"), value: props.queryCondition.values[index], onChange: function (newValue) { valueFieldChanged(index, newValue); } });
            case QueryConditionPropertyTypes.Boolean:
                return null;
            case QueryConditionPropertyTypes.Date:
                return React.createElement(DateField, { className: classNames("inline-label v-center text-small fill", { 'ml-1': index > 0 }), name: "values[".concat(index, "]"), value: props.queryCondition.values[index], onChange: function (newValue) { valueFieldChanged(index, newValue); } });
            default:
                return React.createElement(TextField, { className: classNames("inline-label v-center text-small fill", { 'ml-1': index > 0 }), name: "values[".concat(index, "]"), value: props.queryCondition.values[index], onChange: function (newValue) { valueFieldChanged(index, newValue); } });
        }
    };
    return (React.createElement("div", { className: "flex w-fill" },
        React.createElement(Dropdown, { className: "inline-label text-small v-center w-15 ml-1", name: "property", options: props.propertyOptions, onChange: propertyChanged, value: props.queryCondition.property }),
        React.createElement(Dropdown, { className: "inline-label text-small v-center w-8 ml-1", name: "operator", options: getOperatorsByPropertyType(props.queryCondition.propertyType), onChange: operatorChanged, value: props.queryCondition.operator }),
        React.createElement("div", { className: "v-center fill flex ml-1" },
            getField(0),
            props.queryCondition.operator &&
                [QueryBuilderOperator.Between, QueryBuilderOperator.NotBetween].indexOf(props.queryCondition.operator) >= 0 &&
                React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "ml-1 v-center text-small text-faint" }, "and"),
                    getField(1))),
        React.createElement(Button, { type: "button", className: "button-x-small primary link circle ml-1 mr-1 v-center", leftIcon: "trash", onClick: props.onRemoved })));
});
