import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import Button from '../../components/button/Button';
import Heading from '../../components/heading/Heading';
import { useSession } from '../../hooks/session/useSession';
import { useNavigate } from 'react-router-dom';
import FormikTextField from '../../components/formik/FormikTextField';
import { useLogin } from '../../api/login/loginApi';
export default (function () {
    var _a = useLogin(), fetch = _a.fetch, isWaiting = _a.isWaiting, hasError = _a.hasError, data = _a.data;
    var navigate = useNavigate();
    var _b = useSession(), session = _b.session, setSession = _b.setSession;
    useEffect(function () {
        if (session) {
            navigate('/agent');
        }
    }, []);
    useEffect(function () {
        if (data && !hasError) {
            setSession(data);
            navigate('/agent');
        }
    }, [data]);
    var submited = function (loginData) {
        fetch("grant_type=password" +
            "&username=".concat(loginData.email) +
            "&password=".concat(loginData.password));
    };
    if (session) {
        return null;
    }
    return (React.createElement("div", { className: "window-fill" },
        React.createElement("div", { className: "v-center h-center w-20" },
            React.createElement(Heading, { className: "text-center", level: "4" }, "LOGIN"),
            hasError &&
                React.createElement("div", { className: "mt-2 text-danger text-center" }, "Incorrect username or password"),
            React.createElement(Formik, { initialValues: {
                    email: '',
                    password: ''
                }, validationSchema: Yup.object({
                    email: Yup
                        .string()
                        .required('Required')
                        .email(),
                    password: Yup
                        .string()
                        .required('Required')
                }), onSubmit: submited }, function (props) { return (React.createElement(Form, { className: "mt-2" },
                React.createElement(FormikTextField, { name: "email", label: "Email" }),
                React.createElement(FormikTextField, { name: "password", label: "Password", hideValue: true }),
                React.createElement("div", { className: "mt-1 flex" },
                    React.createElement(Button, { type: "submit", text: isWaiting ? 'Logging In...' : 'Login', className: "primary fill", disabled: isWaiting })))); }))));
});
