import React from 'react';
import ReactDOM from 'react-dom';
import './styles/main.less';
import 'normalize.css';
import AppRoot from './parts/root/AppRoot';
import 'react-loading-skeleton/dist/skeleton.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
library.add(fas, fad, far, fal);
ReactDOM.render(React.createElement(AppRoot, null), document.getElementById('root'));
