import classNames from 'classnames';
import React, { useState } from 'react';
import Button from '../../button/Button';
import Card from '../../card/Card';
import Json from '../../formatters/Json';
import Icon from '../../icons/Icon';
import IconStyles from '../../icons/iconStyles';
import Text from '../../text/Text';
export default (function (_a) {
    var message = _a.message, icon = _a.icon, heading = _a.heading, className = _a.className, headingClassName = _a.headingClassName, iconClassName = _a.iconClassName, messageClassName = _a.messageClassName, detailsMessage = _a.detailsMessage, detailsClassName = _a.detailsClassName;
    var _b = useState(false), isDetailsExpanded = _b[0], setIsDetailsExpanded = _b[1];
    var toggleDetailsExpanded = function () {
        setIsDetailsExpanded(!isDetailsExpanded);
    };
    var setIsDetailMessageJson = function () {
        if (!detailsMessage) {
            return false;
        }
        try {
            JSON.parse(detailsMessage);
            return true;
        }
        catch (err) {
            return false;
        }
    };
    var isDetailMessageJson = setIsDetailMessageJson();
    return (React.createElement(Card, { className: className },
        React.createElement("div", { className: classNames("flex", { "p-8": !isDetailsExpanded }, { "pt-2 pb-2": isDetailsExpanded }, "pl-2 pr-2") },
            React.createElement(Icon, { style: IconStyles.Duotone, className: classNames("text-xxx-large text-faint", iconClassName), icon: icon !== null && icon !== void 0 ? icon : "bug" }),
            React.createElement("div", { className: classNames("ml-4 w-fill", headingClassName) },
                React.createElement("b", { className: "text-danger text-x-large" },
                    !!heading &&
                        React.createElement("span", null, heading),
                    !heading &&
                        React.createElement("span", null, "Unable to load Table")),
                React.createElement("br", null),
                React.createElement(Text, { className: classNames("text-faint", messageClassName) },
                    !!message &&
                        React.createElement("span", null, message),
                    !message &&
                        React.createElement("span", null, "An error occurred loading the data for this table")),
                detailsMessage &&
                    React.createElement("div", null,
                        isDetailsExpanded &&
                            React.createElement("div", { className: classNames("error-detail mt-1 text-light text-small text-faint", detailsClassName) },
                                React.createElement("b", { className: "text-danger" }, "Error Detail"),
                                isDetailMessageJson &&
                                    React.createElement(Json, { jsonString: detailsMessage }),
                                !isDetailMessageJson &&
                                    React.createElement("div", null, detailsMessage)),
                        React.createElement(Button, { className: "primary link button-small button-inline mt-1", text: isDetailsExpanded ? "Hide Details" : "More Details", onClick: toggleDetailsExpanded }))))));
});
