import QueryBuilderOperator from "./queryBuilderCondition/queryBuilderOperator";
export var isConditionSetValid = function (conditionSet) {
    var isConditionValid = function (condition) {
        if (!condition.property || !condition.operator) {
            return false;
        }
        if ([QueryBuilderOperator.Between, QueryBuilderOperator.NotBetween].indexOf(condition.operator) >= 0 &&
            (condition.values[0] === undefined || condition.values[0] === null) &&
            (condition.values[1] === undefined || condition.values[1] === null)) {
            return false;
        }
        if ([
            QueryBuilderOperator.NotBlank,
            QueryBuilderOperator.Blank,
            QueryBuilderOperator.IsTrue,
            QueryBuilderOperator.NotTrue
        ].indexOf(condition.operator) < 0 &&
            (condition.values[0] === undefined || condition.values[0] === null)) {
            return false;
        }
        return true;
    };
    try {
        for (var i = 0; i < conditionSet.conditions.length; i++) {
            if (!isConditionValid(conditionSet.conditions[i])) {
                return false;
            }
        }
        for (var i = 0; i < conditionSet.conditionSets.length; i++) {
            if (!isConditionSetValid(conditionSet.conditionSets[i])) {
                return false;
            }
        }
        return true;
    }
    catch (err) {
        return false;
    }
};
