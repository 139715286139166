import React from "react";
import QueryBuilderPropertyType from "../../components/queryBuilder/queryBuilderCondition/queryConditionPropertyTypes";
import TableColumnValueTypes from "../../components/table/tableColumns/tableColumnValueTypes";
import DataViewType from "../../dataViews/dataViewTypes";
import truncate from "../../utils/strings/truncate";
export default (function () {
    var columns = [
        {
            name: 'Id',
            heading: 'Id',
            cellClassName: 'fit-content',
            headingClassName: 'fit-content',
            sortExpression: 'Logs.Id',
            valueType: TableColumnValueTypes.Number,
            cellTemplate: function (item) { return React.createElement("span", null, item.id); },
            index: 0,
            enabled: true
        },
        {
            name: 'Level',
            heading: 'Level',
            cellClassName: 'fit-content',
            headingClassName: 'fit-content',
            sortExpression: 'Logs.Level',
            valueType: TableColumnValueTypes.String,
            cellTemplate: function (item) { return React.createElement("span", null, item.level); },
            index: 1,
            enabled: true,
        },
        {
            name: 'Message',
            heading: 'Message',
            sortExpression: 'Logs.Message',
            valueType: TableColumnValueTypes.String,
            cellTemplate: function (item) { return React.createElement("span", null, formatLogMessageSummary(item.message)); },
            index: 1,
            enabled: true,
        },
        {
            name: 'Exception',
            heading: 'Exception',
            sortExpression: 'Logs.Exception',
            valueType: TableColumnValueTypes.String,
            cellTemplate: function (item) { return React.createElement("span", null, formatExceptionSummay(item.exception)); },
            index: 1,
            enabled: true,
        },
        {
            name: 'IsResolved',
            heading: 'Is Resolved',
            cellClassName: 'fit-content',
            headingClassName: 'fit-content',
            cellTemplate: function (item) { return React.createElement("span", null, item.resolved); },
            index: 1,
            enabled: true,
        },
    ];
    var defaultViews = [{
            isDefault: true,
            id: -1,
            name: 'Default View',
            dataViewType: DataViewType.Logs,
            recordsPerPage: 25,
            sortExpression: 'Logs.CreatedDateTime DESC',
            columns: columns,
            index: 0
        }];
    var queryConditionPropertyOptions = [
        { label: 'Level', value: 'Logs.Level', type: QueryBuilderPropertyType.String },
        { label: 'Is Resolved', value: 'Logs.Resolved', type: QueryBuilderPropertyType.Boolean },
        { label: 'Message', value: 'Logs.Message', type: QueryBuilderPropertyType.String },
        { label: 'Exception', value: 'Logs.Exception', type: QueryBuilderPropertyType.String },
        { label: 'Date/Time', value: 'Logs.CreatedDateTime', type: QueryBuilderPropertyType.Date },
    ];
    var unhandledExceptionMessage = 'Server UnhandledException logging;';
    var isUnhandledException = function (message) {
        return message.startsWith(unhandledExceptionMessage);
    };
    var formatLogMessageSummary = function (message) {
        if (!message) {
            return message;
        }
        if (isUnhandledException(message)) {
            return getUrlFromMessage(message);
        }
        return truncate(message, 75);
    };
    var getUrlFromMessage = function (message) {
        var uriIndex = message.indexOf('URI: ');
        return message
            .substring(uriIndex + 5)
            .replace(/;/g, '');
    };
    var formatLogMessage = function (message) {
        if (isUnhandledException(message)) {
            try {
                var jsonStartIndex = message.indexOf('{');
                var jsonEndIndex = message.indexOf('; URI:');
                var jsonString = message
                    .substring(jsonStartIndex, jsonEndIndex)
                    .replace(/\\"/g, '"');
                var json = JSON.parse(jsonString);
                return message.substring(jsonEndIndex + 2) + '\n\n' +
                    'Action Arguments:\n' +
                    JSON.stringify(json, null, 4);
            }
            catch (err) {
                console.error(err);
                return message;
            }
        }
        return message.replace(/;/g, '\n');
    };
    var formatExceptionSummay = function (exception) {
        if (!exception) {
            return exception;
        }
        var lineBreakIndex = exception.indexOf('\n');
        if (lineBreakIndex > 0) {
            return exception.substring(0, lineBreakIndex);
        }
        return truncate(exception, 75);
    };
    var formatException = function (exception) {
        try {
            var formattedException = '';
            var lines = exception.split('\n');
            formattedException += lines[0] + '\n\n';
            for (var i = 1; i < lines.length; i++) {
                var line = lines[i];
                if (line.startsWith('   at A3.')) {
                    formattedException += line + '\n';
                }
            }
            return formattedException;
        }
        catch (err) {
            console.error(err);
            return exception;
        }
    };
    return {
        columns: columns,
        defaultViews: defaultViews,
        formatLogMessage: formatLogMessage,
        formatException: formatException,
        queryConditionPropertyOptions: queryConditionPropertyOptions
    };
});
