var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import React from 'react';
import Icon from '../icons/Icon';
import IconStyles from '../icons/iconStyles';
export default (function (_a) {
    var _b, _c;
    var props = __rest(_a, []);
    var checked = props.value === true;
    var toggleChecked = function () {
        if (props.disabled) {
            return;
        }
        if (!!props.onChange) {
            props.onChange(!checked);
        }
    };
    return (React.createElement("div", { className: classNames("checkbox-field-wrapper", props.className, { 'invalid': props.isInvalid }, { 'disabled': props.disabled }), onClick: toggleChecked, onBlur: props.onBlur },
        React.createElement("div", { className: classNames("checkbox-wrapper", { "active": checked }) },
            React.createElement("div", { className: "checkbox-background" }),
            React.createElement(Icon, { icon: "square", style: (_b = props.iconStyle) !== null && _b !== void 0 ? _b : IconStyles.Regular, className: classNames(props.className, 'checkbox', { 'outline': checked }) }),
            checked &&
                React.createElement(Icon, { icon: "check", style: (_c = props.iconStyle) !== null && _c !== void 0 ? _c : IconStyles.Regular, className: classNames(props.className, 'checkbox check') })),
        !!props.label &&
            React.createElement("label", null, props.label)));
});
