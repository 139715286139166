import React from 'react';
import Menu from '../components/menu/Menu';
export default (function (_a) {
    var columns = _a.columns, currentView = _a.currentView, onColumnEnabledChanged = _a.onColumnEnabledChanged;
    var isViewColumnEnabled = function (column) {
        var _a;
        var viewColumn = currentView === null || currentView === void 0 ? void 0 : currentView.columns.find(function (col) { return col.name === column.name; });
        return (_a = viewColumn === null || viewColumn === void 0 ? void 0 : viewColumn.enabled) !== null && _a !== void 0 ? _a : false;
    };
    var handleColumnButtonClicked = function (column) { return function () {
        onColumnEnabledChanged(column);
    }; };
    return (React.createElement(Menu, { buttonText: "Edit Columns", buttonClassName: "button-small primary link", disabled: !currentView, menuPlacement: "bottom", keepOpenOnItemClick: true, menuItems: columns.map(function (column) { return ({
            key: column.name,
            className: "primary",
            leftIconClassName: 'mr-auto',
            text: column.name,
            leftIcon: isViewColumnEnabled(column) ? 'check' : 'square',
            onClick: handleColumnButtonClicked(column)
        }); }) }));
});
