var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import AddressField from './AddressField';
export default (function (_a) {
    var className = _a.className, firstAddressField = _a.firstAddressField, secondAddressField = _a.secondAddressField, sameAsLabel = _a.sameAsLabel, disabled = _a.disabled;
    var _b = useState(firstAddressField.addressValue), firstAddressValue = _b[0], setFirstAddressValue = _b[1];
    var _c = useState(firstAddressField.suiteAptNumberValue), firstSuiteAptNumberValue = _c[0], setFirstSuiteAptNumberValue = _c[1];
    var _d = useState(firstAddressField.cityValue), firstCityValue = _d[0], setFirstCityValue = _d[1];
    var _e = useState(firstAddressField.stateValue), firstStateValue = _e[0], setFirstStateValue = _e[1];
    var _f = useState(firstAddressField.zipValue), firstZipValue = _f[0], setFirstZipValue = _f[1];
    var _g = useState(secondAddressField.addressValue), secondAddressValue = _g[0], setSecondAddressValue = _g[1];
    var _h = useState(secondAddressField.suiteAptNumberValue), secondSuiteAptNumberValue = _h[0], setSecondSuiteAptNumberValue = _h[1];
    var _j = useState(secondAddressField.cityValue), secondCityValue = _j[0], setSecondCityValue = _j[1];
    var _k = useState(secondAddressField.stateValue), secondStateValue = _k[0], setSecondStateValue = _k[1];
    var _l = useState(secondAddressField.zipValue), secondZipValue = _l[0], setSecondZipValue = _l[1];
    var areAddressesSame = function () {
        return firstAddressValue === secondAddressValue &&
            firstSuiteAptNumberValue === secondSuiteAptNumberValue &&
            firstCityValue === secondCityValue &&
            firstStateValue === secondStateValue &&
            firstZipValue === secondZipValue;
    };
    useEffect(function () {
        setSameAsChecked(areAddressesSame());
    }, [firstAddressValue, firstSuiteAptNumberValue, firstCityValue, firstStateValue, firstZipValue,
        secondAddressValue, secondSuiteAptNumberValue, secondCityValue, secondStateValue, secondZipValue]);
    var _m = useState(areAddressesSame()), sameAsChecked = _m[0], setSameAsChecked = _m[1];
    var firstAddressChanged = function (value) {
        setFirstAddressValue(value);
        if (sameAsChecked) {
            setSecondAddressValue(value);
            if (secondAddressField.onAddressChange) {
                secondAddressField.onAddressChange(value);
            }
        }
        if (firstAddressField.onAddressChange) {
            firstAddressField.onAddressChange(value);
        }
    };
    var firstSuiteAptNumberChanged = function (value) {
        setFirstSuiteAptNumberValue(value);
        if (sameAsChecked) {
            setSecondSuiteAptNumberValue(value);
            if (secondAddressField.onSuiteAptNumberChange) {
                secondAddressField.onSuiteAptNumberChange(value);
            }
        }
        if (firstAddressField.onSuiteAptNumberChange) {
            firstAddressField.onSuiteAptNumberChange(value);
        }
    };
    var firstCityChanged = function (value) {
        setFirstCityValue(value);
        if (sameAsChecked) {
            setSecondCityValue(value);
            if (secondAddressField.onCityChange) {
                secondAddressField.onCityChange(value);
            }
        }
        if (firstAddressField.onCityChange) {
            firstAddressField.onCityChange(value);
        }
    };
    var firstStateChanged = function (value) {
        setFirstStateValue(value);
        if (sameAsChecked) {
            setSecondStateValue(value);
            if (secondAddressField.onStateChange) {
                secondAddressField.onStateChange(value);
            }
        }
        if (firstAddressField.onStateChange) {
            firstAddressField.onStateChange(value);
        }
    };
    var firstZipChanged = function (value) {
        setFirstZipValue(value);
        if (sameAsChecked) {
            setSecondZipValue(value);
            if (secondAddressField.onZipChange) {
                secondAddressField.onZipChange(value);
            }
        }
        if (firstAddressField.onZipChange) {
            firstAddressField.onZipChange(value);
        }
    };
    var sameAsCheckboxChanged = function (checked) {
        setSameAsChecked(checked);
        if (checked) {
            secondAddressChanged(firstAddressValue);
            secondSuiteAptNumberChanged(firstSuiteAptNumberValue);
            secondCityChanged(firstCityValue);
            secondStateChanged(firstStateValue);
            secondZipChanged(firstZipValue);
        }
    };
    var secondAddressChanged = function (value) {
        setSecondAddressValue(value);
        if (secondAddressField.onAddressChange) {
            secondAddressField.onAddressChange(value);
        }
    };
    var secondSuiteAptNumberChanged = function (value) {
        setSecondSuiteAptNumberValue(value);
        if (secondAddressField.onSuiteAptNumberChange) {
            secondAddressField.onSuiteAptNumberChange(value);
        }
    };
    var secondCityChanged = function (value) {
        setSecondCityValue(value);
        if (secondAddressField.onCityChange) {
            secondAddressField.onCityChange(value);
        }
    };
    var secondStateChanged = function (value) {
        setSecondStateValue(value);
        if (secondAddressField.onStateChange) {
            secondAddressField.onStateChange(value);
        }
    };
    var secondZipChanged = function (value) {
        setSecondZipValue(value);
        if (secondAddressField.onZipChange) {
            secondAddressField.onZipChange(value);
        }
    };
    return (React.createElement("div", { className: classNames(className, 'flex') },
        React.createElement(AddressField, __assign({}, firstAddressField, { addressValue: firstAddressValue, suiteAptNumberValue: firstSuiteAptNumberValue, cityValue: firstCityValue, stateValue: firstStateValue, zipValue: firstZipValue, onAddressChange: firstAddressChanged, onSuiteAptNumberChange: firstSuiteAptNumberChanged, onCityChange: firstCityChanged, onStateChange: firstStateChanged, onZipChange: firstZipChanged, disabled: disabled })),
        React.createElement(AddressField, __assign({}, secondAddressField, { className: classNames(secondAddressField.className, "ml-1"), addressValue: secondAddressValue, suiteAptNumberValue: secondSuiteAptNumberValue, cityValue: secondCityValue, stateValue: secondStateValue, zipValue: secondZipValue, onAddressChange: secondAddressChanged, onSuiteAptNumberChange: secondSuiteAptNumberChanged, onCityChange: secondCityChanged, onStateChange: secondStateChanged, onZipChange: secondZipChanged, sameAsValue: sameAsChecked, sameAsLabel: sameAsLabel, onSameAsCheckboxChange: sameAsCheckboxChanged, disabled: disabled }))));
});
