import { useSession } from "./useSession";
import useNavigator from "../../routing/useNavigator";
export default (function () {
    var session = useSession().session;
    if (!session || !(session === null || session === void 0 ? void 0 : session.id)) {
        var navigate = useNavigator();
        navigate('Login');
    }
    return { session: session };
});
