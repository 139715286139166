import useFetchCall from "../baseApi/useFetchCall";
var customerApi = {
    useGetCustomerList: function () {
        return useFetchCall({
            endPoint: 'CustomerActions/GetCustomerListDataFromQuery',
            method: 'POST'
        });
    },
    useCreateNewCustomer: function () {
        var fetchCall = useFetchCall({
            endPoint: 'CustomerActions/CreateNewCustomer',
            method: 'POST',
            responseTranslator: function (response) { return response.value; }
        });
        var submit = function (newCustomer) {
            fetchCall.fetch(newCustomer);
        };
        return {
            submit: submit,
            newCustomerId: fetchCall.data,
            isWaiting: fetchCall.isWaiting,
            hasError: fetchCall.hasError,
            errorMessage: fetchCall.errorMessage,
            callCount: fetchCall.callCount
        };
    }
};
export var useGetCustomerList = customerApi.useGetCustomerList, useCreateNewCustomer = customerApi.useCreateNewCustomer;
