import React from 'react';
import Card from '../card/Card';
import Icon from '../icons/Icon';
import IconStyles from '../icons/iconStyles';
import Text from '../text/Text';
export default (function (_a) {
    var heading = _a.heading, message = _a.message, icon = _a.icon, className = _a.className;
    return React.createElement(Card, { className: className },
        React.createElement("div", { className: "p-8 flex" },
            React.createElement(Icon, { style: IconStyles.Duotone, className: "text-xxx-large text-faint", icon: icon !== null && icon !== void 0 ? icon : "map-signs" }),
            React.createElement("div", { className: "ml-2" },
                React.createElement("b", { className: "text-x-large" },
                    !!heading &&
                        React.createElement("span", null, heading),
                    !heading &&
                        React.createElement("span", null, "No Records Found")),
                React.createElement("br", null),
                React.createElement(Text, { className: "text-faint" },
                    !!message &&
                        React.createElement("span", null, message),
                    !message &&
                        React.createElement("span", null, "Either no records exist or none were found based on the search parameters provided.")))));
});
