import { Form, Formik } from 'formik';
import React from 'react';
import Button from "../../../components/button/Button";
import Modal from "../../../components/modal/Modal";
import * as Yup from 'yup';
import FormikTextField from '../../../components/formik/FormikTextField';
import FormikDateField from '../../../components/formik/FormikDateField';
import FormikDecimalField from '../../../components/formik/FormikDecimalField';
import CustomerType from '../customerType';
import FormikRadioField from '../../../components/formik/FormikRadioField';
import FormikMultipleAddressField from '../../../components/formik/FormikMultipleAddressField';
import FormikStateField from '../../../components/formik/FormikStateField';
import FormikPhoneField from '../../../components/formik/FormikPhoneField';
import FormikEmailField from '../../../components/formik/FormikEmailField';
import { yupDate, yupEmail, yupPhone, yupSsn, yupStringRequired, yupZip, yupZipRequired } from '../../../utils/validation/yupSchemas';
import FormikSsnField from '../../../components/formik/FormikSsnField';
import FormikCompanyTypeField from '../../../components/formik/FormikCompanyTypeField';
import FormikGenderField from '../../../components/formik/FormikGenderField';
import TabGroup from '../../../components/tabs/TabGroup';
import FormikAddressField from '../../../components/formik/FormikAddressField';
import FormikMaritalStatusField from '../../../components/formik/FormikMaritalStatusField';
import MaritalStatus from '../../people/maritalStatus';
import { useCreateNewCustomer } from '../../../api/customers/useCustomerApi';
import useToasts from '../../../components/toasts/useToasts';
export default (function (_a) {
    var onCloseButtonClicked = _a.onCloseButtonClicked, onCustomerCreated = _a.onCustomerCreated;
    var addToast = useToasts().addToast;
    var _b = useCreateNewCustomer(), submit = _b.submit, newCustomerId = _b.newCustomerId, isWaiting = _b.isWaiting, hasError = _b.hasError, errorMessage = _b.errorMessage;
    if (newCustomerId) {
        addToast({
            type: 'success',
            heading: 'Customer Created',
            message: 'Customer created successfully'
        });
        onCustomerCreated(newCustomerId);
    }
    if (errorMessage) {
        addToast({
            type: 'danger',
            heading: 'Unable to Create',
            message: 'An error occurred trying to create the customer',
        });
    }
    return (React.createElement(Modal, { onCloseButtonClicked: onCloseButtonClicked, closeButtonDisabled: isWaiting, heading: "New Customer", contentClassName: "relative", footer: React.createElement("div", { className: "flex" },
            React.createElement(Button, { className: "ml-auto", text: "Cancel", onClick: onCloseButtonClicked, disabled: isWaiting }),
            React.createElement(Button, { className: "ml-1 primary", text: isWaiting ? "Saving..." : "Create", type: "submit", form: "newCustomerForm", disabled: isWaiting })) },
        React.createElement(Formik, { initialValues: {
                customerType: CustomerType.Individual,
                contact: {},
                company: {}
            }, validationSchema: Yup.object({
                customerType: Yup
                    .string()
                    .required('Required'),
                contact: Yup.object({})
                    .when('customerType', {
                    is: CustomerType.Individual,
                    then: Yup.object({
                        firstName: yupStringRequired,
                        lastName: yupStringRequired,
                        ssn: yupSsn,
                        dateOfBirth: yupDate,
                        email: yupEmail,
                        cellPhone: yupPhone,
                        homePhone: yupPhone,
                        fax: yupPhone,
                        physicalZip: yupZip,
                        mailZip: yupZip,
                    })
                }),
                company: Yup.object({})
                    .when('customerType', {
                    is: CustomerType.Company,
                    then: Yup.object({
                        name: yupStringRequired,
                        physicalAddress: yupStringRequired,
                        physicalCity: yupStringRequired,
                        physicalState: yupStringRequired,
                        physicalZip: yupZipRequired,
                        mailAddress: yupStringRequired,
                        mailCity: yupStringRequired,
                        mailState: yupStringRequired,
                        mailZip: yupZipRequired
                    })
                })
            }), onSubmit: function (values, actions) {
                if (values.customerType === CustomerType.Individual) {
                    values.company = undefined;
                }
                else {
                    values.contact = undefined;
                }
                submit(values);
            } }, function (props) {
            var _a;
            return (React.createElement(Form, { id: "newCustomerForm" },
                isWaiting &&
                    React.createElement("div", { className: "loading-mask" }),
                React.createElement(FormikRadioField, { name: "customerType", label: "Customer Type", className: "mb-2", options: [
                        { label: CustomerType.Individual, value: CustomerType.Individual },
                        { label: CustomerType.Company, value: CustomerType.Company },
                    ] }),
                props.values.customerType === CustomerType.Individual &&
                    React.createElement("div", { className: "fade-in" },
                        React.createElement(TabGroup, { tabs: [
                                {
                                    heading: 'Personal Info',
                                    content: (React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: "flex" },
                                            React.createElement(FormikTextField, { name: "contact.firstName", label: "First Name", className: "fill" }),
                                            React.createElement(FormikTextField, { name: "contact.middleName", label: "Middle Name", className: "fill ml-1" }),
                                            React.createElement(FormikTextField, { name: "contact.lastName", label: "Last Name", className: "fill ml-1" })),
                                        React.createElement("div", { className: "flex" },
                                            React.createElement(FormikTextField, { name: "contact.suffix", label: "Suffix", className: "fill" }),
                                            React.createElement(FormikTextField, { name: "contact.prefix", label: "Prefix", className: "fill ml-1" }),
                                            React.createElement(FormikGenderField, { name: "contact.gender", label: "Gender", className: "fill ml-1" })),
                                        React.createElement("div", { className: "flex" },
                                            React.createElement(FormikSsnField, { name: "contact.ssn", label: "SSN", className: "fill" }),
                                            React.createElement(FormikDateField, { name: "contact.dateOfBirth", label: "Date of Birth", className: "fill ml-1" }),
                                            React.createElement(FormikTextField, { name: "contact.jobTitle", label: "Job Title", className: "fill ml-1" })),
                                        React.createElement("div", { className: "flex" },
                                            React.createElement(FormikTextField, { name: "contact.driversLicenseNumber", label: "Drivers License Number", className: "fill" }),
                                            React.createElement(FormikStateField, { name: "contact.driversLicenseState", label: "Drivers License State", className: "fill ml-1", fieldTextKey: "label" }),
                                            React.createElement(FormikDecimalField, { name: "contact.yearsExperience", label: "Years of Industry Experience", className: "fill ml-1" })),
                                        React.createElement("div", { className: "flex" },
                                            React.createElement(FormikPhoneField, { name: "contact.cellPhone", label: "Cell Phone", className: "fill" }),
                                            React.createElement(FormikPhoneField, { name: "contact.homePhone", label: "Home Phone", className: "fill ml-1" }),
                                            React.createElement(FormikPhoneField, { name: "contact.fax", label: "Fax", className: "fill ml-1" }),
                                            React.createElement(FormikEmailField, { name: "contact.email", label: "Email", className: "fill ml-1" })),
                                        React.createElement(FormikMultipleAddressField, { firstAddressField: {
                                                name: 'contact.physical',
                                                label: 'Physical Address',
                                                className: 'fill'
                                            }, secondAddressField: {
                                                name: 'contact.mail',
                                                label: 'Mailing Address',
                                                className: 'fill ml-1'
                                            }, sameAsLabel: "Same as Physical" })))
                                },
                                {
                                    heading: 'Employer',
                                    content: (React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: "flex" },
                                            React.createElement(FormikTextField, { name: "contact.employerName", label: "Employer Name", className: "fill" }),
                                            React.createElement(FormikPhoneField, { name: "contact.employerPhone", label: "Phone", className: "fill ml-1" })),
                                        React.createElement(FormikAddressField, { name: "contact.employer", label: "Address" })))
                                },
                                {
                                    heading: 'Spouse',
                                    content: (React.createElement(React.Fragment, null,
                                        React.createElement(FormikMaritalStatusField, { name: "contact.maritalStatus", label: "Marital Status" }),
                                        ((_a = props.values.contact) === null || _a === void 0 ? void 0 : _a.maritalStatus) &&
                                            props.values.contact.maritalStatus !== MaritalStatus.Single &&
                                            React.createElement(React.Fragment, null,
                                                React.createElement("div", { className: "flex" },
                                                    React.createElement(FormikTextField, { name: "contact.spouseFirstName", label: "First Name", className: "fill" }),
                                                    React.createElement(FormikTextField, { name: "contact.spouseMiddleName", label: "Middle Name", className: "fill ml-1" }),
                                                    React.createElement(FormikTextField, { name: "contact.spouseLastName", label: "Last Name", className: "fill ml-1" })),
                                                React.createElement("div", { className: "flex" },
                                                    React.createElement(FormikSsnField, { name: "contact.spouseSsn", label: "SSN", className: "fill" }),
                                                    React.createElement(FormikDateField, { name: "contact.spouseDateOfBirth", label: "Date of Birth", className: "fill ml-1" }),
                                                    React.createElement(FormikGenderField, { name: "contact.spouseGender", label: "Gender", className: "fill ml-1" })),
                                                React.createElement("div", { className: "flex" },
                                                    React.createElement(FormikPhoneField, { name: "contact.spouseCellPhone", label: "Phone", className: "fill" }),
                                                    React.createElement(FormikEmailField, { name: "contact.spouseEmail", label: "Email", className: "fill ml-1" })))))
                                },
                            ] })),
                props.values.customerType === CustomerType.Company &&
                    React.createElement("div", { className: "fade-in" },
                        React.createElement("div", { className: "flex" },
                            React.createElement(FormikTextField, { name: "company.name", label: "Company Name", className: "fill" }),
                            React.createElement(FormikTextField, { name: "company.dba", label: "DBA", className: "fill ml-1" }),
                            React.createElement(FormikCompanyTypeField, { name: "company.companyType", label: "Company Type", className: "fill ml-1" })),
                        React.createElement("div", { className: "flex" },
                            React.createElement(FormikPhoneField, { name: "company.phone", label: "Phone", className: "fill" }),
                            React.createElement(FormikPhoneField, { name: "company.fax", label: "Fax", className: "fill ml-1" }),
                            React.createElement(FormikEmailField, { name: "company.email", label: "Email", className: "fill ml-1" })),
                        React.createElement("div", { className: "flex" },
                            React.createElement(FormikTextField, { name: "company.fein", label: "FEIN", className: "fill" }),
                            React.createElement(FormikDateField, { name: "company.dateFormed", label: "Date Formed", className: "fill ml-1" }),
                            React.createElement(FormikDateField, { name: "company.fiscalYearEnd", label: "fiscalYearEnd", className: "fill ml-1" })),
                        React.createElement(FormikMultipleAddressField, { firstAddressField: {
                                name: 'company.physical',
                                label: 'Physical Address',
                                className: 'fill'
                            }, secondAddressField: {
                                name: 'company.mail',
                                label: 'Mailing Address',
                                className: 'fill ml-1'
                            }, sameAsLabel: "Same as Physical" }))));
        })));
});
