var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import classNames from "classnames";
import React, { useRef } from "react";
import Menu from "../components/menu/Menu";
import getChildElementIndex from "../utils/elements/getChildElementIndex";
import isElementAbove from "../utils/elements/isElementAbove";
import swapSiblingElements from "../utils/elements/swapSiblingElements";
export default (function (_a) {
    var hasChanges = _a.hasChanges, views = _a.views, currentView = _a.currentView, isLoaded = _a.isLoaded, onSetView = _a.onSetView, onResetCurrentView = _a.onResetCurrentView, onShowSaveAsModal = _a.onShowSaveAsModal, onSaveCurrentView = _a.onSaveCurrentView, onShowRenameModal = _a.onShowRenameModal, onShowDeleteViewConfirmationModal = _a.onShowDeleteViewConfirmationModal, onViewsReordered = _a.onViewsReordered;
    var viewMenuRef = useRef();
    var draggingMenuItemElementRef = useRef();
    var dropPlaceholderRef = useRef();
    var draggingViewRef = useRef();
    var originalMenuItemIndexRef = useRef();
    var mouseY = useRef(0);
    var handleViewDragHandleMouseDown = function (view) { return function (event) {
        var _a, _b, _c;
        draggingViewRef.current = view;
        draggingMenuItemElementRef.current = (_c = (_b = (_a = event.currentTarget) === null || _a === void 0 ? void 0 : _a.parentNode) === null || _b === void 0 ? void 0 : _b.parentElement) !== null && _c !== void 0 ? _c : undefined;
        if (!draggingViewRef.current || !draggingMenuItemElementRef.current) {
            draggingViewRef.current = undefined;
            draggingMenuItemElementRef.current = undefined;
            return;
        }
        mouseY.current = event.clientY;
        document.addEventListener('mousemove', mouseMoveViewHandler);
        document.addEventListener('mouseup', mouseUpViewHandler);
    }; };
    var mouseMoveViewHandler = function (event) {
        var _a;
        if (!draggingViewRef.current || !draggingMenuItemElementRef.current) {
            return;
        }
        if (originalMenuItemIndexRef.current === undefined && viewMenuRef.current) {
            originalMenuItemIndexRef.current = getChildElementIndex(viewMenuRef.current, draggingMenuItemElementRef.current);
        }
        if (!dropPlaceholderRef.current) {
            dropPlaceholderRef.current = document.createElement('div');
            dropPlaceholderRef.current.classList.add('menu-item-drop-placeholder');
            (_a = draggingMenuItemElementRef.current.parentNode) === null || _a === void 0 ? void 0 : _a.insertBefore(dropPlaceholderRef.current, draggingMenuItemElementRef.current.nextSibling);
            dropPlaceholderRef.current.style.height = "".concat(draggingMenuItemElementRef.current.offsetHeight, "px");
            dropPlaceholderRef.current.style.width = "".concat(draggingMenuItemElementRef.current.offsetWidth, "px");
        }
        draggingMenuItemElementRef.current.classList.add('menu-item-dragging');
        draggingMenuItemElementRef.current.style.top = "".concat(draggingMenuItemElementRef.current.offsetTop + event.clientY - mouseY.current, "px");
        mouseY.current = event.clientY;
        var previousElement = draggingMenuItemElementRef.current.previousElementSibling;
        var nextElement = draggingMenuItemElementRef.current.nextElementSibling;
        if (previousElement &&
            isElementAbove(draggingMenuItemElementRef.current, previousElement)) {
            swapSiblingElements(dropPlaceholderRef.current, draggingMenuItemElementRef.current);
            swapSiblingElements(dropPlaceholderRef.current, previousElement);
            return;
        }
        if (nextElement &&
            !nextElement.classList.contains('menu-item-divider') &&
            isElementAbove(nextElement, draggingMenuItemElementRef.current)) {
            swapSiblingElements(nextElement, dropPlaceholderRef.current);
            swapSiblingElements(nextElement, draggingMenuItemElementRef.current);
        }
    };
    var mouseUpViewHandler = function () {
        var _a;
        document.removeEventListener('mousemove', mouseMoveViewHandler);
        document.removeEventListener('mouseup', mouseUpViewHandler);
        if (!draggingMenuItemElementRef.current || !viewMenuRef.current) {
            return;
        }
        // remove placeholder so that it doesn't interact with the index counting
        if (dropPlaceholderRef.current) {
            (_a = dropPlaceholderRef.current.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(dropPlaceholderRef.current);
        }
        var newMenueItemIndex = getChildElementIndex(viewMenuRef.current, draggingMenuItemElementRef.current);
        if (newMenueItemIndex >= 0 &&
            (originalMenuItemIndexRef.current ||
                originalMenuItemIndexRef.current === 0) &&
            newMenueItemIndex !== originalMenuItemIndexRef.current) {
            var sortedViews = __spreadArray([], views, true).map(function (_a, index) {
                var view = __rest(_a, []);
                view.index = index;
                return view;
            });
            var menuItemArrayIndex = sortedViews.findIndex(function (menuItem) { var _a; return menuItem.id === ((_a = draggingViewRef.current) === null || _a === void 0 ? void 0 : _a.id); });
            var movingMenuItem = sortedViews.splice(menuItemArrayIndex, 1)[0];
            var priorMenuItems = sortedViews.splice(0, newMenueItemIndex);
            var followingMenuItems = sortedViews;
            var newlySortedViews = __spreadArray(__spreadArray(__spreadArray([], priorMenuItems, true), [
                movingMenuItem
            ], false), followingMenuItems, true).map(function (_a, index) {
                var view = __rest(_a, []);
                view.index = index;
                return view;
            })
                .sort(function (a, b) { return a.index - b.index; });
            onViewsReordered(newlySortedViews);
        }
        draggingMenuItemElementRef.current.classList.remove('menu-item-dragging');
        draggingMenuItemElementRef.current = undefined;
        dropPlaceholderRef.current = undefined;
        draggingViewRef.current = undefined;
        originalMenuItemIndexRef.current = undefined;
    };
    var getViewMenuButtonText = function () {
        if (!isLoaded) {
            return 'Loading...';
        }
        if (hasChanges) {
            return currentView.name + ' - modified';
        }
        return currentView.name;
    };
    return (React.createElement(Menu, { ref: viewMenuRef, className: "mt-auto", disabled: !isLoaded, buttonText: getViewMenuButtonText(), buttonClassName: classNames("button-x-small text-faint oval", { 'outline danger': hasChanges }), buttonRightIcon: "chevron-down", menuPlacement: "bottom", menuItems: views
            .sort(function (a, b) { return a.index - b.index; })
            .map(function (view) { return ({
            key: view.id.toString(),
            text: view.name,
            className: 'primary',
            onLeftIconMouseDown: handleViewDragHandleMouseDown(view),
            leftIcon: 'grip-lines-vertical',
            leftIconClassName: 'text-faint drag-handle',
            rightIcon: view.isDefault ? 'lock' : undefined,
            rightIconClassName: 'text-faint text-small',
            onClick: function () { onSetView(view.id); }
        }); })
            .concat([
            'divider',
            {
                key: 'Reset',
                text: 'Reset View',
                leftIcon: 'undo',
                className: 'primary',
                onClick: onResetCurrentView,
                disabled: !hasChanges
            },
            {
                key: 'Save As',
                text: 'Save As',
                leftIcon: "save",
                className: 'primary',
                onClick: onShowSaveAsModal
            },
            {
                key: 'Save',
                text: 'Save',
                leftIcon: "save",
                className: 'primary',
                onClick: onSaveCurrentView,
                disabled: !hasChanges || currentView.id === -1
            },
            {
                key: 'Rename',
                text: 'Rename',
                leftIcon: "edit",
                className: 'primary',
                onClick: onShowRenameModal,
                disabled: currentView.id === -1
            },
            'divider',
            {
                key: 'Delete',
                text: 'Delete',
                leftIcon: "trash",
                className: 'primary',
                onClick: onShowDeleteViewConfirmationModal,
                disabled: currentView.id === -1
            }
        ]) }));
});
