import React from 'react';
import Checkbox from '../../forms/Checkbox';
import TableBodyExpanderCell from '../tableBody/TableBodyExpanderCell';
import TableHeadCell from '../tableHead/TableHeadCell';
export default React.forwardRef(function (_a, ref) {
    var columns = _a.columns, rows = _a.rows, currentOrderBy = _a.currentOrderBy, showCheckColumn = _a.showCheckColumn, expandedContentBuilder = _a.expandedContentBuilder, columnWidthCalculator = _a.columnWidthCalculator, rowHeightCalculator = _a.rowHeightCalculator;
    var _b = ref.current, tableCloneRef = _b.tableCloneRef, draggingColumnRef = _b.draggingColumnRef, draggingElementRef = _b.draggingElementRef;
    return (React.createElement("div", { className: "draggable-column-table-container", ref: tableCloneRef },
        !!expandedContentBuilder &&
            React.createElement("div", null,
                React.createElement("table", null,
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", { className: "expander-column" }))),
                    rows.map(function (row, rowIndex) { return (React.createElement("tbody", { key: rowIndex },
                        React.createElement("tr", null,
                            React.createElement(TableBodyExpanderCell, { isExpanded: row.isExpanded, onClick: function () { }, disabled: true })))); }))),
        showCheckColumn &&
            React.createElement("div", null,
                React.createElement("table", null,
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", { className: "selecter-column" },
                                React.createElement(Checkbox, { value: !rows.some(function (row) { return row.isChecked !== true; }), disabled: true })))),
                    rows.map(function (row, rowIndex) { return (React.createElement("tbody", { key: rowIndex },
                        React.createElement("tr", null,
                            React.createElement("td", { className: "selecter-column" },
                                React.createElement(Checkbox, { value: row.isChecked, disabled: true }))))); }))),
        columns.map(function (column, columnIndex) {
            var _a;
            return (React.createElement("div", { key: column.name, className: "draggable-column-table-wrapper", ref: ((_a = draggingColumnRef.current) === null || _a === void 0 ? void 0 : _a.index) === column.index ? draggingElementRef : undefined },
                React.createElement("table", { style: { width: columnWidthCalculator(columnIndex) } },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", { style: { height: rowHeightCalculator(0) } },
                                React.createElement(TableHeadCell, { column: column, currentOrderBy: currentOrderBy })))),
                    rows.map(function (row, rowIndex) { return (React.createElement("tbody", { key: rowIndex },
                        React.createElement("tr", { style: { height: rowHeightCalculator(rowIndex + 1) } },
                            React.createElement("td", { className: column.cellClassName }, column.cellTemplate(row.item, rowIndex))))); }))));
        })));
});
