var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import React from 'react';
import { IMaskInput } from 'react-imask';
export default (function (_a) {
    var props = __rest(_a, []);
    var placeholderText = props.placeholder || props.label || '';
    var onAccepted = function (value, maskRef, e) {
        if (!props.onChange) {
            return;
        }
        if (!value) {
            props.onChange(undefined);
        }
        else {
            props.onChange(maskRef.typedValue);
        }
    };
    return (React.createElement("div", { className: classNames('text-field-wrapper', props.className, { 'invalid': !!props.errorMessage }, { 'disabled': props.disabled }) },
        !!props.label &&
            React.createElement("label", { htmlFor: props.name }, props.label),
        React.createElement(IMaskInput, { name: props.name, type: "tel", placeholder: placeholderText, mask: '000-00-0000', onAccept: onAccepted, onBlur: props.onBlur, value: props.value, disabled: props.disabled }),
        !!props.errorMessage &&
            React.createElement("div", { className: "error-message" }, props.errorMessage)));
});
