import useFetchCall from "../baseApi/useFetchCall";
var logApi = {
    useGetLogList: function () {
        return useFetchCall({
            endPoint: 'LogActions/GetLogListDataFromQuery',
            method: 'POST'
        });
    }
};
export var useGetLogList = logApi.useGetLogList;
