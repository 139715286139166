var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import Button from '../../button/Button';
import Heading from '../../heading/Heading';
import QueryBuilderModal from '../../queryBuilder/queryBuilderModal/QueryBuilderModal';
import QuickSearch from '../quickSearch/QuickSearch';
import Table from '../Table';
import TableErrorState from '../tableErrorState/TableErrorState';
import useDataViews from '../../../dataViews/useDataViews';
var DataViewTable = function (_a) {
    var _b, _c, _d, _e;
    var heading = _a.heading, data = _a.data, callCount = _a.callCount, hasError = _a.hasError, errorMessage = _a.errorMessage, isWaiting = _a.isWaiting, fetch = _a.fetch, queryConditionProperyOptions = _a.queryConditionProperyOptions, defaultDataViews = _a.defaultDataViews, columns = _a.columns, dataViewType = _a.dataViewType, tablePagerClassName = _a.tablePagerClassName, actionButtons = _a.actionButtons, expandedContentBuilder = _a.expandedContentBuilder;
    var _f = useDataViews(defaultDataViews, columns, dataViewType), showQueryBuilderModal = _f.showQueryBuilderModal, hideQueryBuilderModal = _f.hideQueryBuilderModal, applyQueryBuilderConditionSet = _f.applyQueryBuilderConditionSet, buildDataViewModals = _f.buildDataViewModals, buildDataViewMenu = _f.buildDataViewMenu, isQueryBuilderModalOpen = _f.isQueryBuilderModalOpen, tableQueryOptions = _f.tableQueryOptions, currentView = _f.currentView, columnOrderChanged = _f.columnOrderChanged, tableChanged = _f.tableChanged, searchPhraseChanged = _f.searchPhraseChanged, buildColumnSelectionMenu = _f.buildColumnSelectionMenu, dataViewsLoaded = _f.dataViewsLoaded;
    useEffect(function () {
        if (tableQueryOptions && dataViewsLoaded) {
            fetch(tableQueryOptions);
        }
    }, [tableQueryOptions]);
    var showLoadingSkeleton = isWaiting || !callCount || !dataViewsLoaded;
    var _g = useState([]), expandedIndicies = _g[0], setExpandedIndicies = _g[1];
    var buildRows = function (items) {
        return items.map(function (item, index) {
            return {
                item: item,
                id: item.id ? item.id : index,
                isExpanded: expandedIndicies.indexOf(index) >= 0
            };
        });
    };
    var onRowExpanderCellClicked = function (rowIndex) {
        if (expandedIndicies.indexOf(rowIndex) >= 0) {
            setExpandedIndicies(__spreadArray([], expandedIndicies, true).filter(function (expandedIndex) { return rowIndex !== expandedIndex; }));
        }
        else {
            setExpandedIndicies(__spreadArray(__spreadArray([], expandedIndicies, true), [rowIndex], false));
        }
    };
    return (React.createElement("div", null,
        buildDataViewModals(),
        !!isQueryBuilderModalOpen &&
            React.createElement(QueryBuilderModal, { conditionSet: currentView.conditionSet, propertyOptions: queryConditionProperyOptions, onClose: hideQueryBuilderModal, onApplyClick: applyQueryBuilderConditionSet }),
        React.createElement("div", { className: "flex mb-2" },
            !!heading &&
                React.createElement(Heading, { level: "5", className: "v-center mr-1" }, heading),
            buildDataViewMenu()),
        React.createElement("div", { className: "flex" },
            React.createElement(QuickSearch, { className: "v-center", onSearch: searchPhraseChanged }),
            React.createElement("div", { className: "ml-1 v-center flex column" },
                buildColumnSelectionMenu(),
                React.createElement(Button, { className: "button-small primary link", text: "Advanced Search", onClick: showQueryBuilderModal })),
            React.createElement("div", { className: "ml-auto flex" }, actionButtons && actionButtons.map(function (actionButton, index) {
                return React.createElement("div", { className: "v-center", key: index }, actionButton);
            }))),
        hasError &&
            React.createElement(TableErrorState, { className: "mt-3", detailsMessage: errorMessage }),
        !hasError &&
            React.createElement(Table, { className: "mt-3", columns: currentView.columns, rows: buildRows((_c = (_b = data === null || data === void 0 ? void 0 : data.value) === null || _b === void 0 ? void 0 : _b.items) !== null && _c !== void 0 ? _c : []), totalItemCount: (_e = (_d = data === null || data === void 0 ? void 0 : data.value) === null || _d === void 0 ? void 0 : _d.totalRecordCount) !== null && _e !== void 0 ? _e : 0, onTableChange: tableChanged, onRefreshClicked: fetch, tableQueryOptions: tableQueryOptions, isLoading: showLoadingSkeleton, columnReorderEnabled: true, onColumnReorder: columnOrderChanged, tablePagerClassName: tablePagerClassName, onRowExpanderCellClicked: onRowExpanderCellClicked, expandedContentBuilder: expandedContentBuilder })));
};
export default DataViewTable;
