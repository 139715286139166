var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import classNames from 'classnames';
export default forwardRef(function (_a, ref) {
    var props = __rest(_a, []);
    var placeholderText = props.placeholder || props.label || '';
    var handleOnChange = function (e) {
        var newValue = e.target.value;
        if (newValue === props.value) {
            return;
        }
        if (!!props.onChange) {
            props.onChange(newValue);
        }
    };
    return (React.createElement("div", { className: classNames('text-field-wrapper', props.className, { 'invalid': !!props.errorMessage }, { 'disabled': !!props.disabled }) },
        !!props.label &&
            React.createElement("label", { htmlFor: props.name }, props.label),
        React.createElement("input", { ref: ref, name: props.name, type: props.hideValue ? "password" : "text", placeholder: placeholderText, autoComplete: "off", onChange: handleOnChange, onBlur: props.onBlur, defaultValue: props.value, disabled: props.disabled }),
        !!props.errorMessage &&
            React.createElement("div", { className: "error-message" }, props.errorMessage)));
});
