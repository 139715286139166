var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useRef, useState } from "react";
import getChildElementIndex from "../../../utils/elements/getChildElementIndex";
import isElementLeftOf from "../../../utils/elements/isElementLeftOf";
import swapSiblingElements from "../../../utils/elements/swapSiblingElements";
export function useColumnOrganizer(initialColumns, columnReorderEnabled, onColumnReorder) {
    var _a = useState(false), isColumnDragging = _a[0], setIsColumnDragging = _a[1];
    var _b = useState(initialColumns), orderedVisibleColumns = _b[0], setOrderedVisibleColumns = _b[1];
    var dropPlaceholderRef = useRef();
    var mouseX = useRef(0);
    var mouseY = useRef(0);
    var originalColumnIndex = useRef();
    var tableRef = useRef();
    var draggingColumnRef = useRef();
    var tableCloneRef = useRef();
    var draggingElementRef = useRef();
    var columnWidthsRef = useRef([]);
    var rowHeightsRef = useRef([]);
    var columnHeaderMouseDownHandler = function (column) { return function (event) {
        if (!tableRef.current || !columnReorderEnabled) {
            return;
        }
        draggingColumnRef.current = column;
        // Determine the mouse position
        mouseX.current = event.clientX;
        mouseY.current = event.clientY - event.target.offsetTop;
        document.addEventListener('mousemove', mouseMoveHander);
        document.addEventListener('mouseup', mouseUpHandler);
    }; };
    var mouseMoveHander = function (event) {
        var _a, _b, _c, _d, _e, _f, _g;
        if (!isColumnDragging) {
            if (tableRef.current) {
                var tableHeaders = (_a = tableRef.current) === null || _a === void 0 ? void 0 : _a.querySelectorAll('th');
                var tableHeaderCount = (_b = tableHeaders === null || tableHeaders === void 0 ? void 0 : tableHeaders.length) !== null && _b !== void 0 ? _b : 0;
                columnWidthsRef.current = [];
                for (var i = 0; i < tableHeaderCount; i++) {
                    columnWidthsRef.current.push(window.getComputedStyle(tableHeaders[i]).width);
                }
                var tableRows = (_c = tableRef.current) === null || _c === void 0 ? void 0 : _c.querySelectorAll('tr');
                var tableRowCount = (_d = tableRows === null || tableRows === void 0 ? void 0 : tableRows.length) !== null && _d !== void 0 ? _d : 0;
                rowHeightsRef.current = [];
                for (var i = 0; i < tableRowCount; i++) {
                    rowHeightsRef.current.push(window.getComputedStyle(tableRows[i]).height);
                }
            }
            setIsColumnDragging(true);
        }
        if (!draggingElementRef.current || !tableCloneRef.current) {
            return;
        }
        // get current index before creating drop placeholder
        if (originalColumnIndex.current === undefined && originalColumnIndex.current !== 0) {
            originalColumnIndex.current = getChildElementIndex(tableCloneRef.current, draggingElementRef.current);
        }
        // create drop placeholder
        if (!dropPlaceholderRef.current) {
            dropPlaceholderRef.current = document.createElement('div');
            dropPlaceholderRef.current.classList.add('column-drop-placeholder');
            (_e = draggingElementRef.current.parentNode) === null || _e === void 0 ? void 0 : _e.insertBefore(dropPlaceholderRef.current, (_f = draggingElementRef.current) === null || _f === void 0 ? void 0 : _f.nextSibling);
            dropPlaceholderRef.current.style.width = "".concat((_g = draggingElementRef.current) === null || _g === void 0 ? void 0 : _g.offsetWidth, "px");
        }
        draggingElementRef.current.style.position = 'absolute';
        draggingElementRef.current.style.top = "".concat(draggingElementRef.current.offsetTop + event.clientY - mouseY.current, "px");
        draggingElementRef.current.style.left = "".concat(mouseX.current, "px");
        mouseY.current = event.clientY;
        mouseX.current = event.clientX;
        var previousElement = draggingElementRef.current.previousElementSibling;
        var nextElement = draggingElementRef.current.nextElementSibling;
        if (previousElement && isElementLeftOf(draggingElementRef.current, previousElement)) {
            swapSiblingElements(dropPlaceholderRef.current, draggingElementRef.current);
            swapSiblingElements(dropPlaceholderRef.current, previousElement);
            return;
        }
        if (nextElement && isElementLeftOf(nextElement, draggingElementRef.current)) {
            swapSiblingElements(nextElement, dropPlaceholderRef.current);
            swapSiblingElements(nextElement, draggingElementRef.current);
        }
    };
    var mouseUpHandler = function () {
        var _a;
        document.removeEventListener('mousemove', mouseMoveHander);
        document.removeEventListener('mouseup', mouseUpHandler);
        if (!tableCloneRef.current || !draggingElementRef.current) {
            return;
        }
        // remove placeholder so that it doesn't interact with the index counting
        if (dropPlaceholderRef.current) {
            (_a = dropPlaceholderRef.current.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(dropPlaceholderRef.current);
        }
        var newColumnIndex = getChildElementIndex(tableCloneRef.current, draggingElementRef.current);
        if (newColumnIndex >= 0 &&
            (originalColumnIndex.current ||
                originalColumnIndex.current === 0) &&
            newColumnIndex !== originalColumnIndex.current) {
            var columns = orderedVisibleColumns.map(function (_a, index) {
                var column = __rest(_a, []);
                column.index = index;
                return column;
            });
            var columnArrayIndex = columns.findIndex(function (column) { var _a; return column.name === ((_a = draggingColumnRef.current) === null || _a === void 0 ? void 0 : _a.name); });
            var movingColumn = columns.splice(columnArrayIndex, 1)[0];
            var priorColumns = columns.splice(0, newColumnIndex);
            var followingColumns = columns;
            var newlySortedColumns = __spreadArray(__spreadArray(__spreadArray([], priorColumns, true), [
                movingColumn
            ], false), followingColumns, true).map(function (_a, index) {
                var column = __rest(_a, []);
                column.index = index;
                return column;
            })
                .sort(function (a, b) { return a.index - b.index; });
            setOrderedVisibleColumns(newlySortedColumns);
            if (typeof onColumnReorder === 'function') {
                onColumnReorder(newlySortedColumns);
            }
        }
        draggingElementRef.current = undefined;
        dropPlaceholderRef.current = undefined;
        originalColumnIndex.current = undefined;
        setIsColumnDragging(false);
    };
    var getColumnWidth = function (columnIndex) {
        return columnWidthsRef.current[columnIndex];
    };
    var getRowHeight = function (rowIndex) {
        return rowHeightsRef.current[rowIndex];
    };
    return {
        columnHeaderMouseDownHandler: columnHeaderMouseDownHandler,
        isColumnDragging: isColumnDragging,
        tableCloneRef: tableCloneRef,
        tableRef: tableRef,
        setOrderedVisibleColumns: setOrderedVisibleColumns,
        orderedVisibleColumns: orderedVisibleColumns,
        draggingColumnRef: draggingColumnRef,
        draggingElementRef: draggingElementRef,
        getColumnWidth: getColumnWidth,
        getRowHeight: getRowHeight
    };
}
