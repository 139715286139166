import React from 'react';
import TableColumnValueTypes from "../../components/table/tableColumns/tableColumnValueTypes";
import DataViewType from '../../dataViews/dataViewTypes';
import QueryBuilderPropertyType from '../../components/queryBuilder/queryBuilderCondition/queryConditionPropertyTypes';
import DateTime from '../../components/formatters/DateTime';
import Number from '../../components/formatters/Number';
import Address from '../../components/formatters/Address';
export default (function () {
    var columns = [
        {
            name: 'Id',
            heading: 'Id',
            cellClassName: 'fit-content',
            headingClassName: 'fit-content',
            sortExpression: 'Leads.Id',
            valueType: TableColumnValueTypes.Number,
            cellTemplate: function (item) { return React.createElement("span", null, item.id); },
            index: 0,
            enabled: true
        },
        {
            name: 'Bond Type',
            heading: 'Bond Type',
            sortExpression: 'BondTypeName',
            valueType: TableColumnValueTypes.String,
            cellTemplate: function (item) { return React.createElement("span", null, item.bondTypeName); },
            index: 1,
            enabled: true
        },
        {
            name: 'Full Name',
            heading: 'Individual\'s Name',
            sortExpression: 'FullName',
            valueType: TableColumnValueTypes.String,
            cellTemplate: function (item) { return React.createElement("span", null, item.fullName); },
            index: 2,
            enabled: true
        },
        {
            name: 'First Name',
            heading: 'First Name',
            sortExpression: 'Person.FirstName',
            valueType: TableColumnValueTypes.String,
            cellTemplate: function (item) { return React.createElement("span", null, item.firstName); },
            index: 3,
            enabled: true
        },
        {
            name: 'Last Name',
            heading: 'Last Name',
            sortExpression: 'Person.LastName',
            valueType: TableColumnValueTypes.String,
            cellTemplate: function (item) { return React.createElement("span", null, item.lastName); },
            index: 4,
            enabled: true
        },
        {
            name: 'Company Name',
            heading: 'Company Name',
            sortExpression: 'CompanyName',
            valueType: TableColumnValueTypes.String,
            cellTemplate: function (item) { return React.createElement("span", null, item.companyName); },
            index: 5,
            enabled: true
        },
        {
            name: 'Created On',
            heading: 'Created On',
            sortExpression: 'Leads.CreatedDateTime',
            valueType: TableColumnValueTypes.DateTime,
            cellTemplate: function (item) { return React.createElement(DateTime, { date: item.createdDateTime }); },
            index: 6,
            enabled: true
        },
        {
            name: 'Bond Amount',
            heading: 'Bond Amount',
            sortExpression: 'BondAmount',
            valueType: TableColumnValueTypes.Number,
            cellTemplate: function (item) { return React.createElement(Number, { value: item.bondAmount, isCurrency: true }); },
            index: 7,
            enabled: true
        },
        {
            name: 'Quote Count',
            heading: 'Quotes Presented',
            sortExpression: 'Leads.PrequalifiedQuoteCount',
            valueType: TableColumnValueTypes.Number,
            cellTemplate: function (item) { return React.createElement("span", null, item.prequalifiedQuoteCount); },
            index: 8,
            enabled: true
        },
        {
            name: 'Desired Effective Date',
            heading: 'Desired Effective Date',
            sortExpression: 'DesiredEffectiveDate',
            valueType: TableColumnValueTypes.DateTime,
            cellTemplate: function (item) { return React.createElement(DateTime, { date: item.desiredEffectiveDate }); },
            index: 9,
            enabled: true
        },
        {
            name: 'Lead Type',
            heading: 'Lead Type',
            sortExpression: 'LeadType',
            valueType: TableColumnValueTypes.String,
            cellTemplate: function (item) { return React.createElement("span", null, item.leadType); },
            index: 10,
            enabled: true
        },
        {
            name: 'Broker',
            heading: 'Broker',
            sortExpression: 'BrokerName',
            valueType: TableColumnValueTypes.String,
            cellTemplate: function (item) { return React.createElement("span", null, item.brokerName); },
            index: 11,
            enabled: true
        },
        {
            name: 'Application Id',
            heading: 'Application Id',
            sortExpression: 'ApplicationId',
            valueType: TableColumnValueTypes.Number,
            cellTemplate: function (item) { return React.createElement("span", null, item.applicationId); },
            index: 12,
            enabled: true
        },
        {
            name: 'Carrier Count',
            heading: 'Carrier Count',
            sortExpression: 'CarrierCount',
            valueType: TableColumnValueTypes.Number,
            cellTemplate: function (item) { return React.createElement("span", null, item.carrierCount); },
            index: 13,
            enabled: true
        },
        {
            name: 'Customer Id',
            heading: 'Customer Id',
            sortExpression: 'CustomerId',
            valueType: TableColumnValueTypes.Number,
            cellTemplate: function (item) { return React.createElement("span", null, item.customerId); },
            index: 14,
            enabled: true
        },
        {
            name: 'Customer Name',
            heading: 'Customer Name',
            sortExpression: 'CustomerName',
            valueType: TableColumnValueTypes.Number,
            cellTemplate: function (item) { return React.createElement("span", null, item.customerName); },
            index: 14,
            enabled: true
        },
        {
            name: 'Lookup Code',
            heading: 'Lookup Code',
            sortExpression: 'LookupCode',
            valueType: TableColumnValueTypes.String,
            cellTemplate: function (item) { return React.createElement("span", null, item.lookupCode); },
            index: 14,
            enabled: true
        },
        {
            name: 'Customer Active Bond Count',
            heading: 'Customer Active Bond Count',
            sortExpression: 'CustomerActiveBondCount',
            valueType: TableColumnValueTypes.Number,
            cellTemplate: function (item) { return React.createElement("span", null, item.customerActiveBondCount); },
            index: 14,
            enabled: true
        },
        {
            name: 'Ip Address',
            heading: 'IP Address',
            sortExpression: 'Leads.IpAddress',
            valueType: TableColumnValueTypes.String,
            cellTemplate: function (item) { return React.createElement("span", null, item.ipAddress); },
            index: 15,
            enabled: true
        },
        {
            name: 'Submitted On',
            heading: 'Submitted On',
            sortExpression: 'SubmissionDateTime',
            valueType: TableColumnValueTypes.DateTime,
            cellTemplate: function (item) { return React.createElement(DateTime, { date: item.submissionDateTime }); },
            index: 16,
            enabled: true
        },
        {
            name: 'Name On Bond',
            heading: 'Name on Bond',
            sortExpression: 'NameOnBond',
            valueType: TableColumnValueTypes.String,
            cellTemplate: function (item) { return React.createElement("span", null, item.nameOnBond); },
            index: 17,
            enabled: true
        },
        {
            name: "Address",
            heading: "Address",
            valueType: TableColumnValueTypes.String,
            cellTemplate: function (item) { return React.createElement(Address, { address: item.physicalAddress, suiteAptNumber: item.physicalSuiteAptNumber, city: item.physicalCity, state: item.physicalState, zip: item.physicalZip }); },
            index: 18,
            enabled: true
        }
    ];
    var defaultViews = [{
            isDefault: true,
            id: -1,
            name: 'Default View',
            dataViewType: DataViewType.Leads,
            recordsPerPage: 25,
            sortExpression: 'Leads.Id',
            columns: columns,
            index: 0
        }];
    var queryConditionPropertyOptions = [
        { label: 'First Name', value: 'Person.FirstName', type: QueryBuilderPropertyType.String },
        { label: 'Last Name', value: 'Person.LastName', type: QueryBuilderPropertyType.String },
        { label: 'Company Name', value: 'ISNULL(CustomerCompanies.Name, Companies.Name)', type: QueryBuilderPropertyType.String },
        { label: 'Created On', value: 'Leads.CreatedDateTime', type: QueryBuilderPropertyType.Date },
        { label: 'Bond Type', value: 'BondTypes.Name', type: QueryBuilderPropertyType.String },
        { label: 'Bond Amount', value: 'ISNULL(Applications.BondAmount, Leads.BondAmount)', type: QueryBuilderPropertyType.Money },
        { label: 'Quotes Presented', value: 'Leads.PrequalifiedQuoteCount', type: QueryBuilderPropertyType.Integer },
        { label: 'Carrier Count', value: 'Leads.CarrierCount', type: QueryBuilderPropertyType.Integer },
        { label: 'Desired Effective Date', value: 'ISNULL(Applications.DesiredEffectiveDate, Leads.DesiredEffectiveDate)', type: QueryBuilderPropertyType.Date },
        //{ label: 'Lead Type', value: 'Leads.LeadType', type: QueryBuilderPropertyType.Integer }, todo: allow for dropdown options
        { label: 'Broker', value: 'EProducerAccounts.Name', type: QueryBuilderPropertyType.String },
        { label: 'Customer Name', value: 'Customers.Name', type: QueryBuilderPropertyType.String },
        { label: 'Customer Lookup Code', value: 'Customers.EpicLookupCode', type: QueryBuilderPropertyType.String },
        { label: 'Ip Address', value: 'Leads.IpAddress', type: QueryBuilderPropertyType.String },
        { label: 'Submitted On', value: 'Application.SubmissionDateTime', type: QueryBuilderPropertyType.Date },
        { label: 'Name On Bond', value: 'ISNULL(Applications.NameOnBond, Leads.NameOnBond)', type: QueryBuilderPropertyType.String },
        //{ label: 'Active Bond Count', value: '', type: QueryBuilderPropertyType.Integer }, // todo: translate complex sql expression to alias
        // todo: Address (person, company, customer, application, etc.)
        // todo: phone number
        // todo: email address
    ];
    return {
        columns: columns,
        defaultViews: defaultViews,
        queryConditionPropertyOptions: queryConditionPropertyOptions
    };
});
