var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from "react";
import Button from "../../button/Button";
import Modal from "../../modal/Modal";
import QueryBuilderConditionSet from "../queryBuilderConditionSet/QueryBuilderConditionSet";
import { isConditionSetValid } from "../conditionSetValidator";
import classNames from "classnames";
export default (function (_a) {
    var props = __rest(_a, []);
    var _b = useState(false), isInvalid = _b[0], setIsInvalid = _b[1];
    var _c = useState(props.conditionSet), conditionSet = _c[0], setConditionSet = _c[1];
    useEffect(function () {
        if (props.conditionSet !== conditionSet) {
            setConditionSet(props.conditionSet);
        }
    }, [props.conditionSet]);
    var conditionSetRemoved = function () {
        setConditionSet(undefined);
        setIsInvalid(false);
    };
    var conditionSetChanged = function (conditionSet) {
        setConditionSet(conditionSet);
    };
    var cancel = function () {
        setConditionSet(props.conditionSet);
        props.onClose();
    };
    var applyConditionSet = function () {
        if (conditionSet && !isConditionSetValid(conditionSet)) {
            setIsInvalid(true);
            return;
        }
        setIsInvalid(false);
        props.onApplyClick(conditionSet);
    };
    var removeQuery = function () {
        props.onApplyClick(undefined);
    };
    return (React.createElement(Modal, { heading: "Query Builder", contentClassName: classNames({ 'flex': !conditionSet }), footer: React.createElement("div", { className: "flex" },
            !!props.conditionSet &&
                React.createElement(Button, { className: "danger link", text: "Remove Query", onClick: removeQuery }),
            React.createElement(Button, { className: "ml-auto", text: "Cancel", onClick: cancel }),
            (!!conditionSet || !!props.conditionSet) &&
                React.createElement(Button, { className: "primary ml-1", text: "Apply", onClick: applyConditionSet })), onCloseButtonClicked: cancel },
        isInvalid &&
            React.createElement("div", { className: "text-danger mb-1" }, "The query below is incomplete or not valid. Please review and complete your query to proceed."),
        React.createElement(QueryBuilderConditionSet, { propertyOptions: props.propertyOptions, onChanged: conditionSetChanged, onRemoved: conditionSetRemoved, conditionSet: conditionSet })));
});
