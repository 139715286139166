var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import React, { useState } from 'react';
import Button from '../button/Button';
export default (function (_a) {
    var props = __rest(_a, []);
    var _b = useState(0), currentTabIndex = _b[0], setCurrentTabIndex = _b[1];
    return (React.createElement("div", { className: "tab-group" },
        React.createElement("div", { className: classNames(props.headingClassName, "tab-heading") },
            React.createElement("div", { className: "tab-button-wrapper" }, props.tabs.map(function (tab, index) {
                return React.createElement(Button, { key: index, className: classNames("tab-button link primary", "tab-".concat(currentTabIndex), { 'active': currentTabIndex === index }), onClick: function () { setCurrentTabIndex(index); }, text: tab.heading });
            })),
            React.createElement("div", { className: "tab-button-indicator-wrapper" },
                React.createElement("div", { className: classNames("tab-button-indicator", "active-".concat(currentTabIndex)) }))),
        React.createElement("div", { className: classNames("tab-content p-1", props.contentClassName) }, props.tabs.map(function (tab, index) {
            return React.createElement("div", { key: index, className: classNames("tab", "tab-".concat(index), { 'active': index === currentTabIndex }, { 'tab-left': index < currentTabIndex }, { 'tab-right': index > currentTabIndex }) }, tab.content);
        }))));
});
