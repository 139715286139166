var HttpStatusCodes;
(function (HttpStatusCodes) {
    // SUCCESS
    HttpStatusCodes[HttpStatusCodes["SUCCESS_OK"] = 200] = "SUCCESS_OK";
    HttpStatusCodes[HttpStatusCodes["SUCCESS_CREATED"] = 201] = "SUCCESS_CREATED";
    HttpStatusCodes[HttpStatusCodes["SUCCESS_ACCEPTED"] = 202] = "SUCCESS_ACCEPTED";
    HttpStatusCodes[HttpStatusCodes["SUCCESS_NONAUTHORITATIVE_INFORMATION"] = 203] = "SUCCESS_NONAUTHORITATIVE_INFORMATION";
    HttpStatusCodes[HttpStatusCodes["SUCCESS_NO_CONTENT"] = 204] = "SUCCESS_NO_CONTENT";
    HttpStatusCodes[HttpStatusCodes["SUCCESS_RESET_CONTENT"] = 205] = "SUCCESS_RESET_CONTENT";
    HttpStatusCodes[HttpStatusCodes["SUCCESS_PARTIAL_CONTENT"] = 206] = "SUCCESS_PARTIAL_CONTENT";
    // REDIRECT
    HttpStatusCodes[HttpStatusCodes["REDIRECT"] = 300] = "REDIRECT";
    HttpStatusCodes[HttpStatusCodes["REDIRECT_MOVED_PERMANENTLY"] = 301] = "REDIRECT_MOVED_PERMANENTLY";
    HttpStatusCodes[HttpStatusCodes["REDIRECT_OBJECT_MOVED"] = 302] = "REDIRECT_OBJECT_MOVED";
    HttpStatusCodes[HttpStatusCodes["REDIRECT_NOT_MODIFIED"] = 304] = "REDIRECT_NOT_MODIFIED";
    HttpStatusCodes[HttpStatusCodes["REDIRECT_TEMPORARY_REDIRECT"] = 307] = "REDIRECT_TEMPORARY_REDIRECT";
    // BAD REQUEST
    HttpStatusCodes[HttpStatusCodes["BAD_REQUEST"] = 400] = "BAD_REQUEST";
    HttpStatusCodes[HttpStatusCodes["BAD_REQUEST_INVALID_DESTINATION_HEADER"] = 400.1] = "BAD_REQUEST_INVALID_DESTINATION_HEADER";
    HttpStatusCodes[HttpStatusCodes["BAD_REQUEST_INVALID_DEPTH_HEADER"] = 400.2] = "BAD_REQUEST_INVALID_DEPTH_HEADER";
    HttpStatusCodes[HttpStatusCodes["BAD_REQUEST_INVALID_IF_HEADER"] = 400.3] = "BAD_REQUEST_INVALID_IF_HEADER";
    HttpStatusCodes[HttpStatusCodes["BAD_REQUEST_INVALID_OVERWRITE_HEADER"] = 400.4] = "BAD_REQUEST_INVALID_OVERWRITE_HEADER";
    HttpStatusCodes[HttpStatusCodes["BAD_REQUEST_INVALID_TRANSLATE_HEADER"] = 400.5] = "BAD_REQUEST_INVALID_TRANSLATE_HEADER";
    HttpStatusCodes[HttpStatusCodes["BAD_REQUEST_INVALID_REQUEST_BODY"] = 400.6] = "BAD_REQUEST_INVALID_REQUEST_BODY";
    HttpStatusCodes[HttpStatusCodes["BAD_REQUEST_INVALID_CONTENT_LENGTH"] = 400.7] = "BAD_REQUEST_INVALID_CONTENT_LENGTH";
    HttpStatusCodes[HttpStatusCodes["BAD_REQUEST_INVALID_TIMEOUT"] = 400.8] = "BAD_REQUEST_INVALID_TIMEOUT";
    HttpStatusCodes[HttpStatusCodes["BAD_REQUEST_INVALID_LOCK_TOKEN"] = 400.9] = "BAD_REQUEST_INVALID_LOCK_TOKEN";
    // ACCESS DENIED
    HttpStatusCodes[HttpStatusCodes["ACCESS_DENIED"] = 401] = "ACCESS_DENIED";
    HttpStatusCodes[HttpStatusCodes["ACCESS_DENIED_LOGON_FAILED_SERVER_CONFIGURATION"] = 401.2] = "ACCESS_DENIED_LOGON_FAILED_SERVER_CONFIGURATION";
    HttpStatusCodes[HttpStatusCodes["ACCESS_DENIED_UNAUTHORIZED_DUE_TO_ACL"] = 401.3] = "ACCESS_DENIED_UNAUTHORIZED_DUE_TO_ACL";
    HttpStatusCodes[HttpStatusCodes["ACCESS_DENIED_AUTHORIZATION_FAILED_FILTER"] = 401.4] = "ACCESS_DENIED_AUTHORIZATION_FAILED_FILTER";
    HttpStatusCodes[HttpStatusCodes["ACCESS_DENIED_AUTHORIZATION_FAILED_ISAPI_APPLICATION"] = 401.5] = "ACCESS_DENIED_AUTHORIZATION_FAILED_ISAPI_APPLICATION";
    HttpStatusCodes[HttpStatusCodes["ACCESS_DENIED_TOO_MANY_REQUESTS"] = 401.501] = "ACCESS_DENIED_TOO_MANY_REQUESTS";
    HttpStatusCodes[HttpStatusCodes["ACCESS_DENIED_TOO_MANY_REQUESTS_ALT"] = 401.502] = "ACCESS_DENIED_TOO_MANY_REQUESTS_ALT";
    HttpStatusCodes[HttpStatusCodes["ACCESS_DENIED_IP_RESTRICTED"] = 401.503] = "ACCESS_DENIED_IP_RESTRICTED";
    HttpStatusCodes[HttpStatusCodes["ACCESS_DENIED_HOST_NAME_RESTRICTED"] = 401.504] = "ACCESS_DENIED_HOST_NAME_RESTRICTED";
    // FORBIDDEN
    HttpStatusCodes[HttpStatusCodes["FORBIDDEN"] = 403] = "FORBIDDEN";
    HttpStatusCodes[HttpStatusCodes["FORBIDDEN_EXECUTE_ACCESS_FORBIDDEN"] = 403.1] = "FORBIDDEN_EXECUTE_ACCESS_FORBIDDEN";
    HttpStatusCodes[HttpStatusCodes["FORBIDDEN_READ_ACCESS_FORBIDDEN"] = 403.2] = "FORBIDDEN_READ_ACCESS_FORBIDDEN";
    HttpStatusCodes[HttpStatusCodes["FORBIDDEN_WRITE_ACCESS_FORBIDDEN"] = 403.3] = "FORBIDDEN_WRITE_ACCESS_FORBIDDEN";
    HttpStatusCodes[HttpStatusCodes["FORBIDDEN_SSL_REQUIRED"] = 403.4] = "FORBIDDEN_SSL_REQUIRED";
    HttpStatusCodes[HttpStatusCodes["FORBIDDEN_SSL_128_REQUIRED"] = 403.5] = "FORBIDDEN_SSL_128_REQUIRED";
    HttpStatusCodes[HttpStatusCodes["FORBIDDEN_IP_ADDRESS_REJECTED"] = 403.6] = "FORBIDDEN_IP_ADDRESS_REJECTED";
    HttpStatusCodes[HttpStatusCodes["FORBIDDEN_CLIENT_CERTIFICATE_REQUIRED"] = 403.7] = "FORBIDDEN_CLIENT_CERTIFICATE_REQUIRED";
    HttpStatusCodes[HttpStatusCodes["FORBIDDEN_SITE_ACCESS_DEINED"] = 403.8] = "FORBIDDEN_SITE_ACCESS_DEINED";
    HttpStatusCodes[HttpStatusCodes["FORBIDDEN_TO_MANY_CLIENT_CONNECTIONS"] = 403.9] = "FORBIDDEN_TO_MANY_CLIENT_CONNECTIONS";
    HttpStatusCodes[HttpStatusCodes["FORBIDDEN_SERVER_DENIED_EXECUTE_ACCESS"] = 403.1] = "FORBIDDEN_SERVER_DENIED_EXECUTE_ACCESS";
    HttpStatusCodes[HttpStatusCodes["FORBIDDEN_PASSWORD_HAS_BEEN_CHANGED"] = 403.11] = "FORBIDDEN_PASSWORD_HAS_BEEN_CHANGED";
    HttpStatusCodes[HttpStatusCodes["FORBIDDEN_MAPPER_DENIED_ACCESS"] = 403.12] = "FORBIDDEN_MAPPER_DENIED_ACCESS";
    HttpStatusCodes[HttpStatusCodes["FORBIDDEN_CLIENT_CERTIFICATE_REVOKED"] = 403.13] = "FORBIDDEN_CLIENT_CERTIFICATE_REVOKED";
    HttpStatusCodes[HttpStatusCodes["FORBIDDEN_DIRECTORY_LISTING_DENIED"] = 403.14] = "FORBIDDEN_DIRECTORY_LISTING_DENIED";
    HttpStatusCodes[HttpStatusCodes["FORBIDDEN_CLIENT_ACCESS_LICENSES_EXCEEDED"] = 403.15] = "FORBIDDEN_CLIENT_ACCESS_LICENSES_EXCEEDED";
    HttpStatusCodes[HttpStatusCodes["FORBIDDEN_CLIENT_CERTIFICATE_NOT_ACCEPTED"] = 403.16] = "FORBIDDEN_CLIENT_CERTIFICATE_NOT_ACCEPTED";
    HttpStatusCodes[HttpStatusCodes["FORBIDDEN_CLIENT_CERTIFICATE_EXPIRED"] = 403.17] = "FORBIDDEN_CLIENT_CERTIFICATE_EXPIRED";
    HttpStatusCodes[HttpStatusCodes["FORBIDDEN_CANNOT_EXECUTE_URL_IN_CURRENT_APPLICATION_POOL"] = 403.18] = "FORBIDDEN_CANNOT_EXECUTE_URL_IN_CURRENT_APPLICATION_POOL";
    HttpStatusCodes[HttpStatusCodes["FORBIDDEN_CANNOT_EXECUTE_CGI_APPLICATIONS_IN_APPLICATION_POOL"] = 403.19] = "FORBIDDEN_CANNOT_EXECUTE_CGI_APPLICATIONS_IN_APPLICATION_POOL";
    HttpStatusCodes[HttpStatusCodes["FORBIDDEN_PASSPORT_LOGON_FAILED"] = 403.2] = "FORBIDDEN_PASSPORT_LOGON_FAILED";
    HttpStatusCodes[HttpStatusCodes["FORBIDDEN_SOURCE_ACCESS_DENIED"] = 403.21] = "FORBIDDEN_SOURCE_ACCESS_DENIED";
    HttpStatusCodes[HttpStatusCodes["FORBIDDEN_INFINITE_DEPTH_DENIED"] = 403.22] = "FORBIDDEN_INFINITE_DEPTH_DENIED";
    HttpStatusCodes[HttpStatusCodes["FORBIDDEN_TOO_MANY_REQUESTS"] = 403.501] = "FORBIDDEN_TOO_MANY_REQUESTS";
    HttpStatusCodes[HttpStatusCodes["FORBIDDEN_TOO_MANY_REQUESTS_ALT"] = 403.502] = "FORBIDDEN_TOO_MANY_REQUESTS_ALT";
    HttpStatusCodes[HttpStatusCodes["FORBIDDEN_IP_RESTRICTED"] = 403.503] = "FORBIDDEN_IP_RESTRICTED";
    HttpStatusCodes[HttpStatusCodes["FORBIDDEN_HOST_NAME_RESTRICTED"] = 403.504] = "FORBIDDEN_HOST_NAME_RESTRICTED";
    // NOT FOUND
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND"] = 404] = "NOT_FOUND";
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND_SITE_NOT_FOUND"] = 404.1] = "NOT_FOUND_SITE_NOT_FOUND";
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND_ISAPI_OR_CGI_RESTRICTION"] = 404.2] = "NOT_FOUND_ISAPI_OR_CGI_RESTRICTION";
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND_MIME_TYPE_RESTRICTION"] = 404.3] = "NOT_FOUND_MIME_TYPE_RESTRICTION";
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND_NO_HANDLER_CONFIGURED"] = 404.4] = "NOT_FOUND_NO_HANDLER_CONFIGURED";
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND_DENIED_BY_REQUEST_FILTERING"] = 404.5] = "NOT_FOUND_DENIED_BY_REQUEST_FILTERING";
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND_VERB_DENIED"] = 404.6] = "NOT_FOUND_VERB_DENIED";
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND_FILE_EXTENSION_DENIED"] = 404.7] = "NOT_FOUND_FILE_EXTENSION_DENIED";
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND_HIDDEN_NAMESPACE"] = 404.8] = "NOT_FOUND_HIDDEN_NAMESPACE";
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND_FILE_ATTRIBUTE_HIDDEN"] = 404.9] = "NOT_FOUND_FILE_ATTRIBUTE_HIDDEN";
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND_REQUEST_HEADER_TOO_LONG"] = 404.1] = "NOT_FOUND_REQUEST_HEADER_TOO_LONG";
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND_REQUEST_CONTAINS_DOUBLE_ESCAPE_SEQUENCE"] = 404.11] = "NOT_FOUND_REQUEST_CONTAINS_DOUBLE_ESCAPE_SEQUENCE";
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND_REQUEST_CONTAINS_HIGH_BIT_CHARACTERS"] = 404.12] = "NOT_FOUND_REQUEST_CONTAINS_HIGH_BIT_CHARACTERS";
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND_CONTENT_TOO_LARGE"] = 404.13] = "NOT_FOUND_CONTENT_TOO_LARGE";
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND_REQUEST_URL_TOO_LONG"] = 404.14] = "NOT_FOUND_REQUEST_URL_TOO_LONG";
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND_QUERY_STRING_TOO_LONG"] = 404.15] = "NOT_FOUND_QUERY_STRING_TOO_LONG";
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND_DAV_REQUEST_SENT_TO_STATIC_FILE_HANDLER"] = 404.16] = "NOT_FOUND_DAV_REQUEST_SENT_TO_STATIC_FILE_HANDLER";
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND_DYNAMIC_CONTENT_MAPPED_TO_STATIC_FILE_HANDLER_VIA_WILDCARD_MIME_MAPPING"] = 404.17] = "NOT_FOUND_DYNAMIC_CONTENT_MAPPED_TO_STATIC_FILE_HANDLER_VIA_WILDCARD_MIME_MAPPING";
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND_QUERY_STRING_SEQUENCE_DENIED"] = 404.18] = "NOT_FOUND_QUERY_STRING_SEQUENCE_DENIED";
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND_DENIED_BY_FILTERING_RULE"] = 404.19] = "NOT_FOUND_DENIED_BY_FILTERING_RULE";
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND_TOO_MANY_URL_SEGMENTS"] = 404.2] = "NOT_FOUND_TOO_MANY_URL_SEGMENTS";
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND_TOO_MANY_REQUESTS"] = 404.501] = "NOT_FOUND_TOO_MANY_REQUESTS";
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND_TOO_MANY_REQUESTS_ALT"] = 404.502] = "NOT_FOUND_TOO_MANY_REQUESTS_ALT";
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND_IP_RESTRICTED"] = 404.503] = "NOT_FOUND_IP_RESTRICTED";
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND_HOST_NAME_RESTRICTED"] = 404.504] = "NOT_FOUND_HOST_NAME_RESTRICTED";
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND_METHOD_NOT_ALLOWED"] = 405] = "NOT_FOUND_METHOD_NOT_ALLOWED";
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND_CLIENT_BROWSER_DOES_NOT_ACCEPT_MIME_TYPE_REQUESTED"] = 406] = "NOT_FOUND_CLIENT_BROWSER_DOES_NOT_ACCEPT_MIME_TYPE_REQUESTED";
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND_REQUEST_TIMED_OUT"] = 408] = "NOT_FOUND_REQUEST_TIMED_OUT";
    HttpStatusCodes[HttpStatusCodes["NOT_FOUND_PRECONDITION_FAILED"] = 412] = "NOT_FOUND_PRECONDITION_FAILED";
    // SERVER ERROR
    HttpStatusCodes[HttpStatusCodes["SERVER_ERROR"] = 500] = "SERVER_ERROR";
    HttpStatusCodes[HttpStatusCodes["SERVER_ERROR_APPLICATION_SHUTTING_DOWN"] = 500.11] = "SERVER_ERROR_APPLICATION_SHUTTING_DOWN";
    HttpStatusCodes[HttpStatusCodes["SERVER_ERROR_APPLICATION_RESTARTING"] = 500.12] = "SERVER_ERROR_APPLICATION_RESTARTING";
    HttpStatusCodes[HttpStatusCodes["SERVER_ERROR_SERVER_TOO_BUSY"] = 500.13] = "SERVER_ERROR_SERVER_TOO_BUSY";
    HttpStatusCodes[HttpStatusCodes["SERVER_ERROR_DIRECT_REQUEST_NOT_ALLOWED"] = 500.15] = "SERVER_ERROR_DIRECT_REQUEST_NOT_ALLOWED";
    HttpStatusCodes[HttpStatusCodes["SERVER_ERROR_CONFIGURATION_DATA_INVALID"] = 500.19] = "SERVER_ERROR_CONFIGURATION_DATA_INVALID";
    HttpStatusCodes[HttpStatusCodes["SERVER_ERROR_MODULE_NOT_RECOGNIZED"] = 500.21] = "SERVER_ERROR_MODULE_NOT_RECOGNIZED";
    HttpStatusCodes[HttpStatusCodes["SERVER_ERROR_ASPNET_HTTPMODULES_CONFIGURATION_DOES_NOT_APPLY"] = 500.22] = "SERVER_ERROR_ASPNET_HTTPMODULES_CONFIGURATION_DOES_NOT_APPLY";
    HttpStatusCodes[HttpStatusCodes["SERVER_ERROR_ASPNET_HTTPHANDLERS_CONFIGURATION_DOES_NOT_APPLY"] = 500.23] = "SERVER_ERROR_ASPNET_HTTPHANDLERS_CONFIGURATION_DOES_NOT_APPLY";
    HttpStatusCodes[HttpStatusCodes["SERVER_ERROR_ASPNET_IMPERSONATION_CONFIGURATION_DOES_NOT_APPLY"] = 500.24] = "SERVER_ERROR_ASPNET_IMPERSONATION_CONFIGURATION_DOES_NOT_APPLY";
    HttpStatusCodes[HttpStatusCodes["SERVER_ERROR_DURING_REWRITE_RQ_BEGIN_REQUEST"] = 500.5] = "SERVER_ERROR_DURING_REWRITE_RQ_BEGIN_REQUEST";
    HttpStatusCodes[HttpStatusCodes["SERVER_ERROR_DURING_REWRITE_GL_PRE_BEGIN_REQUEST"] = 500.51] = "SERVER_ERROR_DURING_REWRITE_GL_PRE_BEGIN_REQUEST";
    HttpStatusCodes[HttpStatusCodes["SERVER_ERROR_DURING_REWRITE_RQ_SEND_RESPONSE"] = 500.52] = "SERVER_ERROR_DURING_REWRITE_RQ_SEND_RESPONSE";
    HttpStatusCodes[HttpStatusCodes["SERVER_ERROR_DURING_REWRITE_RQ_RELEASE_REQUEST_STATE"] = 500.53] = "SERVER_ERROR_DURING_REWRITE_RQ_RELEASE_REQUEST_STATE";
    HttpStatusCodes[HttpStatusCodes["SERVER_ERROR_INTERNAL_APS_ERROR"] = 500.1] = "SERVER_ERROR_INTERNAL_APS_ERROR";
    // SPECIFIED CONFIGURATION NOT IMPLEMENTED
    HttpStatusCodes[HttpStatusCodes["SPECIFIED_CONFIGURATION_NOT_IMPLEMENTED"] = 501] = "SPECIFIED_CONFIGURATION_NOT_IMPLEMENTED";
    // INVALID RESPONSE
    HttpStatusCodes[HttpStatusCodes["INVALID_RESPONSE"] = 502] = "INVALID_RESPONSE";
    // SERVICE UNAVAILABLE
    HttpStatusCodes[HttpStatusCodes["SERVICE_UNAVAILABLE"] = 503] = "SERVICE_UNAVAILABLE";
    HttpStatusCodes[HttpStatusCodes["SERVICE_UNAVAILABLE_CONCURRENT_REQUEST_LIMIT_EXCEEDED"] = 503.2] = "SERVICE_UNAVAILABLE_CONCURRENT_REQUEST_LIMIT_EXCEEDED";
    HttpStatusCodes[HttpStatusCodes["SERVICE_UNAVAILABLE_ASPNET_QUEUE_FULL"] = 503.3] = "SERVICE_UNAVAILABLE_ASPNET_QUEUE_FULL";
    HttpStatusCodes[HttpStatusCodes["SERVICE_UNAVAILABLE_FAST_CGI_QUEUE_FULL"] = 503.4] = "SERVICE_UNAVAILABLE_FAST_CGI_QUEUE_FULL";
})(HttpStatusCodes || (HttpStatusCodes = {}));
export default HttpStatusCodes;
