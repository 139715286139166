var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useEffect, useReducer, useRef } from "react";
import createId from "../utils/createId";
import React from "react";
import Modal from "../components/modal/Modal";
import Button from "../components/button/Button";
import IconStyles from "../components/icons/iconStyles";
import SaveDataViewAsModal from "./SaveDataViewAsModal";
import RenameDataViewModal from "./RenameDataViewModal";
import DataViewMenu from "./DataViewMenu";
import ColumnSelectionMenu from "./ColumnSelectionMenu";
import { useDeleteUserDataView, useGetDataViews, useSaveUserDataViews } from "../api/userDataViews/userDataViewApi";
var dataViewReducer = function (state, action) {
    switch (action.type) {
        case 'ResetCurrentView':
            return dataViewReducer(state, { type: 'SetCurrentView', viewId: state.currentView.id });
        case 'SaveCurrentView':
            var saveViews = state.views.map(function (view) {
                if (view.id === state.currentView.id) {
                    return state.currentView;
                }
                return view;
            });
            return __assign(__assign({}, state), { views: saveViews, hasChanges: false });
        case 'SaveViewAs': {
            var newView = __assign(__assign({}, state.currentView), { id: createId(state.views), name: action.name, index: Math.max.apply(Math, state.views.map(function (view) { return view.index; })) + 1, isDefault: false });
            return __assign(__assign({}, state), { views: state.views.concat(newView), currentView: newView, hasChanges: false, isSaveAsModalVisible: false });
        }
        case 'SearchPhraseChanged':
            if (state.tableQueryOptions.searchPhrase === action.searchPhrase) {
                return state;
            }
            return __assign(__assign({}, state), { tableQueryOptions: __assign(__assign({}, state.tableQueryOptions), { searchPhrase: action.searchPhrase }) });
        case 'SetCurrentView':
            var newlySelectedView = state.views.filter(function (v) { return v.id === action.viewId; })[0];
            return __assign(__assign({}, state), { currentView: newlySelectedView, tableQueryOptions: __assign(__assign({}, state.tableQueryOptions), { orderBy: newlySelectedView.sortExpression, recordsPerPage: newlySelectedView.recordsPerPage, conditionSet: newlySelectedView.conditionSet, pageNumber: 1 }), hasChanges: false });
        case 'ApplyQueryBuilderConditionSet':
            return __assign(__assign({}, state), { tableQueryOptions: __assign(__assign({}, state.tableQueryOptions), { conditionSet: action.conditionSet }), currentView: __assign(__assign({}, state.currentView), { conditionSet: action.conditionSet }), hasChanges: true, isQueryBuilderModalOpen: false });
        case 'TableChanged':
            var hasChanges = false;
            if (state.currentView.sortExpression != action.newTableQueryOptions.orderBy ||
                state.currentView.recordsPerPage != action.newTableQueryOptions.recordsPerPage) {
                hasChanges = true;
            }
            return __assign(__assign({}, state), { tableQueryOptions: __assign(__assign({}, state.tableQueryOptions), { orderBy: action.newTableQueryOptions.orderBy, pageNumber: action.newTableQueryOptions.pageNumber, recordsPerPage: action.newTableQueryOptions.recordsPerPage }), currentView: __assign(__assign({}, state.currentView), { sortExpression: action.newTableQueryOptions.orderBy, recordsPerPage: action.newTableQueryOptions.recordsPerPage }), hasChanges: hasChanges });
        case 'SetQueryBuilderModalVisibility':
            return __assign(__assign({}, state), { isQueryBuilderModalOpen: action.isVisible });
        case 'SetSaveAsModalVisibility':
            return __assign(__assign({}, state), { isSaveAsModalVisible: action.isVisible });
        case 'ColumnOrderChanged':
            return __assign(__assign({}, state), { currentView: __assign(__assign({}, state.currentView), { columns: action.columns }), hasChanges: true });
        case 'SetRenameModalVisibility':
            return __assign(__assign({}, state), { isRenameModalVisible: action.isVisible });
        case 'RenameView':
            var renameViews = state.views.map(function (_a) {
                var view = __rest(_a, []);
                if (view.id === state.currentView.id) {
                    view.name = action.name;
                }
                return view;
            });
            return __assign(__assign({}, state), { views: renameViews, currentView: __assign(__assign({}, state.currentView), { name: action.name }), isRenameModalVisible: false });
        case 'SetDeleteConfirmationModalVisibility':
            return __assign(__assign({}, state), { isDeleteConfirmationVisible: action.isVisible });
        case 'DeleteCurrentView':
            return __assign(__assign({}, state), { views: state.views.filter(function (view) { return view.id !== state.currentView.id; }), currentView: state.views[0], isDeleteConfirmationVisible: false, hasChanges: false });
        case 'ApplyColumnEnabledChange':
            // if new column is being activated and not in the current view column list then add it
            if (!state.currentView.columns.some(function (column) { return column.name === action.column.name; })) {
                // set enabled to false so that the logic to toggle remains the same
                action.column.enabled = false;
                state.currentView.columns.push(action.column);
            }
            return __assign(__assign({}, state), { currentView: __assign(__assign({}, state.currentView), { columns: state.currentView.columns.map(function (_a) {
                        var column = __rest(_a, []);
                        if (column.name === action.column.name) {
                            column.enabled = column.enabled !== true;
                        }
                        return column;
                    }) }), hasChanges: true });
        case 'UserDataViewsLoaded':
            return __assign(__assign({}, state), { views: action.userDataViews, currentView: __assign({}, action.userDataViews[0]), tableQueryOptions: {
                    recordsPerPage: action.userDataViews[0].recordsPerPage,
                    orderBy: action.userDataViews[0].sortExpression,
                    conditionSet: action.userDataViews[0].conditionSet,
                    pageNumber: 1
                }, dataViewsLoaded: true });
        case 'SetViews':
            return __assign(__assign({}, state), { views: action.views });
        default:
            throw new Error('Invalid Action Type');
    }
};
export default (function (defaultDataViews, columns, dataViewType) {
    var _a = useReducer(dataViewReducer, {
        views: [],
        currentView: defaultDataViews[0],
        isQueryBuilderModalOpen: false,
        isRenameModalVisible: false,
        isSaveAsModalVisible: false,
        hasChanges: false,
        isDeleteConfirmationVisible: false,
        tableQueryOptions: {
            recordsPerPage: defaultDataViews[0].recordsPerPage,
            orderBy: defaultDataViews[0].sortExpression,
            pageNumber: 1
        },
        dataViewsLoaded: false
    }), state = _a[0], dispatch = _a[1];
    var _b = useGetDataViews(dataViewType), persistedUserDataViews = _b.data, fetchDataViews = _b.fetch, fetchDataViewsCallCount = _b.callCount;
    useEffect(function () {
        if (fetchDataViewsCallCount > 0) {
            if (persistedUserDataViews === null || persistedUserDataViews === void 0 ? void 0 : persistedUserDataViews.length) {
                var userDataViews = persistedUserDataViews
                    .filter(function (persistedDataView) { return persistedDataView.isDefault !== true; })
                    .map(function (persistedDataView) {
                    var _a;
                    var dataView = {
                        id: persistedDataView.id,
                        name: persistedDataView.name,
                        dataViewType: dataViewType,
                        index: persistedDataView.index,
                        recordsPerPage: persistedDataView.recordsPerPage,
                        sortExpression: persistedDataView.sortExpression,
                        columns: []
                    };
                    if (persistedDataView.conditionSetJson) {
                        dataView.conditionSet = __assign({}, JSON.parse(persistedDataView.conditionSetJson));
                    }
                    var parsedColumns = [];
                    for (var i = 0; i < columns.length; i++) {
                        var persistedColumn = persistedDataView.columns.find(function (column) { return column.name === columns[i].name; });
                        parsedColumns.push(__assign(__assign({}, columns[i]), { enabled: !!persistedColumn, index: (_a = persistedColumn === null || persistedColumn === void 0 ? void 0 : persistedColumn.index) !== null && _a !== void 0 ? _a : columns[i].index }));
                    }
                    dataView.columns = parsedColumns.sort(function (a, b) { return a.index - b.index; });
                    return dataView;
                });
                for (var i = 0; i < defaultDataViews.length; i++) {
                    var matchingPersistedDataView = persistedUserDataViews.find(function (persistedDataView) { return persistedDataView.isDefault && persistedDataView.name === defaultDataViews[i].name; });
                    if (matchingPersistedDataView) {
                        defaultDataViews[i].index = matchingPersistedDataView.index;
                    }
                    userDataViews.push(defaultDataViews[i]);
                }
                var sortedDataViews = userDataViews.sort(function (a, b) { return a.index - b.index; });
                dispatch({ type: 'UserDataViewsLoaded', userDataViews: sortedDataViews });
            }
            else {
                dispatch({ type: 'UserDataViewsLoaded', userDataViews: defaultDataViews });
            }
        }
    }, [persistedUserDataViews]);
    useEffect(function () {
        fetchDataViews();
    }, []);
    var shouldSaveRef = useRef(false);
    useEffect(function () {
        if (shouldSaveRef.current) {
            shouldSaveRef.current = false;
            saveDataViews(state.views);
        }
    }, [state.views]);
    var saveDataViews = useSaveUserDataViews(dataViewType).submit;
    var deleteDataView = useDeleteUserDataView(dataViewType).submit;
    var tableChanged = function (newTableQueryOptions) {
        dispatch({ type: 'TableChanged', newTableQueryOptions: newTableQueryOptions });
    };
    var saveViewAs = function (name) {
        dispatch({ type: 'SaveViewAs', name: name });
        shouldSaveRef.current = true;
    };
    var showQueryBuilderModal = function () {
        dispatch({ type: 'SetQueryBuilderModalVisibility', isVisible: true });
    };
    var hideQueryBuilderModal = function () {
        dispatch({ type: 'SetQueryBuilderModalVisibility', isVisible: false });
    };
    var resetCurrentView = function () {
        dispatch({ type: 'ResetCurrentView' });
    };
    var hideSaveAsModal = function () {
        dispatch({ type: 'SetSaveAsModalVisibility', isVisible: false });
    };
    var showSaveAsModal = function () {
        dispatch({ type: 'SetSaveAsModalVisibility', isVisible: true });
    };
    var setView = function (viewId) {
        dispatch({ type: 'SetCurrentView', viewId: viewId });
    };
    var saveCurrentView = function () {
        dispatch({ type: 'SaveCurrentView' });
        shouldSaveRef.current = true;
    };
    var searchPhraseChanged = function (searchPhrase) {
        dispatch({ type: 'SearchPhraseChanged', searchPhrase: searchPhrase });
    };
    var showRenameModal = function () {
        dispatch({ type: 'SetRenameModalVisibility', isVisible: true });
    };
    var hideRenameModal = function () {
        dispatch({ type: 'SetRenameModalVisibility', isVisible: false });
    };
    var renameView = function (name) {
        dispatch({ type: 'RenameView', name: name });
        shouldSaveRef.current = true;
    };
    var applyQueryBuilderConditionSet = function (conditionSet) {
        dispatch({ type: 'ApplyQueryBuilderConditionSet', conditionSet: conditionSet });
    };
    var columnOrderChanged = function (columns) {
        dispatch({ type: 'ColumnOrderChanged', columns: columns });
    };
    var showDeleteViewConfirmation = function () {
        dispatch({ type: 'SetDeleteConfirmationModalVisibility', isVisible: true });
    };
    var hideDeleteViewConfirmation = function () {
        dispatch({ type: 'SetDeleteConfirmationModalVisibility', isVisible: false });
    };
    var deleteCurrentView = function () {
        {
            dispatch({ type: 'DeleteCurrentView' });
            deleteDataView(state.currentView.id);
        }
    };
    var viewsReordered = function (views) {
        dispatch({ type: 'SetViews', views: views });
        shouldSaveRef.current = true;
    };
    var applyColumnEnabledChange = function (column) {
        dispatch({ type: 'ApplyColumnEnabledChange', column: column });
    };
    var buildDataViewModals = function () {
        return (React.createElement(React.Fragment, null,
            state.isSaveAsModalVisible &&
                React.createElement(SaveDataViewAsModal, { onCancel: hideSaveAsModal, onSaveAs: saveViewAs }),
            !!state.isRenameModalVisible &&
                React.createElement(RenameDataViewModal, { onCancel: hideRenameModal, onSave: renameView, name: state.currentView.name }),
            !!state.isDeleteConfirmationVisible &&
                React.createElement(Modal, { heading: "Delete View", className: "blackout no-header", windowClassName: "w-25-p", onCloseButtonClicked: hideDeleteViewConfirmation },
                    React.createElement("div", null,
                        React.createElement("div", null, "Are you sure you want to delete he current view?"),
                        React.createElement("div", { className: "mt-2 flex" },
                            React.createElement(Button, { text: "Cancel", className: "button-small ml-auto mr-1", onClick: hideDeleteViewConfirmation }),
                            React.createElement(Button, { leftIcon: "trash", leftIconStyle: IconStyles.Duotone, text: "Delete", className: "primary button-small", onClick: deleteCurrentView }))))));
    };
    var buildDataViewMenu = function () {
        return (React.createElement(DataViewMenu, { hasChanges: !!state.hasChanges, views: __spreadArray([], state.views, true), currentView: __assign({}, state.currentView), isLoaded: state.dataViewsLoaded, onSetView: setView, onResetCurrentView: resetCurrentView, onShowSaveAsModal: showSaveAsModal, onSaveCurrentView: saveCurrentView, onShowRenameModal: showRenameModal, onShowDeleteViewConfirmationModal: showDeleteViewConfirmation, onViewsReordered: viewsReordered }));
    };
    var buildColumnSelectionMenu = function () {
        return (React.createElement(ColumnSelectionMenu, { columns: columns, currentView: __assign({}, state.currentView), onColumnEnabledChanged: applyColumnEnabledChange }));
    };
    return {
        buildColumnSelectionMenu: buildColumnSelectionMenu,
        showQueryBuilderModal: showQueryBuilderModal,
        hideQueryBuilderModal: hideQueryBuilderModal,
        applyQueryBuilderConditionSet: applyQueryBuilderConditionSet,
        buildDataViewModals: buildDataViewModals,
        buildDataViewMenu: buildDataViewMenu,
        columnOrderChanged: columnOrderChanged,
        tableChanged: tableChanged,
        searchPhraseChanged: searchPhraseChanged,
        isQueryBuilderModalOpen: state.isQueryBuilderModalOpen,
        tableQueryOptions: state.tableQueryOptions,
        currentView: state.currentView,
        dataViewsLoaded: state.dataViewsLoaded
    };
});
