var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import useCustomerList from "./useCustomerList";
import { useGetCustomerList } from '../../../api/customers/useCustomerApi';
import DataViewTable from "../../../components/table/dataViewTable/DataViewTable";
import DataViewType from "../../../dataViews/dataViewTypes";
import OpenNewCustomerModalButton from "../../../app/customers/newCustomerModal/OpenNewCustomerModalButton";
export default (function () {
    var _a = useCustomerList(), columns = _a.columns, defaultViews = _a.defaultViews, queryConditionProperyOptions = _a.queryConditionProperyOptions;
    var getCustomerList = useGetCustomerList();
    return (React.createElement(DataViewTable, __assign({ heading: "Customers", columns: columns }, getCustomerList, { defaultDataViews: defaultViews, queryConditionProperyOptions: queryConditionProperyOptions, dataViewType: DataViewType.Customers, tablePagerClassName: "sticky-footer", actionButtons: [
            React.createElement(OpenNewCustomerModalButton, { className: "button-small" })
        ] })));
});
