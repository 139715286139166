import classNames from 'classnames';
import React from 'react';
import Checkbox from '../../forms/Checkbox';
import Icon from '../../icons/Icon';
import IconStyles from '../../icons/iconStyles';
import TableHeadCell from './TableHeadCell';
export default (function (_a) {
    var showExpandedContentColumn = _a.showExpandedContentColumn, showCheckAllColumn = _a.showCheckAllColumn, columns = _a.columns, currentOrderBy = _a.currentOrderBy, allRowsChecked = _a.allRowsChecked, onSortChanged = _a.onSortChanged, onSelectAllChecked = _a.onSelectAllChecked, onDragHandleMouseDown = _a.onDragHandleMouseDown, columnReorderEnabled = _a.columnReorderEnabled;
    return (React.createElement("thead", null,
        React.createElement("tr", null,
            showExpandedContentColumn &&
                React.createElement("th", { className: "expander-column" }),
            showCheckAllColumn &&
                React.createElement("th", { className: "selecter-column" },
                    React.createElement(Checkbox, { value: allRowsChecked, onChange: onSelectAllChecked })),
            columns.map(function (column) { return (React.createElement("th", { className: classNames({ 'draggable': columnReorderEnabled && !!onDragHandleMouseDown }, column.headingClassName), key: column.name },
                columnReorderEnabled && !!onDragHandleMouseDown &&
                    React.createElement("span", { className: 'table-header-drag-handle', onMouseDown: onDragHandleMouseDown(column) },
                        React.createElement(Icon, { icon: 'grip-lines-vertical', style: IconStyles.Regular })),
                React.createElement(TableHeadCell, { column: column, currentOrderBy: currentOrderBy, onSortChanged: onSortChanged }))); }))));
});
