import Routes from "./routes";
export var useRouteBuilder = function () {
    var getRoutes = function (routeType) {
        return Routes.filter(function (route) { return route.routeType === routeType; });
    };
    var getLoginRoute = function () {
        return Routes.filter(function (route) { return route.name === 'Login'; })[0];
    };
    return {
        getRoutes: getRoutes,
        getLoginRoute: getLoginRoute
    };
};
