export var useLocalStorage = function (key) {
    var itemString = window.localStorage.getItem(key);
    var item = null;
    if (itemString) {
        item = JSON.parse(itemString);
    }
    var set = function (obj) {
        window.localStorage.setItem(key, JSON.stringify(obj));
    };
    var remove = function () {
        window.localStorage.removeItem(key);
    };
    return [item, set, remove];
};
