var QueryBuilderPropertyType;
(function (QueryBuilderPropertyType) {
    QueryBuilderPropertyType[QueryBuilderPropertyType["String"] = 1] = "String";
    QueryBuilderPropertyType[QueryBuilderPropertyType["Money"] = 2] = "Money";
    QueryBuilderPropertyType[QueryBuilderPropertyType["Integer"] = 3] = "Integer";
    QueryBuilderPropertyType[QueryBuilderPropertyType["Decimal"] = 4] = "Decimal";
    QueryBuilderPropertyType[QueryBuilderPropertyType["Boolean"] = 5] = "Boolean";
    QueryBuilderPropertyType[QueryBuilderPropertyType["Date"] = 6] = "Date";
})(QueryBuilderPropertyType || (QueryBuilderPropertyType = {}));
export default QueryBuilderPropertyType;
