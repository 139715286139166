var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Form, Formik } from 'formik';
import React from 'react';
import Button from '../../button/Button';
import FormikTextField from '../../formik/FormikTextField';
export default (function (_a) {
    var props = __rest(_a, []);
    return React.createElement("div", { className: props.className },
        React.createElement(Formik, { initialValues: {
                searchPhrase: ''
            }, onSubmit: function (values) { props.onSearch(values.searchPhrase); } },
            React.createElement(Form, { className: "flex" },
                React.createElement(FormikTextField, { name: "searchPhrase", placeholder: "Quick Search", className: "inline-label w-20" }),
                React.createElement(Button, { className: "primary button-small v-center button-search", type: "submit", leftIcon: "search" }))));
});
