var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useField } from 'formik';
import AddressField from '../forms/AddressField';
export default (function (_a) {
    var props = __rest(_a, []);
    var _b = useField("".concat(props.name, "Address")), addressField = _b[0], addressMeta = _b[1], addressFieldHelper = _b[2];
    var _c = useField("".concat(props.name, "SuiteAptNumber")), suiteAptNumberField = _c[0], suiteAptNumberMeta = _c[1], suiteAptNumberFieldHelper = _c[2];
    var _d = useField("".concat(props.name, "City")), cityField = _d[0], cityMeta = _d[1], cityFieldHelper = _d[2];
    var _e = useField("".concat(props.name, "State")), stateField = _e[0], stateMeta = _e[1], stateFieldHelper = _e[2];
    var _f = useField("".concat(props.name, "Zip")), zipField = _f[0], zipMeta = _f[1], zipFieldHelper = _f[2];
    var addressInvalid = function () {
        return addressMeta.touched && !!addressMeta.error;
    };
    var suiteAptNumberInvalid = function () {
        return suiteAptNumberMeta.touched && !!suiteAptNumberMeta.error;
    };
    var cityInvalid = function () {
        return cityMeta.touched && !!cityMeta.error;
    };
    var stateInvalid = function () {
        return stateMeta.touched && !!stateMeta.error;
    };
    var zipInvalid = function () {
        return zipMeta.touched && !!zipMeta.error;
    };
    var isInvalid = function () {
        return addressInvalid() ||
            suiteAptNumberInvalid() ||
            cityInvalid() ||
            stateInvalid() ||
            zipInvalid();
    };
    return React.createElement(AddressField, __assign({}, props, { errorMessage: isInvalid() ? 'Required' : '', isAddressInvalid: addressInvalid(), isSuiteAptNumberInvalid: suiteAptNumberInvalid(), isCityInvalid: cityInvalid(), isStateInvalid: stateInvalid(), isZipInvalid: zipInvalid(), addressValue: addressField.value, suiteAptNumberValue: suiteAptNumberField.value, cityValue: cityField.value, stateValue: stateField.value, zipValue: zipField.value, onAddressChange: addressFieldHelper.setValue, onAddressBlur: addressField.onBlur, onSuiteAptNumberChange: suiteAptNumberFieldHelper.setValue, onSuiteAptNumberBlur: suiteAptNumberField.onBlur, onCityChange: cityFieldHelper.setValue, onCityBlur: cityField.onBlur, onStateChange: stateFieldHelper.setValue, onStateBlur: stateField.onBlur, onZipChange: zipFieldHelper.setValue, onZipBlur: zipField.onBlur }));
});
