var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import useCache from "../../api/baseApi/useCache";
import { useLocalStorage } from "../useLocalStorage";
export var useSession = function () {
    var sessionKey = 'USER_SESSION';
    var _a = useLocalStorage(sessionKey), session = _a[0], storeSession = _a[1], clearSession = _a[2];
    var clearAllCache = useCache('useFatchCallCache').clearAllCache;
    var setSession = function (loginResponse) {
        var session = __assign({ accessToken: loginResponse.access_token, permissions: JSON.parse(loginResponse.permissions) }, JSON.parse(loginResponse.user));
        storeSession(session);
    };
    var clearAppSession = function () {
        clearSession();
        clearAllCache();
    };
    return {
        session: session,
        setSession: setSession,
        clearSession: clearAppSession
    };
};
