import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Button from '../button/Button';
import Icon from '../icons/Icon';
var toastDisplayTime = 5000; // 5 seconds
export default (function (_a) {
    var toastMessage = _a.toastMessage;
    var _b = useState(false), isDismissing = _b[0], setIsDismissing = _b[1];
    useEffect(function () {
        if (!toastMessage.manuallyDismiss) {
            var timeoutHandle_1 = setTimeout(function () { return setIsDismissing(true); }, toastDisplayTime);
            return function () { return clearTimeout(timeoutHandle_1); };
        }
    }, [toastMessage.manuallyDismiss]);
    useEffect(function () {
        if (isDismissing) {
            //allow for 250ms of fade out
            var timeoutHandle_2 = setTimeout(toastMessage.remove, 250);
            return function () { return clearTimeout(timeoutHandle_2); };
        }
    }, [isDismissing]);
    var beginDismissing = function () {
        setIsDismissing(true);
    };
    var getIconType = function () {
        switch (toastMessage.type) {
            case 'success':
                return 'check-circle';
            case 'danger':
                return 'bug';
            case 'accent':
                return 'exclamation-circle';
            case 'primary':
                return 'star-circle';
            case 'warn':
                return 'warning';
        }
    };
    return (React.createElement("div", { className: classNames("toast-wrapper", toastMessage.type, { 'fade-in': !isDismissing }, { 'fade-out': isDismissing }) },
        React.createElement(Icon, { className: "mr-1 v-center text-large toast-icon", icon: getIconType() }),
        React.createElement("div", { className: "toast-body v-center fill flex" },
            React.createElement("div", { className: "fill" },
                toastMessage.heading &&
                    React.createElement("b", null, toastMessage.heading),
                React.createElement("div", { className: "toast-message text-small" }, toastMessage.message)),
            React.createElement(Button, { className: "mb-auto ml-1 close-button button-small link", leftIcon: "times", onClick: beginDismissing }))));
});
