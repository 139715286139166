var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import classNames from 'classnames';
import React from 'react';
import Button from '../../button/Button';
import ToggleButton from '../../toggleButton/ToggleButton';
import QueryBuilderCondition from '../queryBuilderCondition/QueryBuilderCondition';
import createId from '../../../utils/createId';
import QueryBuilderLogicType from './queryBuilderLogicType';
import Menu from '../../menu/Menu';
import Icon from '../../icons/Icon';
import Heading from '../../heading/Heading';
import IconStyles from '../../icons/iconStyles';
var QueryBuilderConditionSet = function (_a) {
    var props = __rest(_a, []);
    var getNewConditionSet = function () {
        return __assign({
            id: createId([]),
            logicType: QueryBuilderLogicType.And,
            conditions: [],
            conditionSets: []
        });
    };
    var handleConditionSetChange = function (conditionSet) {
        var newConditionSet = __assign({}, conditionSet);
        if (!props.isChild &&
            !newConditionSet.conditions.length &&
            !newConditionSet.conditionSets.length) {
            if (props.onRemoved) {
                props.onRemoved();
            }
        }
        else {
            if (props.onChanged) {
                props.onChanged(newConditionSet);
            }
        }
    };
    var addCondition = function () {
        var newConditionSet;
        if (props.conditionSet) {
            newConditionSet = __assign(__assign({}, props.conditionSet), { conditions: __spreadArray([], props.conditionSet.conditions, true), conditionSets: __spreadArray([], props.conditionSet.conditionSets, true) });
        }
        else {
            newConditionSet = getNewConditionSet();
        }
        newConditionSet.conditions.push({
            id: createId(newConditionSet.conditions),
            values: []
        });
        handleConditionSetChange(newConditionSet);
    };
    var addConditionSet = function () {
        var newConditionSet;
        if (props.conditionSet) {
            newConditionSet = __assign(__assign({}, props.conditionSet), { conditions: __spreadArray([], props.conditionSet.conditions, true), conditionSets: __spreadArray([], props.conditionSet.conditionSets, true) });
        }
        else {
            newConditionSet = getNewConditionSet();
        }
        newConditionSet.conditionSets.push({
            id: createId(newConditionSet.conditionSets),
            logicType: QueryBuilderLogicType.And,
            conditions: [],
            conditionSets: []
        });
        handleConditionSetChange(newConditionSet);
    };
    var conditionRemoved = function (index) {
        if (!props.conditionSet) {
            return;
        }
        var conditions = __spreadArray([], props.conditionSet.conditions, true);
        conditions.splice(index, 1);
        handleConditionSetChange(__assign(__assign({}, props.conditionSet), { conditions: conditions }));
    };
    var conditionSetRemoved = function (index) {
        if (!props.conditionSet) {
            return;
        }
        var conditionSets = __spreadArray([], props.conditionSet.conditionSets, true);
        conditionSets.splice(index, 1);
        handleConditionSetChange(__assign(__assign({}, props.conditionSet), { conditionSets: conditionSets }));
    };
    var conditionChanged = function (index, updatedQueryCondition) {
        if (!props.conditionSet) {
            return;
        }
        var updatedConditions = __spreadArray([], props.conditionSet.conditions, true);
        updatedConditions[index] = updatedQueryCondition;
        handleConditionSetChange(__assign(__assign({}, props.conditionSet), { conditions: updatedConditions }));
    };
    var conditionSetChanged = function (index, updatedConditionSet) {
        if (!props.conditionSet) {
            return;
        }
        var updatedConditionSets = __spreadArray([], props.conditionSet.conditionSets, true);
        updatedConditionSets[index] = updatedConditionSet;
        handleConditionSetChange(__assign(__assign({}, props.conditionSet), { conditionSets: updatedConditionSets }));
    };
    var conditionSetTypeChanged = function (newValue) {
        if (!props.conditionSet) {
            return;
        }
        handleConditionSetChange(__assign(__assign({}, props.conditionSet), { logicType: newValue }));
    };
    return (React.createElement(React.Fragment, null,
        props.conditionSet &&
            React.createElement("div", { className: classNames("query-builder-condition-set", props.className) },
                !!props.isChild &&
                    React.createElement("div", { className: "query-builder-condition-set-pointer" }),
                React.createElement("div", { className: "flex" },
                    React.createElement(ToggleButton, { className: "toggle-button-small primary v-center", options: [
                            { label: 'And', value: QueryBuilderLogicType.And },
                            { label: 'Or', value: QueryBuilderLogicType.Or }
                        ], onChange: conditionSetTypeChanged, value: props.conditionSet.logicType }),
                    !!props.isChild && !!props.onRemoved &&
                        React.createElement(Button, { className: "button-x-small link primary ml-1 v-center", onClick: props.onRemoved, leftIcon: "trash", text: "Remove" })),
                React.createElement("div", { className: "query-builder-condition-container" },
                    props.conditionSet.conditions.map(function (condition, index) {
                        return React.createElement("div", { key: condition.id, className: "query-builder-condition" },
                            React.createElement("div", { className: "query-builder-condition-pointer" }),
                            React.createElement(QueryBuilderCondition, { propertyOptions: props.propertyOptions, queryCondition: condition, onRemoved: function () { return conditionRemoved(index); }, onChange: function (updatedCondition) { conditionChanged(index, updatedCondition); } }));
                    }),
                    props.conditionSet.conditionSets.map(function (conditionSet, index) {
                        return React.createElement(QueryBuilderConditionSet, { key: conditionSet.id, isChild: true, propertyOptions: props.propertyOptions, className: props.className, conditionSet: conditionSet, onRemoved: function () { return conditionSetRemoved(index); }, onChanged: function (updatedConditionSet) { conditionSetChanged(index, updatedConditionSet); } });
                    })),
                React.createElement(Menu, { buttonClassName: "add-condition-button circle button-x-small elevate outline", buttonLeftIcon: "plus", menuPlacement: "right", menuItems: [
                        {
                            key: 'Condition',
                            className: "primary",
                            text: "Add Filter",
                            leftIcon: "filter",
                            onClick: addCondition
                        },
                        {
                            key: 'Condition Set',
                            className: "primary",
                            text: "Add Group",
                            leftIcon: "layer-plus",
                            onClick: addConditionSet
                        }
                    ] })),
        !props.conditionSet &&
            React.createElement("div", { className: "v-center h-center max-w-40" },
                React.createElement("div", { className: "flex" },
                    React.createElement(Icon, { className: "text-jumbo-large v-center", style: IconStyles.Duotone, icon: "flux-capacitor" }),
                    React.createElement("div", { className: "fill ml-4" },
                        React.createElement("div", null,
                            React.createElement(Heading, { level: "5", className: "text-primary mb-1" }, "Create New Query"),
                            "Queries describe how you want to load information to be viewed or calculated. You can have simple or complex rules that filter information according to your query."))),
                React.createElement(Button, { className: "primary mt-1 ml-auto", text: "Create Query", onClick: addCondition }))));
};
export default QueryBuilderConditionSet;
