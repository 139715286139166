var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { ToastContext } from './toastContext';
import ToastsContainer from './ToastsContainer';
export default (function (_a) {
    var _b;
    var children = _a.children;
    var _c = useState([]), toasts = _c[0], setToasts = _c[1];
    var toastIdRef = useRef(0);
    var removeToast = function (idToRemove) { return function () {
        setToasts(function (previousToasts) { return __spreadArray([], previousToasts.filter(function (toast) { return toast.id !== idToRemove; }), true); });
    }; };
    var addToast = useCallback(function (toast) {
        var id = toastIdRef.current++;
        var toastToDisplay = __assign(__assign({}, toast), { id: id, remove: removeToast(id) });
        setToasts(function (previousToasts) { return __spreadArray(__spreadArray([], previousToasts, true), [toastToDisplay], false); });
    }, [toasts, setToasts]);
    var contextValue = useMemo(function () { return ({
        addToast: addToast
    }); }, [addToast]);
    return (React.createElement(ToastContext.Provider, { value: contextValue },
        children,
        createPortal((React.createElement(ToastsContainer, { toasts: toasts })), (_b = document.getElementById('app-container')) !== null && _b !== void 0 ? _b : document.body)));
});
