import React from "react";
import { useSession } from "../../hooks/session/useSession";
import Button from "../../components/button/Button";
import { useNavigate } from "react-router-dom";
export default (function () {
    var clearSession = useSession().clearSession;
    var navigate = useNavigate();
    var logout = function () {
        clearSession();
        navigate('/');
    };
    return (React.createElement("header", { className: "flex p-2" },
        React.createElement(Button, { className: "ml-auto link primary", text: "Logout", onClick: logout })));
});
