var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import classNames from 'classnames';
import TextField from './TextField';
import Checkbox from './Checkbox';
import IconStyles from '../icons/iconStyles';
import StateField from './StateField';
import ZipCodeField from './ZipCodeField';
export default (function (_a) {
    var props = __rest(_a, []);
    var isInvalid = function () {
        return !!props.errorMessage ||
            props.isAddressInvalid ||
            props.isSuiteAptNumberInvalid ||
            props.isCityInvalid ||
            props.isStateInvalid ||
            props.isZipInvalid;
    };
    return (React.createElement("div", { className: classNames("address-field-wrapper", props.className, { 'disabled': !!props.disabled }) },
        React.createElement("div", { className: classNames("address-field-border-wrapper", { 'invalid': isInvalid() }) },
            props.label &&
                React.createElement("label", null, props.label),
            props.onSameAsCheckboxChange &&
                React.createElement("div", { className: "same-as-checkbox" },
                    React.createElement(Checkbox, { iconStyle: IconStyles.Light, label: props.sameAsLabel, onChange: props.onSameAsCheckboxChange, value: props.sameAsValue, disabled: props.disabled })),
            React.createElement(TextField, { name: "".concat(props.name, "Address"), placeholder: "Address", onChange: props.onAddressChange, onBlur: props.onAddressBlur, value: props.addressValue, className: classNames('inline-label', { 'invalid': props.isAddressInvalid }), disabled: props.disabled }),
            React.createElement(TextField, { name: "".concat(props.name, "SuiteAptNumber"), placeholder: "Suite/Apt", onChange: props.onSuiteAptNumberChange, onBlur: props.onSuiteAptNumberBlur, value: props.suiteAptNumberValue, className: classNames('inline-label', { 'invalid': props.isSuiteAptNumberInvalid }), disabled: props.disabled }),
            React.createElement("div", { className: "flex" },
                React.createElement(TextField, { name: "".concat(props.name, "City"), placeholder: "City", onChange: props.onCityChange, onBlur: props.onCityBlur, value: props.cityValue, className: classNames('inline-label', 'city-field', { 'invalid': props.isCityInvalid }), disabled: props.disabled }),
                React.createElement(StateField, { name: "".concat(props.name, "State"), placeholder: "State", onChange: props.onStateChange, onBlur: props.onStateBlur, value: props.stateValue, className: classNames('inline-label', 'state-field', { 'invalid': props.isStateInvalid }), hideCaret: true, disabled: props.disabled }),
                React.createElement(ZipCodeField, { name: "".concat(props.name, "Zip"), placeholder: "Zip", onChange: props.onZipChange, onBlur: props.onZipBlur, value: props.zipValue, className: classNames('inline-label', 'zip-field', { 'invalid': props.isZipInvalid }), disabled: props.disabled }))),
        !!props.errorMessage &&
            React.createElement("div", { className: "error-message" }, props.errorMessage)));
});
