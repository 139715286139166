import React from "react";
import InvoicesPage from '../pages/invoices/InvoicesPage';
import BondsPage from '../pages/bonds/BondsPage';
import BondTransactionsPage from '../pages/bondTransactions/BondTransactionsPage';
import BrokersPage from '../pages/brokers/BrokersPage';
import CustomersPage from "../pages/customers/customerList/CustomersPage";
import DashboardPage from '../pages/dashboard/DashboardPage';
import DocumentLibraryPage from "../pages/documentLibrary/DocumentLibraryPage";
import LeadsPage from '../pages/leads/LeadsPage';
import SettingsPage from '../pages/settings/SettingsPage';
import RouteTypes from './routeType';
import PaymentsPage from "../pages/payments/paymentsPage";
import LoginPage from "../pages/login/LoginPage";
import LogsPage from "../pages/logs/LogsPage";
var Routes = [
    {
        name: 'Dashboard',
        pathName: '/agent',
        icon: 'tachometer-alt-average',
        routeType: RouteTypes.Agent,
        element: React.createElement(DashboardPage, null)
    },
    {
        pathName: '/agent/customers',
        name: 'Customers',
        icon: 'id-card',
        routeType: RouteTypes.Agent,
        element: React.createElement(CustomersPage, null)
    },
    {
        pathName: '/agent/bonds',
        name: 'Bonds',
        icon: 'file-contract',
        routeType: RouteTypes.Agent,
        element: React.createElement(BondsPage, null)
    },
    {
        pathName: '/agent/invoices',
        name: 'Invoices',
        icon: 'file-invoice',
        routeType: RouteTypes.Agent,
        element: React.createElement(InvoicesPage, null)
    },
    {
        pathName: '/agent/payments',
        name: 'Payments',
        icon: 'dollar',
        routeType: RouteTypes.Agent,
        element: React.createElement(PaymentsPage, null)
    },
    {
        pathName: '/agent/bondTransactions',
        name: 'Transactions',
        icon: 'file-times',
        routeType: RouteTypes.Agent,
        element: React.createElement(BondTransactionsPage, null)
    },
    {
        pathName: '/agent/brokers',
        name: 'Brokers',
        icon: 'building',
        routeType: RouteTypes.Agent,
        element: React.createElement(BrokersPage, null)
    },
    {
        pathName: '/agent/documentLibrary',
        name: 'Doc Library',
        icon: 'files',
        routeType: RouteTypes.Agent,
        element: React.createElement(DocumentLibraryPage, null)
    },
    {
        pathName: '/agent/leads',
        name: 'Leads',
        icon: 'id-card-alt',
        routeType: RouteTypes.Agent,
        element: React.createElement(LeadsPage, null)
    },
    {
        pathName: '/agent/logs',
        name: 'System Logs',
        icon: 'file-binary',
        routeType: RouteTypes.Agent,
        element: React.createElement(LogsPage, null)
    },
    {
        pathName: '/agent/settings',
        name: 'Settings',
        icon: 'cog',
        isBottomNav: true,
        routeType: RouteTypes.Agent,
        element: React.createElement(SettingsPage, null)
    },
    {
        pathName: '*',
        name: 'Login',
        routeType: RouteTypes.Common,
        element: React.createElement(LoginPage, null)
    }
];
export default Routes;
