var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
export default (function (_a) {
    var props = __rest(_a, []);
    var _b = useState(props.value), selectedValue = _b[0], setSelectedValue = _b[1];
    useEffect(function () {
        setSelectedValue(props.value);
    }, [props.value]);
    var changed = function (newValue) {
        setSelectedValue(newValue);
        props.onChange(newValue);
    };
    return (React.createElement("div", { className: classNames(props.className, "toggle-button") }, props.options.map(function (option, index) { return (React.createElement("div", { key: index, onClick: function () { changed(option.value); }, className: classNames("toggle-button-option", { 'active': option.value === selectedValue }) }, option.label)); })));
});
