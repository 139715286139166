import * as Yup from 'yup';
import { phoneValidationRegEx, ssnValidationRegEx, zipCodeValidationRegEx } from './regExPatterns';
export var defaultRequiredValidationMessage = 'Required';
export var yupPhone = Yup
    .string()
    .matches(phoneValidationRegEx, 'Invalid Number');
export var yupPhoneRequired = yupPhone.required(defaultRequiredValidationMessage);
export var yupEmail = Yup
    .string()
    .email('Invalid Email');
export var yupEmailRequired = yupEmail.required(defaultRequiredValidationMessage);
export var yupDate = Yup
    .date()
    .typeError('Invalid Date');
export var yupDateRequired = yupDate.required(defaultRequiredValidationMessage);
export var yupZip = Yup
    .string()
    .matches(zipCodeValidationRegEx, 'Invalid Zip Code');
export var yupZipRequired = yupZip.required(defaultRequiredValidationMessage);
export var yupStringRequired = Yup
    .string()
    .required(defaultRequiredValidationMessage);
export var yupSsn = Yup
    .string()
    .matches(ssnValidationRegEx, 'Invalid SSN');
export var yupSsnRequired = yupSsn.required(defaultRequiredValidationMessage);
