import React from "react";
import { useSession } from "../../hooks/session/useSession";
import { Responsive, WidthProvider } from "react-grid-layout";
var ResponsivGridLayout = WidthProvider(Responsive);
export default (function () {
    var session = useSession().session;
    var layouts = {
        lg: [
            { i: "welcomeWidget", x: 0, y: 0, w: 12, h: 3 },
            { i: "info1", x: 0, y: 1, w: 6, h: 2 },
            { i: "info2", x: 6, y: 1, w: 6, h: 2 }
        ]
    };
    return (React.createElement(ResponsivGridLayout, { className: "layout", layouts: layouts, breakpoints: { lg: 0 }, cols: {
            lg: 12
        }, rowHeight: 32 },
        React.createElement("div", { key: "welcomeWidget", className: "p-1" },
            React.createElement("h4", null,
                "Hi ", session === null || session === void 0 ? void 0 :
                session.firstName),
            "Welcome to A3 Next (v4.0)!"),
        React.createElement("div", { key: "info1", className: "p-1" }, "This is the next major version of A3. Not all features have been added to this version just yet."),
        React.createElement("div", { key: "info2", className: "p-1" }, "The rollout plan for this version of A3 is to only build critical fix and features into the legacy version. All new functionality that isn't considered urgent will be added to this new version of A3.")));
});
