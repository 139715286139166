var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import useFetchCall from "../baseApi/useFetchCall";
import useRequiredSession from "../../hooks/session/useRequiredSession";
var getCacheKey = function (userId, dataViewType) {
    return "UserDataView_".concat(userId, "_").concat(dataViewType);
};
var userDataViewApi = {
    useGetDataViews: function (dataViewType) {
        var session = useRequiredSession().session;
        return useFetchCall({
            endPoint: "UserDataViewActions/GetUserDataView?userId=".concat(session.id, "&dataViewType=").concat(dataViewType),
            method: 'GET',
            responseTranslator: function (original) { return original.value; },
            cacheResult: true,
            cacheKey: getCacheKey(session.id, dataViewType)
        });
    },
    useSaveUserDataViews: function (dataViewType) {
        var session = useRequiredSession().session;
        var fetchCall = useFetchCall({
            endPoint: "UserDataViewActions/SaveUserDataViews",
            method: 'POST',
            clearCacheByKey: getCacheKey(session.id, dataViewType)
        });
        var submit = function (dataViews) {
            var persistedDataViews = dataViews.map(function (dataView) {
                var parsedColumns = dataView.columns
                    .filter(function (column) { return !!column.enabled; })
                    .map(function (column) { return ({
                    name: column.name,
                    index: column.index
                }); });
                var conditionSetJson = !!dataView.conditionSet ? JSON.stringify(__assign({}, dataView.conditionSet)) : undefined;
                return {
                    id: dataView.id > 0 ? dataView.id : 0,
                    name: dataView.name,
                    userId: session.id,
                    dataViewType: dataViewType,
                    index: dataView.index,
                    isDefault: dataView.isDefault,
                    recordsPerPage: dataView.recordsPerPage,
                    sortExpression: dataView.sortExpression,
                    conditionSetJson: conditionSetJson,
                    columns: parsedColumns
                };
            });
            fetchCall.fetch({
                userId: session.id,
                dataViewType: dataViewType,
                dataViews: persistedDataViews
            });
        };
        return {
            submit: submit,
            isWaiting: fetchCall.isWaiting,
            hasError: fetchCall.hasError,
            errorMessage: fetchCall.errorMessage
        };
    },
    useDeleteUserDataView: function (dataViewType) {
        var session = useRequiredSession().session;
        var fetchCall = useFetchCall({
            endPoint: "UserDataViewActions/DeleteUserDataView",
            method: 'POST',
            clearCacheByKey: getCacheKey(session.id, dataViewType)
        });
        var submit = function (id) {
            fetchCall.fetch(id);
        };
        return {
            submit: submit,
            isWaiting: fetchCall.isWaiting,
            hasError: fetchCall.hasError,
            errorMessage: fetchCall.errorMessage
        };
    }
};
export var useGetDataViews = userDataViewApi.useGetDataViews, useSaveUserDataViews = userDataViewApi.useSaveUserDataViews, useDeleteUserDataView = userDataViewApi.useDeleteUserDataView;
