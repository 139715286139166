import classNames from 'classnames';
import React from 'react';
import Icon from '../../icons/Icon';
export default (function (_a) {
    var isExpanded = _a.isExpanded, onClick = _a.onClick, disabled = _a.disabled;
    var handleClick = function () {
        if (!disabled) {
            onClick();
        }
    };
    return (React.createElement("td", { onClick: handleClick, className: classNames("expander-column", { 'active': isExpanded }) },
        React.createElement(Icon, { icon: "chevron-right" })));
});
