import React from 'react';
export default (function (elRef, callback) {
    var callbackRef = React.useRef(callback);
    React.useEffect(function () {
        var handleClickOutside = function (e) {
            var refElement = elRef === null || elRef === void 0 ? void 0 : elRef.current;
            if (!(refElement === null || refElement === void 0 ? void 0 : refElement.contains(e.target)) && callbackRef.current) {
                callbackRef.current(e);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return function () {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });
});
