var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import React, { useEffect, useReducer, useRef } from 'react';
import Icon from '../icons/Icon';
var dropdownOptionSearchKeys = [
    'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
    ' ', ',', ';', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'
];
var dropdownActionKeys = ['Tab', 'ArrowUp', 'ArrowDown', 'Backspace', 'Delete'];
var normalizeOptionComparisonString = function (string) {
    return string.trim().toLocaleLowerCase();
};
var doesOptionStartWith = function (option, searchPhrase) {
    var value = option.value;
    var normalizedSearchPhrase = normalizeOptionComparisonString(searchPhrase);
    if (typeof value.toString === 'function') {
        var normalizedStringValue = normalizeOptionComparisonString(value.toString());
        if (normalizedStringValue === normalizedSearchPhrase) {
            return true;
        }
        else if (normalizedStringValue.startsWith(normalizedSearchPhrase)) {
            return true;
        }
    }
    var normalizedOptionLabel = normalizeOptionComparisonString(option.label);
    if (normalizedOptionLabel === normalizedSearchPhrase) {
        return true;
    }
    else if (normalizedOptionLabel.startsWith(normalizedSearchPhrase)) {
        return true;
    }
    return false;
};
var dropdownReducer = function (state, action) {
    var _a;
    switch (action.type) {
        case 'Focused':
            return __assign(__assign({}, state), { isFocused: true, isOpen: true });
        case 'Blurred':
            return __assign(__assign({}, state), { isOpen: false, isFocused: false });
        case 'OptionClicked':
            return __assign(__assign({}, state), { selectedIndex: action.optionIndex, isOpen: false, inputValue: action.inputValue });
        case 'KeyAction':
            switch (action.key) {
                case 'Tab':
                    var tabPressInputValue = '';
                    if (state.selectedIndex || state.selectedIndex === 0) {
                        tabPressInputValue = getFieldInputValue(action.options, (_a = action.options[state.selectedIndex]) === null || _a === void 0 ? void 0 : _a.value, action.fieldTextKey);
                    }
                    return __assign(__assign({}, state), { isOpen: false, isFocused: false, inputValue: tabPressInputValue });
                case 'ArrowDown':
                    var arrowDownNewIndex = void 0;
                    if (state.selectedIndex === undefined) {
                        arrowDownNewIndex = 0;
                    }
                    else {
                        if (state.selectedIndex >= action.options.length - 1) {
                            arrowDownNewIndex = 0;
                        }
                        else {
                            arrowDownNewIndex = state.selectedIndex + 1;
                        }
                    }
                    return __assign(__assign({}, state), { selectedIndex: arrowDownNewIndex, inputValue: getFieldInputValue(action.options, action.options[arrowDownNewIndex].value, action.fieldTextKey) });
                case 'ArrowUp':
                    var arrowUpNewIndex = void 0;
                    if (state.selectedIndex === undefined) {
                        arrowUpNewIndex = action.options.length - 1;
                    }
                    else {
                        if (state.selectedIndex <= 0) {
                            arrowUpNewIndex = action.options.length - 1;
                        }
                        else {
                            arrowUpNewIndex = state.selectedIndex - 1;
                        }
                    }
                    return __assign(__assign({}, state), { selectedIndex: arrowUpNewIndex, inputValue: getFieldInputValue(action.options, action.options[arrowUpNewIndex].value, action.fieldTextKey) });
                case 'Backspace':
                case 'Delete':
                    return __assign(__assign({}, state), { selectedIndex: undefined, inputValue: '' });
                default:
                    return __assign({}, state);
            }
        case 'EscapeKeyPressed':
            return __assign(__assign({}, state), { selectedIndex: action.previousSelectedIndex, inputValue: action.previousInputValue, isOpen: false, isFocused: false });
        case 'KeyOptionSearch':
            var keyOptionSearchSelectedOption = action.options.find(function (option) { return doesOptionStartWith(option, action.searchPhrase); });
            var keyOptionSearchSelectedIndex = void 0;
            var keyOptionSearchInputValue = '';
            if (keyOptionSearchSelectedOption) {
                keyOptionSearchSelectedIndex = getOptionIndex(action.options, keyOptionSearchSelectedOption.value);
                keyOptionSearchInputValue = getFieldInputValue(action.options, keyOptionSearchSelectedOption.value, action.fieldTextKey);
            }
            return __assign(__assign({}, state), { selectedIndex: keyOptionSearchSelectedIndex, inputValue: keyOptionSearchInputValue });
        case 'UpdateFromProps':
            return __assign(__assign({}, state), { selectedIndex: getOptionIndex(action.options, action.value), inputValue: getFieldInputValue(action.options, action.value, action.fieldTextKey) });
        default:
            throw new Error('Invalid Action Type');
    }
};
var getFieldInputValue = function (options, value, fieldTextKey) {
    if (fieldTextKey === void 0) { fieldTextKey = 'label'; }
    if (!value) {
        return '';
    }
    for (var i = 0; i < options.length; i++) {
        if (options[i].value === value) {
            if (fieldTextKey === 'value') {
                return options[i].value;
            }
            else {
                return options[i].label;
            }
        }
    }
    return '';
};
var getOptionIndex = function (options, value) {
    for (var i = 0; i < options.length; i++) {
        if (options[i].value === value) {
            return i;
        }
    }
    return undefined;
};
export default (function (_a) {
    var props = __rest(_a, []);
    var dropdownRef = useRef();
    var dropdownDirectionRef = useRef('DOWN');
    var previousSelectedIndexRef = useRef();
    var initialRenderComplete = useRef(false);
    var previousInputValueRef = useRef(getFieldInputValue(props.options, props.value, props.fieldTextKey));
    var keyOptionSearchRef = useRef('');
    var inputRef = useRef();
    var originalValueSetRef = useRef(false);
    var _b = useReducer(dropdownReducer, {
        isOpen: false,
        isFocused: false,
        inputValue: previousInputValueRef.current,
        selectedIndex: getOptionIndex(props.options, props.value)
    }), state = _b[0], dispatch = _b[1];
    useEffect(function () {
        var handleDocumentClick = function (event) {
            var _a;
            if (!((_a = dropdownRef.current) === null || _a === void 0 ? void 0 : _a.contains(event.target))) {
                if (state.isOpen) {
                    dispatch({ type: 'Blurred' });
                    keyOptionSearchRef.current = '';
                }
            }
        };
        if (state.isOpen) {
            window.addEventListener('click', handleDocumentClick);
        }
        else {
            window.removeEventListener('click', handleDocumentClick);
        }
        return (function () {
            window.removeEventListener('click', handleDocumentClick);
        });
    }, [state.isOpen]);
    useEffect(function () {
        if (state.selectedIndex !== previousSelectedIndexRef.current) {
            previousSelectedIndexRef.current = state.selectedIndex;
            if (state.selectedIndex || state.selectedIndex == 0) {
                previousInputValueRef.current = getFieldInputValue(props.options, props.options[state.selectedIndex].value, props.fieldTextKey);
            }
            else {
                previousInputValueRef.current = '';
            }
            if (props.onChange && initialRenderComplete.current) {
                if (!state.selectedIndex && state.selectedIndex !== 0) {
                    props.onChange();
                }
                else {
                    props.onChange(props.options[state.selectedIndex].value);
                }
            }
        }
    }, [state.selectedIndex]);
    useEffect(function () {
        if (originalValueSetRef.current) {
            dispatch({
                type: 'UpdateFromProps',
                options: props.options,
                value: props.value,
                fieldTextKey: props.fieldTextKey
            });
        }
        else {
            originalValueSetRef.current = true;
        }
    }, [props.value]);
    useEffect(function () {
        initialRenderComplete.current = true;
        setDropDirection();
    }, []);
    var keyDown = function (event) {
        var _a;
        if (props.disabled) {
            return;
        }
        if (dropdownActionKeys.includes(event.key)) {
            keyOptionSearchRef.current = '';
            dispatch({
                type: 'KeyAction',
                key: event.key,
                options: props.options,
                fieldTextKey: props.fieldTextKey
            });
        }
        else if (dropdownOptionSearchKeys.includes(event.key)) {
            keyOptionSearchRef.current += event.key;
            dispatch({
                type: 'KeyOptionSearch',
                searchPhrase: keyOptionSearchRef.current,
                options: props.options,
                fieldTextKey: props.fieldTextKey
            });
        }
        else if (event.key === 'Escape') {
            keyOptionSearchRef.current = '';
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.blur();
            dispatch({
                type: 'EscapeKeyPressed',
                previousInputValue: previousInputValueRef.current,
                previousSelectedIndex: previousSelectedIndexRef.current
            });
        }
    };
    var setDropDirection = function () {
        if (!props.autoPosition || !dropdownRef.current) {
            return;
        }
        var paddingPx = 16;
        var dropdownRect = dropdownRef.current.getBoundingClientRect();
        var dropdownTop = dropdownRect.top;
        var dropdownHeight = dropdownRect.height;
        var windowHeight = window.visualViewport.height;
        var estimatedDropdownContentHeight = props.options.length * 24;
        var shouldDropUp = (dropdownTop + dropdownHeight + estimatedDropdownContentHeight + paddingPx) > windowHeight;
        if (shouldDropUp) {
            dropdownDirectionRef.current = 'UP';
        }
        else {
            dropdownDirectionRef.current = 'DOWN';
        }
    };
    var focused = function () {
        if (props.disabled) {
            return;
        }
        setDropDirection();
        keyOptionSearchRef.current = '';
        dispatch({ type: 'Focused' });
    };
    var optionClicked = function (event, option, optionIndex) {
        keyOptionSearchRef.current = '';
        dispatch({
            type: 'OptionClicked',
            optionIndex: optionIndex,
            inputValue: getFieldInputValue(props.options, option.value, props.fieldTextKey)
        });
        event.stopPropagation();
    };
    return (React.createElement("div", { ref: dropdownRef, className: classNames("dropdown-wrapper", props.className, { 'active': state.isOpen }, { 'invalid': !!props.errorMessage }, { 'no-label': !props.label }, { 'drop-up': dropdownDirectionRef.current === 'UP' }, { 'disabled': !!props.disabled }) },
        props.label &&
            React.createElement("label", null, props.label),
        React.createElement("input", { ref: inputRef, name: props.name, type: "text", autoComplete: "off", value: state.inputValue, placeholder: props.placeholder || props.label || '', onFocus: focused, onKeyDown: keyDown, onChange: function (e) { e.preventDefault(); }, disabled: props.disabled }),
        !props.hideCaret &&
            React.createElement(Icon, { className: "dropdown-caret", icon: dropdownDirectionRef.current === 'UP' ? 'caret-up' : 'caret-down', onClick: focused }),
        !!props.errorMessage &&
            React.createElement("div", { className: "error-message" }, props.errorMessage),
        React.createElement("div", { className: "dropdown-content" }, props.options.map(function (option, index) {
            return React.createElement("div", { key: option.value, className: classNames('option', { 'active': state.selectedIndex === index }), onClick: function (event) { return optionClicked(event, option, index); } }, option.label);
        }))));
});
