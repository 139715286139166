import classNames from 'classnames';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '../../components/button/Button';
import IconStyles from '../../components/icons/iconStyles';
import RouteTypes from '../../routing/routeType';
import { useRouteBuilder } from '../../routing/useRouteBuilder';
export default (function () {
    var navigate = useNavigate();
    var pathname = useLocation().pathname;
    var getRoutes = useRouteBuilder().getRoutes;
    var getLinkButton = function (route) {
        var activeClass = '';
        if (route.pathName === pathname) {
            activeClass = 'active';
        }
        var marginClass = 'mt-1';
        if (route.isBottomNav) {
            marginClass = 'mt-auto';
        }
        return (React.createElement(Button, { key: route.pathName, className: classNames('h-center tile button-small highlight-icon', activeClass, marginClass), leftIcon: route.icon, leftIconStyle: IconStyles.Duotone, text: route.name, onClick: function () { return navigate(route.pathName); } }));
    };
    return (React.createElement("div", { className: "left-nav flex column" }, getRoutes(RouteTypes.Agent).map(function (route) { return getLinkButton(route); })));
});
