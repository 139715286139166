import React from 'react';
export default (function (_a) {
    var address = _a.address, suiteAptNumber = _a.suiteAptNumber, city = _a.city, state = _a.state, zip = _a.zip;
    var getSeparator = function (parts, separator) {
        if (parts === null || parts === void 0 ? void 0 : parts.length) {
            return separator;
        }
        return '';
    };
    var parts = [];
    if (address) {
        parts.push(React.createElement("span", { key: "address" }, address));
    }
    if (suiteAptNumber) {
        parts.push(React.createElement("span", { key: "suiteAptNumber" },
            getSeparator(parts, ', '),
            suiteAptNumber));
    }
    if (city) {
        parts.push(React.createElement("span", { key: "city" },
            getSeparator(parts, ', '),
            city));
    }
    if (state) {
        parts.push(React.createElement("span", { key: "state" },
            getSeparator(parts, ', '),
            state));
    }
    if (zip) {
        parts.push(React.createElement("span", { key: "zip" },
            getSeparator(parts, ' '),
            zip));
    }
    return (React.createElement("span", null, parts.map(function (part) { return part; })));
});
