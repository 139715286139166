var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import MaritalStatus from '../../app/people/maritalStatus';
import Dropdown from './Dropdown';
var options = [
    { label: 'Single', value: MaritalStatus.Single },
    { label: 'Married', value: MaritalStatus.Married },
    { label: 'Divorced', value: MaritalStatus.Divorced },
    { label: 'Separated', value: MaritalStatus.Separated },
    { label: 'Widowed', value: MaritalStatus.Widowed },
    { label: 'Domestic Partner', value: MaritalStatus.DomesticPartner },
    { label: 'Fiancee', value: MaritalStatus.Fiancee },
    { label: 'Civil Union', value: MaritalStatus.CivilUnion },
    { label: 'Unknown', value: MaritalStatus.Unknown },
    { label: 'Other', value: MaritalStatus.Other },
];
export default (function (_a) {
    var _b;
    var props = __rest(_a, []);
    return (React.createElement(Dropdown, { name: props.name, options: options, className: props.className, label: props.label, placeholder: props.placeholder, value: props.value, onChange: props.onChange, onBlur: props.onBlur, errorMessage: props.errorMessage, fieldTextKey: (_b = props.fieldTextKey) !== null && _b !== void 0 ? _b : 'label', hideCaret: props.hideCaret, disabled: props.disabled }));
});
