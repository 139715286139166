export default (function (nodeA, nodeB) {
    if (!(nodeA === null || nodeA === void 0 ? void 0 : nodeA.parentNode) || !(nodeB === null || nodeB === void 0 ? void 0 : nodeB.parentNode)) {
        return;
    }
    var parentA = nodeA.parentNode;
    var siblingA = nodeA.nextSibling === nodeB ? nodeA : nodeA.nextSibling;
    // Move `nodeA` to before the `nodeB`
    nodeB.parentNode.insertBefore(nodeA, nodeB);
    // Move `nodeB` to before the `nodeA`
    parentA.insertBefore(nodeB, siblingA);
});
