import useFetchCall from "../baseApi/useFetchCall";
var customerApi = {
    useGetLeadList: function () {
        return useFetchCall({
            endPoint: 'LeadActions/GetLeadListItems',
            method: 'POST'
        });
    },
};
export var useGetLeadList = customerApi.useGetLeadList;
