import React from 'react';
import DateTime from '../../../components/formatters/DateTime';
import Number from "../../../components/formatters/Number";
import DataViewType from '../../../dataViews/dataViewTypes';
import QueryBuilderPropertyType from '../../../components/queryBuilder/queryBuilderCondition/queryConditionPropertyTypes';
import TableColumnValueTypes from '../../../components/table/tableColumns/tableColumnValueTypes';
import Address from '../../../components/formatters/Address';
export default (function () {
    var columns = [
        {
            name: 'Id',
            heading: 'Id',
            cellClassName: 'fit-content',
            headingClassName: 'fit-content',
            sortExpression: 'Customers.Id',
            valueType: TableColumnValueTypes.Number,
            cellTemplate: function (item) { return React.createElement("span", null, item.id); },
            index: 0,
            enabled: true
        },
        {
            name: 'LookupCode',
            heading: 'Lookup Code',
            cellClassName: 'fit-content',
            headingClassName: 'fit-content',
            sortExpression: 'Customers.EpicLookupCode',
            valueType: TableColumnValueTypes.String,
            cellTemplate: function (item) { return React.createElement("span", null, item.lookupCode); },
            index: 1,
            enabled: true,
        },
        {
            name: 'Name',
            heading: 'Name',
            sortExpression: 'Customers.Name',
            valueType: TableColumnValueTypes.String,
            cellTemplate: function (item) { return React.createElement("span", null,
                item.name,
                !item.isActive &&
                    React.createElement("span", { className: "text-danger" }, " - Inactive")); },
            index: 2,
            enabled: true,
        },
        {
            name: 'Address',
            heading: 'Address',
            valueType: TableColumnValueTypes.String,
            cellTemplate: function (item) { return (React.createElement(Address, { address: item.physicalAddress, suiteAptNumber: item.physicalSuiteAptNumber, city: item.physicalCity, state: item.physicalState, zip: item.physicalZip })); },
            index: 3,
            enabled: true,
        },
        {
            name: 'Balance',
            heading: 'Balance',
            sortExpression: 'Customers.Balance',
            valueType: TableColumnValueTypes.Number,
            cellTemplate: function (item) { return (React.createElement(Number, { value: item.balance, isCurrency: true })); },
            index: 4,
            enabled: true,
        },
        {
            name: 'Unbilled Balance',
            heading: 'Unbilled Balance',
            sortExpression: 'Customers.UnbilledBalance',
            valueType: TableColumnValueTypes.Number,
            cellTemplate: function (item) { return (React.createElement(Number, { value: item.unbilledBalance, isCurrency: true })); },
            index: 5,
            enabled: false,
        },
        {
            name: 'Cash on Account',
            heading: 'Cash on Account',
            sortExpression: 'Customers.CashOnAccountBalance',
            valueType: TableColumnValueTypes.Number,
            cellTemplate: function (item) { return (React.createElement(Number, { value: item.cashOnAccountBalance, isCurrency: true })); },
            index: 6,
            enabled: false,
        },
        {
            name: 'Bond Count',
            heading: 'Bond Count',
            sortExpression: 'ActiveBondCount',
            valueType: TableColumnValueTypes.Number,
            cellTemplate: function (item) { return React.createElement("span", null, item.activeBondCount); },
            index: 7,
            enabled: false,
        },
        {
            name: 'Created Date Time',
            heading: 'Created On',
            sortExpression: 'Customers.CreatedDateTime',
            valueType: TableColumnValueTypes.DateTime,
            cellTemplate: function (item) { return React.createElement(DateTime, { date: item.createdDateTime }); },
            index: 8,
            enabled: true,
        },
        {
            name: 'Broker Name',
            heading: 'Broker',
            sortExpression: 'BrokerName',
            valueType: TableColumnValueTypes.String,
            cellTemplate: function (item) { return React.createElement("span", null, item.brokerName); },
            index: 9,
            enabled: true,
        }
    ];
    var defaultViews = [{
            isDefault: true,
            id: -1,
            name: 'Default View',
            dataViewType: DataViewType.Customers,
            recordsPerPage: 25,
            sortExpression: 'Customers.Name',
            columns: columns,
            index: 0
        }];
    var queryConditionPropertyOptions = [
        { label: 'Balance', value: 'Customers.Balance', type: QueryBuilderPropertyType.Money },
        { label: 'COA Balance', value: 'Customers.CashOnAccountBalance', type: QueryBuilderPropertyType.Money },
        { label: 'Unbilled Balance', value: 'Customers.UnbilledBalance', type: QueryBuilderPropertyType.Money },
        { label: 'Is Active', value: 'Customers.IsActive', type: QueryBuilderPropertyType.Boolean },
        { label: 'Created Date', value: 'Customers.CreatedDateTime', type: QueryBuilderPropertyType.Date },
        { label: 'Broker', value: 'BrokerName', type: QueryBuilderPropertyType.String },
        { label: 'Id', value: 'Customers.Id', type: QueryBuilderPropertyType.Integer },
        { label: 'Customer Name', value: 'Customers.Name', type: QueryBuilderPropertyType.String },
        { label: 'Bond Count', value: 'ActiveBondCount', type: QueryBuilderPropertyType.Integer },
        { label: 'Lookup Code', value: 'Customers.EpicLookupCode', type: QueryBuilderPropertyType.String },
    ];
    return {
        columns: columns,
        defaultViews: defaultViews,
        queryConditionProperyOptions: queryConditionPropertyOptions
    };
});
