var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from "classnames";
import React from "react";
import Icon from "../icons/Icon";
export default (function (_a) {
    var _b, _c, _d, _e;
    var props = __rest(_a, []);
    return (React.createElement("button", { form: props.form, ref: props.buttonRef, type: (_b = props.type) !== null && _b !== void 0 ? _b : 'button', onClick: props.onClick, className: classNames(props.className, { 'disabled': props.disabled }), disabled: (_c = props.disabled) !== null && _c !== void 0 ? _c : false },
        !!props.leftIcon &&
            React.createElement("div", { onMouseDown: (_d = props.onLeftIconMouseDown) !== null && _d !== void 0 ? _d : undefined, className: "icon-wrapper" },
                React.createElement(Icon, { style: props.leftIconStyle, icon: props.leftIcon, className: props.leftIconClassName })),
        !!props.text &&
            React.createElement("span", null, props.text),
        !!props.rightIcon &&
            React.createElement("div", { onMouseDown: (_e = props.onRightIconMouseDown) !== null && _e !== void 0 ? _e : undefined, className: "icon-wrapper" },
                React.createElement(Icon, { style: props.rightIconStyle, icon: props.rightIcon, className: props.rightIconClassName })),
        props.badgeText &&
            React.createElement("span", { className: classNames(props.badgeClassName, "badge") }, props.badgeText)));
});
