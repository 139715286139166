var MaritalStatus;
(function (MaritalStatus) {
    MaritalStatus["Single"] = "Single";
    MaritalStatus["Married"] = "Married";
    MaritalStatus["Separated"] = "Separated";
    MaritalStatus["Divorced"] = "Divorced";
    MaritalStatus["Widowed"] = "Widowed";
    MaritalStatus["DomesticPartner"] = "DomPartn";
    MaritalStatus["Fiancee"] = "Fiancee";
    MaritalStatus["Other"] = "Other";
    MaritalStatus["Unknown"] = "Unknown";
    MaritalStatus["CivilUnion"] = "CivUnion";
})(MaritalStatus || (MaritalStatus = {}));
export default MaritalStatus;
