import { useNavigate } from "react-router-dom";
import Routes from "./routes";
export default (function () { return function (routeName) {
    var navigate = useNavigate();
    var route = Routes.filter(function (r) { return r.name === routeName; })[0];
    if (!route) {
        throw new Error('Invalid route name');
    }
    navigate(route.pathName);
}; });
