var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from "classnames";
import React, { useState } from "react";
import Button from "../../../components/button/Button";
import NewCustomerModal from "./NewCustomerModal";
export default (function (_a) {
    var props = __rest(_a, []);
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var closeButtonClicked = function () {
        setIsOpen(false);
    };
    var openButtonClicked = function () {
        setIsOpen(true);
    };
    var customerCreated = function (newCustomerId) {
        setIsOpen(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { text: "New Customer", className: classNames("primary", props.className), onClick: openButtonClicked }),
        isOpen &&
            React.createElement(NewCustomerModal, { onCloseButtonClicked: closeButtonClicked, onCustomerCreated: customerCreated })));
});
