import React, { memo } from "react";
var render = function (_a) {
    var children = _a.children, className = _a.className, level = _a.level;
    switch (level) {
        case '1':
            return React.createElement("h1", { className: className }, children);
        case '2':
            return React.createElement("h2", { className: className }, children);
        case '3':
            return React.createElement("h3", { className: className }, children);
        case '4':
            return React.createElement("h4", { className: className }, children);
        case '5':
            return React.createElement("h5", { className: className }, children);
        case '6':
            return React.createElement("h6", { className: className }, children);
        default:
            return React.createElement("span", { className: className }, children);
    }
};
export default memo(render);
