var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
export default (function (_a) {
    var props = __rest(_a, []);
    if (!props.date) {
        return null;
    }
    var date = props.date;
    if (!date.getMonth) {
        date = new Date(date);
    }
    var timePartIndexes = {
        hour: 0,
        minute: 1,
        second: 2,
        amPm: 3,
        timezone: 4,
        abbreviatedTimezone: 5
    };
    var dateTimeString = date.toTimeString();
    var timeParts = dateTimeString.substring(0, 8).split(':');
    var hourNumber = parseInt(timeParts[timePartIndexes.hour]);
    if (hourNumber >= 12) {
        timeParts.push('PM');
        timeParts[timePartIndexes.hour] = (hourNumber - 12).toString();
    }
    else {
        timeParts.push('AM');
    }
    if (hourNumber < 10) {
        timeParts[timePartIndexes.hour] = timeParts[timePartIndexes.hour].substring(1, 2);
    }
    var timezoneStartIndex = dateTimeString.indexOf('(') + 1;
    var timezoneEndIndex = dateTimeString.indexOf(')') - 1;
    timeParts.push(dateTimeString.substring(timezoneStartIndex, timezoneEndIndex));
    var timezoneParts = timeParts[timePartIndexes.timezone].split(' ');
    var abbreviatedTimezone = '';
    for (var i = 0; i < timezoneParts.length; i++) {
        abbreviatedTimezone += timezoneParts[i].substring(0, 1).toUpperCase();
    }
    timeParts.push(abbreviatedTimezone);
    return (React.createElement("span", { className: props.className },
        timeParts[timePartIndexes.hour],
        ":",
        timeParts[timePartIndexes.minute],
        ":",
        timeParts[timePartIndexes.second],
        " ",
        timeParts[timePartIndexes.amPm],
        " ",
        timeParts[timePartIndexes.abbreviatedTimezone]));
});
