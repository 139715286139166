var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import useLogList from "./useLogList";
import { useGetLogList } from '../../api/logs/useLogApi';
import DataViewTable from "../../components/table/dataViewTable/DataViewTable";
import DataViewType from "../../dataViews/dataViewTypes";
export default (function () {
    var _a = useLogList(), columns = _a.columns, defaultViews = _a.defaultViews, queryConditionPropertyOptions = _a.queryConditionPropertyOptions, formatLogMessage = _a.formatLogMessage, formatException = _a.formatException;
    var getLogList = useGetLogList();
    var expandedContentBuilder = function (item, index) {
        return (React.createElement("div", null,
            React.createElement("b", null, "Message: "),
            React.createElement("div", null,
                React.createElement("textarea", { style: { width: "100%", height: "10rem", padding: 0 }, disabled: true, defaultValue: formatLogMessage(item.message) })),
            React.createElement("b", null, "Exception: "),
            React.createElement("div", null,
                React.createElement("textarea", { style: { width: "100%", height: "10rem", padding: 0 }, disabled: true, defaultValue: formatException(item.exception) }))));
    };
    return (React.createElement(DataViewTable, __assign({ heading: "System Logs", columns: columns }, getLogList, { defaultDataViews: defaultViews, queryConditionProperyOptions: queryConditionPropertyOptions, dataViewType: DataViewType.Logs, tablePagerClassName: "sticky-footer", expandedContentBuilder: expandedContentBuilder })));
});
