var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import classNames from 'classnames';
export default (function (_a) {
    var props = __rest(_a, []);
    var handleOnChange = function (e) {
        var newValue = e.target.value;
        if (!!props.onChange) {
            if (isNaN(Number(newValue))) {
                props.onChange(newValue);
            }
            else {
                props.onChange(parseFloat(newValue));
            }
        }
    };
    return (React.createElement("div", { className: classNames('radio-field-wrapper', props.className, { 'invalid': !!props.errorMessage }, { 'disabled': !!props.disabled }) },
        !!props.label &&
            React.createElement("label", { className: "radio-field-label", htmlFor: props.name }, props.label),
        props.options.map(function (option) {
            return React.createElement("label", { key: option.value, className: classNames("option-label", props.optionClassName, { 'active': props.value == option.value }) },
                React.createElement("input", { name: props.name, type: "radio", autoComplete: "off", onChange: handleOnChange, value: option.value, checked: option.value == props.value, disabled: props.disabled }),
                React.createElement("span", null, option.label));
        }),
        !!props.errorMessage &&
            React.createElement("div", { className: "error-message" }, props.errorMessage)));
});
