var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import React from 'react';
import { IMaskInput } from 'react-imask';
export default (function (_a) {
    var _b, _c;
    var props = __rest(_a, []);
    var placeholderText = props.placeholder || props.label || '';
    var accepted = function (value, maskRef, e) {
        if (!props.onChange) {
            return;
        }
        if (!value) {
            props.onChange(undefined);
        }
        else {
            props.onChange(maskRef.typedValue);
        }
    };
    return (React.createElement("div", { className: classNames('text-field-wrapper', props.className, { 'invalid': !!props.errorMessage }, { 'disabled': !!props.disabled }) },
        !!props.label &&
            React.createElement("label", { htmlFor: props.name }, props.label),
        React.createElement(IMaskInput, { name: props.name, label: props.label, placeholder: placeholderText, mask: Number, radix: '.', thousandsSeparator: ',', scale: 2, padFractionalZeros: true, autofix: true, onBlur: props.onBlur, onAccept: accepted, defaultValue: (_c = (_b = props.value) === null || _b === void 0 ? void 0 : _b.toString()) !== null && _c !== void 0 ? _c : '', disabled: props.disabled }),
        !!props.errorMessage &&
            React.createElement("div", { className: "error-message" }, props.errorMessage)));
});
