import React from 'react';
import Button from '../../button/Button';
import TableColumnValueTypes from '../tableColumns/tableColumnValueTypes';
export default (function (_a) {
    var column = _a.column, currentOrderBy = _a.currentOrderBy, onSortChanged = _a.onSortChanged;
    if (!column.heading) {
        return React.createElement("span", null);
    }
    if (!column.sortExpression || !onSortChanged) {
        return (React.createElement("span", null, column.heading));
    }
    var icon;
    if (currentOrderBy === column.sortExpression) {
        switch (column.valueType) {
            case TableColumnValueTypes.String:
                icon = 'arrow-up-a-z';
                break;
            case TableColumnValueTypes.Number:
                icon = 'arrow-up-1-9';
                break;
            default:
                icon = 'arrow-up-short-wide';
        }
    }
    else if (currentOrderBy === "".concat(column.sortExpression, " desc")) {
        switch (column.valueType) {
            case TableColumnValueTypes.String:
                icon = 'arrow-down-z-a';
                break;
            case TableColumnValueTypes.Number:
                icon = 'arrow-down-9-1';
                break;
            default:
                icon = 'arrow-down-wide-short';
        }
    }
    return (React.createElement(Button, { className: "table-sort-button", text: column.heading.toString(), rightIcon: icon, onClick: function () { return onSortChanged(column.sortExpression); } }));
});
