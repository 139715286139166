var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from "classnames";
import React from "react";
import Button from "../button/Button";
export default (function (_a) {
    var props = __rest(_a, []);
    return React.createElement("div", { className: classNames('fade-in modal-wrapper backdrop', props.className) },
        React.createElement("div", { className: "modal-backdrop" }),
        React.createElement("div", { className: classNames("modal-window", props.windowClassName) },
            React.createElement("div", { className: classNames("header", props.headerClassName) },
                React.createElement("div", { className: "v-center" }, props.heading),
                React.createElement(Button, { className: "close-button v-center primary link", leftIcon: "times", onClick: props.onCloseButtonClicked, disabled: props.closeButtonDisabled })),
            React.createElement("div", { className: classNames("content", props.contentClassName) }, props.children),
            !!props.footer &&
                React.createElement("div", { className: classNames("footer", props.footerClassName) }, props.footer)));
});
