import QueryConditionPropertyTypes from "./queryConditionPropertyTypes";
import QueryBuilderOperator from "./queryBuilderOperator";
export var allOperatorOptions = {
    equals: { label: 'Equals', value: QueryBuilderOperator.Equals },
    notEqual: { label: 'Not Equal', value: QueryBuilderOperator.NotEqual },
    greaterThan: { label: 'Greater Than', value: QueryBuilderOperator.GreaterThan },
    lessThan: { label: 'Less Than', value: QueryBuilderOperator.LessThan },
    greaterOrEqual: { label: 'Greater or Equal', value: QueryBuilderOperator.GreaterOrEqual },
    lessOrEqual: { label: 'Less or Equal', value: QueryBuilderOperator.LessOrEqual },
    startsWith: { label: 'Starts with', value: QueryBuilderOperator.StartsWith },
    endsWith: { label: 'Ends with', value: QueryBuilderOperator.EndsWith },
    contains: { label: 'Contains', value: QueryBuilderOperator.Contains },
    between: { label: 'Between', value: QueryBuilderOperator.Between },
    in: { label: 'In', value: QueryBuilderOperator.In },
    blank: { label: 'Blank', value: QueryBuilderOperator.Blank },
    notStartsWith: { label: 'Not starts with', value: QueryBuilderOperator.NotStartsWith },
    notEndsWith: { label: 'Not ends with', value: QueryBuilderOperator.NotEndsWith },
    notContains: { label: 'Not contains', value: QueryBuilderOperator.NotContains },
    notBetween: { label: 'Not between', value: QueryBuilderOperator.NotBetween },
    notIn: { label: 'Not in', value: QueryBuilderOperator.NotIn },
    notBlank: { label: 'Not Blank', value: QueryBuilderOperator.NotBlank },
    isTrue: { label: 'Is True', value: QueryBuilderOperator.IsTrue },
    notTrue: { label: 'Not True', value: QueryBuilderOperator.NotTrue },
};
export var getOperatorsByPropertyType = function (propertyType) {
    var operatorOptions = [];
    switch (propertyType) {
        case QueryConditionPropertyTypes.String:
            operatorOptions.push(allOperatorOptions.equals);
            operatorOptions.push(allOperatorOptions.notEqual);
            operatorOptions.push(allOperatorOptions.contains);
            operatorOptions.push(allOperatorOptions.notContains);
            operatorOptions.push(allOperatorOptions.startsWith);
            operatorOptions.push(allOperatorOptions.notStartsWith);
            operatorOptions.push(allOperatorOptions.endsWith);
            operatorOptions.push(allOperatorOptions.notEndsWith);
            operatorOptions.push(allOperatorOptions.blank);
            operatorOptions.push(allOperatorOptions.notBlank);
            break;
        case QueryConditionPropertyTypes.Money:
        case QueryConditionPropertyTypes.Decimal:
        case QueryConditionPropertyTypes.Integer:
            operatorOptions.push(allOperatorOptions.equals);
            operatorOptions.push(allOperatorOptions.notEqual);
            operatorOptions.push(allOperatorOptions.greaterThan);
            operatorOptions.push(allOperatorOptions.greaterOrEqual);
            operatorOptions.push(allOperatorOptions.lessThan);
            operatorOptions.push(allOperatorOptions.lessOrEqual);
            operatorOptions.push(allOperatorOptions.between);
            operatorOptions.push(allOperatorOptions.notBetween);
            operatorOptions.push(allOperatorOptions.blank);
            operatorOptions.push(allOperatorOptions.notBlank);
            break;
        case QueryConditionPropertyTypes.Boolean:
            operatorOptions.push(allOperatorOptions.isTrue);
            operatorOptions.push(allOperatorOptions.notTrue);
            break;
        case QueryConditionPropertyTypes.Date:
            operatorOptions.push(allOperatorOptions.equals);
            operatorOptions.push(allOperatorOptions.notEqual);
            operatorOptions.push(allOperatorOptions.greaterThan);
            operatorOptions.push(allOperatorOptions.greaterOrEqual);
            operatorOptions.push(allOperatorOptions.lessThan);
            operatorOptions.push(allOperatorOptions.lessOrEqual);
            operatorOptions.push(allOperatorOptions.between);
            operatorOptions.push(allOperatorOptions.notBetween);
            operatorOptions.push(allOperatorOptions.blank);
            operatorOptions.push(allOperatorOptions.notBlank);
            break;
    }
    return operatorOptions;
};
