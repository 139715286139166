var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import React from 'react';
export default (function (_a) {
    var props = __rest(_a, []);
    var getValueString = function () {
        var _a, _b;
        var formatOptions = {
            minimumFractionDigits: (_a = props.scale) !== null && _a !== void 0 ? _a : 2,
            maximumFractionDigits: (_b = props.scale) !== null && _b !== void 0 ? _b : 2,
        };
        if (props.isCurrency) {
            formatOptions.style = 'currency';
            formatOptions.currency = 'USD';
        }
        var formatter = new Intl.NumberFormat('en-US', formatOptions);
        return formatter.format(props.value);
    };
    return (React.createElement("span", { className: classNames(props.className, (props.value && props.value < 0) ? props.negativeClassName : '', (props.value && props.value > 0) ? props.positiveClassName : '') }, getValueString()));
});
