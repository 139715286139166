var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import IMask from 'imask';
import { IMaskInput } from 'react-imask';
import classNames from 'classnames';
export default (function (_a) {
    var _b, _c;
    var props = __rest(_a, []);
    var placeholderText = props.placeholder || props.label || '';
    var maskOptions = {
        mask: Date,
        pattern: 'm/d/Y',
        blocks: {
            d: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 31,
                maxLength: 2,
            },
            m: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 12,
                maxLength: 2,
            },
            Y: {
                mask: IMask.MaskedRange,
                from: 1800,
                to: 2100,
                maxLength: 4
            }
        },
        autofix: false,
        lazy: true,
        // define date -> str convertion
        format: function (date) {
            var day = date.getDate();
            var month = date.getMonth() + 1;
            var year = date.getFullYear();
            var dayStr = day.toString();
            var monthStr = month.toString();
            var yearStr = year.toString();
            if (day < 10) {
                dayStr = "0" + day;
            }
            if (month < 10) {
                monthStr = "0" + month;
            }
            return [monthStr, dayStr, yearStr].join('/');
        },
        // define str -> date convertion
        parse: function (str) {
            var yearMonthDay = str.split('/');
            return new Date(parseInt(yearMonthDay[2]), parseInt(yearMonthDay[0]) - 1, parseInt(yearMonthDay[1]));
        },
    };
    var accepted = function (value, maskRef, e) {
        if (!props.onChange) {
            return;
        }
        if (!value) {
            props.onChange(undefined);
        }
        else {
            props.onChange(maskRef.typedValue);
        }
    };
    return (React.createElement("div", { className: classNames('text-field-wrapper', props.className, { 'invalid': !!props.errorMessage }, { 'disabled': !!props.disabled }) },
        !!props.label &&
            React.createElement("label", { htmlFor: props.name }, props.label),
        React.createElement(IMaskInput, __assign({ name: props.name, label: props.label, placeholder: placeholderText }, maskOptions, { onAccept: accepted, onBlur: props.onBlur, defaultValue: (_c = (_b = props.value) === null || _b === void 0 ? void 0 : _b.toString()) !== null && _c !== void 0 ? _c : '', disabled: props.disabled })),
        !!props.errorMessage &&
            React.createElement("div", { className: "error-message" }, props.errorMessage)));
});
