var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Button from '../../button/Button';
import { getTotalPageCount, buildPageNumberButtonOptions, buildRecordsShowingLabel } from './tablePagerCalculator';
import Skeleton from 'react-loading-skeleton';
import Dropdown from '../../forms/Dropdown';
export default (function (_a) {
    var props = __rest(_a, []);
    var maxPageButtonOptions = 8;
    var totalPageCount = getTotalPageCount(props.totalItemCount, props.recordsPerPage);
    var _b = useState(props.pageNumber), currentPageNumber = _b[0], setCurrentPageNumber = _b[1];
    useEffect(function () {
        setCurrentPageNumber(props.pageNumber);
    }, [props.pageNumber]);
    var goToPage = function (newPageNumber) { return function () {
        if (newPageNumber < 1 ||
            newPageNumber > totalPageCount ||
            newPageNumber === currentPageNumber) {
            return;
        }
        props.onPageChange(newPageNumber);
        setCurrentPageNumber(newPageNumber);
    }; };
    var onRecordsPerPageChanged = function (value) {
        if (!value) {
            return;
        }
        props.onRecordsPerPageChanged(value);
    };
    return (React.createElement("div", { className: classNames("mt-1 flex pagination-row", props.className) },
        props.isLoading &&
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: "pl-1 w-10" },
                    React.createElement(Skeleton, null)),
                React.createElement("div", { className: "ml-auto fill max-w-30 pr-1" },
                    React.createElement(Skeleton, null))),
        !props.isLoading &&
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: "gray1-bg round pl-1 text-small flex" },
                    React.createElement("div", { className: "v-center" },
                        React.createElement("span", { className: "text-faint" }, "Showing "),
                        buildRecordsShowingLabel(currentPageNumber, props.recordsPerPage, props.totalItemCount),
                        React.createElement("span", { className: "text-faint" },
                            " of ",
                            props.totalItemCount)),
                    React.createElement(Button, { className: "square button-small accent link v-center", leftIcon: "sync-alt", onClick: props.onRefreshClicked })),
                React.createElement(Dropdown, { name: "recordsPerPage", className: "ml-1 field-x-small w-7 text-faint", value: props.recordsPerPage, options: [
                        { label: '10 per page', value: 10 },
                        { label: '25 per page', value: 25 },
                        { label: '50 per page', value: 50 }
                    ], onChange: onRecordsPerPageChanged, autoPosition: true }),
                React.createElement("div", { className: "ml-auto flex" },
                    React.createElement(Button, { className: "square outline button-small navigate-button", leftIcon: "step-backward", onClick: goToPage(1) }),
                    React.createElement(Button, { className: "square outline button-small ml-1 navigate-button", leftIcon: "chevron-left", onClick: goToPage(currentPageNumber - 1) }),
                    buildPageNumberButtonOptions(currentPageNumber, totalPageCount, maxPageButtonOptions).map(function (pageNumber) {
                        return React.createElement(Button, { key: pageNumber, className: classNames("square button-small ml-2 go-to-page-button", { 'active': pageNumber === currentPageNumber }), text: pageNumber.toString(), onClick: goToPage(pageNumber) });
                    }),
                    React.createElement(Button, { className: "square outline button-small ml-2 navigate-button", leftIcon: "chevron-right", onClick: goToPage(currentPageNumber + 1) }),
                    React.createElement(Button, { className: "square outline button-small ml-1 navigate-button", leftIcon: "step-forward", onClick: goToPage(totalPageCount) })))));
});
