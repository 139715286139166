import React from 'react';
import TableBodyRow from './TableBodyRow';
export default (function (_a) {
    var columns = _a.columns, rows = _a.rows, showCheckColumn = _a.showCheckColumn, onRowCheckboxClicked = _a.onRowCheckboxClicked, showExpandedContentColumn = _a.showExpandedContentColumn, onRowExpanderCellClicked = _a.onRowExpanderCellClicked, expandedContentBuilder = _a.expandedContentBuilder;
    var expanderCellClicked = function (index) { return function () {
        if (onRowExpanderCellClicked) {
            onRowExpanderCellClicked(index);
        }
    }; };
    return (React.createElement(React.Fragment, null, rows.map(function (row, rowIndex) {
        return React.createElement(TableBodyRow, { key: row.id, columns: columns, isExpanded: row.isExpanded, isChecked: row.isChecked, item: row.item, showCheckColumn: showCheckColumn, onRowCheckboxClicked: onRowCheckboxClicked, showExpandedContentColumn: showExpandedContentColumn, expandedContentBuilder: expandedContentBuilder, onRowExpanderCellClicked: expanderCellClicked(rowIndex), rowIndex: rowIndex });
    })));
});
