var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import Button from '../button/Button';
import useClickOutside from '../../hooks/useClickOutside';
export default React.forwardRef(function (_a, ref) {
    var props = __rest(_a, []);
    var _b = useState(false), popperIsOpen = _b[0], setPopperIsOpen = _b[1];
    var addButtonRef = useRef(null);
    var popperRef = useRef(null);
    useClickOutside(popperRef, function () { setPopperIsOpen(false); });
    var popperOptions = {
        placement: 'auto'
    };
    if (props.menuPlacement === 'right') {
        popperOptions.placement = 'right-start';
        popperOptions.modifiers = [
            {
                name: 'offset',
                options: {
                    offset: [0, 8]
                }
            }
        ];
    }
    if (props.menuPlacement === 'left') {
        popperOptions.placement = 'left-start';
        popperOptions.modifiers = [
            {
                name: 'offset',
                options: {
                    offset: [0, 8]
                }
            }
        ];
    }
    if (props.menuPlacement === 'bottom') {
        popperOptions.placement = 'bottom-start';
        popperOptions.modifiers = [
            {
                name: 'offset',
                options: {
                    offset: [0, 2]
                }
            }
        ];
    }
    if (props.menuPlacement === 'top') {
        popperOptions.placement = 'top-start';
        popperOptions.modifiers = [
            {
                name: 'offset',
                options: {
                    offset: [0, 2]
                }
            }
        ];
    }
    var popper = usePopper(addButtonRef.current, popperRef.current, popperOptions);
    var menuItemClicked = function (menuItemButtonProps) {
        if (menuItemButtonProps.onClick) {
            menuItemButtonProps.onClick();
        }
        if (!props.keepOpenOnItemClick) {
            setPopperIsOpen(false);
        }
        if (popper.update) {
            popper.update();
        }
    };
    return (React.createElement("span", { className: props.className },
        React.createElement(Button, { buttonRef: addButtonRef, text: props.buttonText, className: props.buttonClassName, badgeText: props.buttonBadgeText, badgeClassName: props.buttonBadgeClassName, leftIconStyle: props.buttonLeftIconStyle, leftIcon: props.buttonLeftIcon, leftIconClassName: props.buttonLeftIconClassName, rightIconStyle: props.buttonRightIconStyle, rightIcon: props.buttonRightIcon, rightIconClassName: props.buttonRightIconClassName, disabled: props.disabled, onClick: function () { setPopperIsOpen(!popperIsOpen); } }),
        React.createElement("div", __assign({ ref: popperRef, style: __assign({}, popper.styles.popper) }, popper.attributes.popper, { className: classNames('menu', { 'active': popperIsOpen }) }),
            React.createElement("div", { ref: ref }, props.menuItems.map(function (menuItem, index) { return (React.createElement("div", { key: menuItem === 'divider' ? 'divider_' + index : menuItem.key, className: classNames("menu-item", { "menu-item-divider": menuItem === 'divider' }) },
                menuItem === 'divider' &&
                    React.createElement("div", { className: "divider" }),
                typeof menuItem !== 'string' &&
                    React.createElement(Button, __assign({}, menuItem, { className: classNames(menuItem.className, 'link menu-button button-small no-wrap'), onClick: function () { return menuItemClicked(menuItem); } })))); })))));
});
