var QueryBuilderOperator;
(function (QueryBuilderOperator) {
    QueryBuilderOperator[QueryBuilderOperator["Equals"] = 1] = "Equals";
    QueryBuilderOperator[QueryBuilderOperator["NotEqual"] = 2] = "NotEqual";
    QueryBuilderOperator[QueryBuilderOperator["GreaterThan"] = 3] = "GreaterThan";
    QueryBuilderOperator[QueryBuilderOperator["LessThan"] = 4] = "LessThan";
    QueryBuilderOperator[QueryBuilderOperator["GreaterOrEqual"] = 5] = "GreaterOrEqual";
    QueryBuilderOperator[QueryBuilderOperator["LessOrEqual"] = 6] = "LessOrEqual";
    QueryBuilderOperator[QueryBuilderOperator["StartsWith"] = 7] = "StartsWith";
    QueryBuilderOperator[QueryBuilderOperator["EndsWith"] = 8] = "EndsWith";
    QueryBuilderOperator[QueryBuilderOperator["Contains"] = 9] = "Contains";
    QueryBuilderOperator[QueryBuilderOperator["Between"] = 10] = "Between";
    QueryBuilderOperator[QueryBuilderOperator["In"] = 11] = "In";
    QueryBuilderOperator[QueryBuilderOperator["Blank"] = 12] = "Blank";
    QueryBuilderOperator[QueryBuilderOperator["NotStartsWith"] = 13] = "NotStartsWith";
    QueryBuilderOperator[QueryBuilderOperator["NotEndsWith"] = 14] = "NotEndsWith";
    QueryBuilderOperator[QueryBuilderOperator["NotContains"] = 15] = "NotContains";
    QueryBuilderOperator[QueryBuilderOperator["NotBetween"] = 16] = "NotBetween";
    QueryBuilderOperator[QueryBuilderOperator["NotIn"] = 17] = "NotIn";
    QueryBuilderOperator[QueryBuilderOperator["NotBlank"] = 18] = "NotBlank";
    QueryBuilderOperator[QueryBuilderOperator["IsTrue"] = 19] = "IsTrue";
    QueryBuilderOperator[QueryBuilderOperator["NotTrue"] = 20] = "NotTrue";
})(QueryBuilderOperator || (QueryBuilderOperator = {}));
export default QueryBuilderOperator;
