import { Form, Formik } from "formik";
import React, { useEffect, useRef } from "react";
import Modal from "../components/modal/Modal";
import * as Yup from "yup";
import FormikTextField from "../components/formik/FormikTextField";
import Button from "../components/button/Button";
import IconStyles from "../components/icons/iconStyles";
export default (function (_a) {
    var onCancel = _a.onCancel, onSave = _a.onSave, name = _a.name;
    var inputRef = useRef();
    useEffect(function () {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);
    return (React.createElement(Modal, { heading: "Rename View", className: "blackout no-header", windowClassName: "w-25-p", onCloseButtonClicked: onCancel },
        React.createElement("div", { className: "flex" },
            React.createElement(Formik, { initialValues: { name: name }, validationSchema: Yup.object({
                    name: Yup
                        .string()
                        .required('Required')
                }), onSubmit: function (formModel) { return onSave(formModel.name); } },
                React.createElement(Form, { className: "flex" },
                    React.createElement(FormikTextField, { ref: inputRef, name: "name", placeholder: "View Name", className: "inline-label on-blackout mr-1" }),
                    React.createElement(Button, { text: "Cancel", className: "button-small mr-1", onClick: onCancel }),
                    React.createElement(Button, { type: "submit", leftIcon: "save", leftIconStyle: IconStyles.Duotone, text: "Save", className: "primary button-small" }))))));
});
