var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useReducer } from "react";
import { useSession } from "../../hooks/session/useSession";
import useNavigator from "../../routing/useNavigator";
import HttpStatusCodes from "./httpStatusCodes";
import statusCodeSummarizer from "./statusCodeSummarizer";
import useCache from "./useCache";
var useFetchReducer = function (state, action) {
    switch (action.type) {
        case 'Error':
            return __assign(__assign({}, state), { hasError: true, isFetching: false, errorMessage: action.message });
        case 'Loading':
            return __assign(__assign({}, state), { isFetching: true, hasError: false, errorMessage: '', response: undefined });
        case 'Loaded':
            return __assign(__assign({}, state), { isFetching: false, hasError: false, errorMessage: '', response: action.response, callCount: state.callCount + 1 });
        case 'Refetch':
            return __assign(__assign({}, state), { body: action.body });
        default:
            throw new Error('Action not is not valid');
    }
};
export default (function (request) {
    var _a;
    var baseUrl = (_a = request.baseUrl) !== null && _a !== void 0 ? _a : 'http://localhost:9000/api/';
    var fetchUrl = "".concat(baseUrl).concat(request.endPoint);
    var _b = useSession(), session = _b.session, clearSession = _b.clearSession;
    var navigate = useNavigator();
    var _c = useReducer(useFetchReducer, {
        isFetching: !!request.body,
        body: request.body,
        hasError: false,
        errorMessage: "",
        callCount: 0
    }), state = _c[0], dispatch = _c[1];
    var _d = useCache('useFatchCallCache'), getCachedResponse = _d.getCachedResponse, setCachedResponse = _d.setCachedResponse, clearCache = _d.clearCache;
    var processResponse = function (response) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, response.json()];
                case 1:
                    data = _a.sent();
                    if (request.responseTranslator) {
                        return [2 /*return*/, request.responseTranslator(data)];
                    }
                    else {
                        return [2 /*return*/, data];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var getPreparedHeaders = function () {
        if (!(request === null || request === void 0 ? void 0 : request.headers)) {
            request.headers = new Headers();
        }
        if (!request.headers.has('Content-Type')) {
            request.headers.append('Content-Type', 'application/json');
        }
        if (session && !request.headers.has('Authorization')) {
            request.headers.append('Authorization', "Bearer ".concat(session.accessToken));
        }
        return request.headers;
    };
    var getPreparedBody = function (requestBody) {
        if (!requestBody) {
            return undefined;
        }
        if (request.method !== 'GET') {
            if (typeof requestBody === 'string') {
                return requestBody;
            }
            else {
                return JSON.stringify(requestBody);
            }
        }
    };
    var processFetch = function (requestBody) { return __awaiter(void 0, void 0, void 0, function () {
        var promise, responseData, err_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (Object.keys(request).length === 0 && request.constructor === Object) {
                        return [2 /*return*/];
                    }
                    promise = new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
                        var fetchData, cachedResponse, _a, response, _b, _c, err_2, _d, _e, _f, _g, err_3;
                        var _h;
                        var _j, _k;
                        return __generator(this, function (_l) {
                            switch (_l.label) {
                                case 0:
                                    fetchData = {
                                        headers: getPreparedHeaders(),
                                        method: request.method,
                                        body: getPreparedBody(requestBody)
                                    };
                                    if (!request.cacheResult) return [3 /*break*/, 3];
                                    return [4 /*yield*/, getCachedResponse((_j = request.cacheKey) !== null && _j !== void 0 ? _j : request.endPoint)];
                                case 1:
                                    cachedResponse = _l.sent();
                                    if (!cachedResponse) return [3 /*break*/, 3];
                                    _a = resolve;
                                    return [4 /*yield*/, processResponse(cachedResponse)];
                                case 2:
                                    _a.apply(void 0, [_l.sent()]);
                                    return [2 /*return*/];
                                case 3:
                                    if (!request.clearCacheByKey) return [3 /*break*/, 5];
                                    return [4 /*yield*/, clearCache(request.clearCacheByKey)];
                                case 4:
                                    _l.sent();
                                    _l.label = 5;
                                case 5:
                                    _l.trys.push([5, 17, , 18]);
                                    return [4 /*yield*/, fetch(fetchUrl, fetchData)];
                                case 6:
                                    response = _l.sent();
                                    _b = statusCodeSummarizer(response.status);
                                    switch (_b) {
                                        case HttpStatusCodes.SUCCESS_OK: return [3 /*break*/, 7];
                                        case HttpStatusCodes.ACCESS_DENIED: return [3 /*break*/, 13];
                                    }
                                    return [3 /*break*/, 14];
                                case 7:
                                    if (!request.cacheResult) return [3 /*break*/, 9];
                                    return [4 /*yield*/, setCachedResponse((_k = request.cacheKey) !== null && _k !== void 0 ? _k : request.endPoint, response.clone())];
                                case 8:
                                    _l.sent();
                                    _l.label = 9;
                                case 9:
                                    _l.trys.push([9, 11, , 12]);
                                    _c = resolve;
                                    return [4 /*yield*/, processResponse(response)];
                                case 10:
                                    _c.apply(void 0, [_l.sent()]);
                                    return [3 /*break*/, 12];
                                case 11:
                                    err_2 = _l.sent();
                                    resolve(null);
                                    return [3 /*break*/, 12];
                                case 12: return [3 /*break*/, 16];
                                case 13:
                                    reject(new Error('Unauthorized'));
                                    clearSession();
                                    navigate('Login');
                                    return [3 /*break*/, 16];
                                case 14:
                                    _d = reject;
                                    _e = Error.bind;
                                    _g = (_f = JSON).stringify;
                                    _h = {
                                        status: response.status,
                                        statusText: response.statusText,
                                        type: response.type,
                                        url: response.url
                                    };
                                    return [4 /*yield*/, response.text()];
                                case 15:
                                    _d.apply(void 0, [new (_e.apply(Error, [void 0, _g.apply(_f, [(_h.body = _l.sent(),
                                                    _h)])]))()]);
                                    _l.label = 16;
                                case 16: return [3 /*break*/, 18];
                                case 17:
                                    err_3 = _l.sent();
                                    reject(err_3);
                                    return [3 /*break*/, 18];
                                case 18: return [2 /*return*/];
                            }
                        });
                    }); });
                    dispatch({ type: 'Loading' });
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, promise];
                case 2:
                    responseData = _c.sent();
                    dispatch({ type: 'Loaded', response: responseData });
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _c.sent();
                    dispatch({ type: 'Error', message: (_b = (_a = err_1 === null || err_1 === void 0 ? void 0 : err_1.message) !== null && _a !== void 0 ? _a : err_1) !== null && _b !== void 0 ? _b : 'Error details were not specified' });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return {
        data: state.response,
        isWaiting: state.isFetching,
        hasError: state.hasError,
        errorMessage: state.errorMessage,
        fetch: processFetch,
        callCount: state.callCount
    };
});
