var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { useGetLeadList } from "../../api/leads/useLeadsApi";
import DataViewTable from "../../components/table/dataViewTable/DataViewTable";
import DataViewType from "../../dataViews/dataViewTypes";
import useLeadList from "./useLeadList";
export default (function () {
    var _a = useLeadList(), columns = _a.columns, defaultViews = _a.defaultViews, queryConditionPropertyOptions = _a.queryConditionPropertyOptions;
    var getLeadList = useGetLeadList();
    return (React.createElement(DataViewTable, __assign({ heading: "Leads", columns: columns }, getLeadList, { defaultDataViews: defaultViews, queryConditionProperyOptions: queryConditionPropertyOptions, dataViewType: DataViewType.Leads, tablePagerClassName: "sticky-footer" })));
});
