import useFetchCall from "../baseApi/useFetchCall";
var loginApi = {
    useLogin: function () {
        return useFetchCall({
            baseUrl: 'http://localhost:9000/',
            endPoint: 'login',
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json'
            })
        });
    }
};
export var useLogin = loginApi.useLogin;
