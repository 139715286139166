var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
export default (function (_a) {
    var props = __rest(_a, []);
    if (!props.date) {
        return null;
    }
    var date = props.date;
    if (!date.getMonth) {
        date = new Date(date);
    }
    var month = date.getMonth() + 1;
    var dateOfMonth = date.getDate();
    var year = date.getFullYear();
    return (React.createElement("span", { className: props.className },
        month,
        "/",
        dateOfMonth,
        "/",
        year));
});
