import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSession } from '../../hooks/session/useSession';
import AppCenterContent from '../appCenterContent/AppCenterContent';
import AppFooter from '../appFooter/AppFooter';
import AppHeader from '../appHeader/AppHeader';
import LeftNav from '../leftNav/LeftNav';
export default (function () {
    var session = useSession().session;
    var navigate = useNavigate();
    useEffect(function () {
        if (!session) {
            navigate('/');
        }
    }, []);
    if (!session) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(LeftNav, null),
        React.createElement("main", null,
            React.createElement(AppHeader, null),
            React.createElement(AppCenterContent, null),
            React.createElement(AppFooter, null))));
});
