import classNames from 'classnames';
import React from 'react';
import Checkbox from '../../forms/Checkbox';
import TableBodyExpanderCell from './TableBodyExpanderCell';
export default (function (_a) {
    var columns = _a.columns, item = _a.item, isExpanded = _a.isExpanded, isChecked = _a.isChecked, showCheckColumn = _a.showCheckColumn, onRowCheckboxClicked = _a.onRowCheckboxClicked, showExpandedContentColumn = _a.showExpandedContentColumn, onRowExpanderCellClicked = _a.onRowExpanderCellClicked, expandedContentBuilder = _a.expandedContentBuilder, rowIndex = _a.rowIndex;
    return (React.createElement("tbody", null,
        React.createElement("tr", null,
            showExpandedContentColumn &&
                React.createElement(TableBodyExpanderCell, { isExpanded: isExpanded, onClick: onRowExpanderCellClicked }),
            showCheckColumn &&
                React.createElement("td", { className: "selecter-column" },
                    React.createElement(Checkbox, { value: isChecked, onChange: onRowCheckboxClicked ? function () { return onRowCheckboxClicked(rowIndex); } : undefined })),
            columns
                .filter(function (column) { return column.enabled; })
                .map(function (column) { return (React.createElement("td", { className: column.cellClassName, key: column.name }, column.cellTemplate(item, rowIndex))); })),
        showExpandedContentColumn && expandedContentBuilder &&
            React.createElement("tr", { className: classNames("expanded-content-row", { 'active': isExpanded }) },
                React.createElement("td", { colSpan: 100 }, isExpanded &&
                    expandedContentBuilder(item, rowIndex)))));
});
