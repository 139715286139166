var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useRef, useState } from "react";
import TablePager from "./tablePager/TablePager";
import EmptyState from "../emptyState/EmptyState";
import TableHead from "./tableHead/TableHead";
import TableBodySkeleton from "./tableSkeletons/TableBodySkeleton";
import ColumnDraggableTableClone from "./columnOrganizer/ColumnDraggableTableClone";
import { useColumnOrganizer } from "./columnOrganizer/useColumnOranizer";
import TableHeadSkeleton from "./tableSkeletons/TableHeadSkeleton";
import TableBody from "./tableBody/TableBody";
export default (function (_a) {
    var _b;
    var props = __rest(_a, []);
    var _c = useColumnOrganizer(props.columns, props.columnReorderEnabled, props.onColumnReorder), isColumnDragging = _c.isColumnDragging, tableCloneRef = _c.tableCloneRef, tableRef = _c.tableRef, orderedVisibleColumns = _c.orderedVisibleColumns, draggingColumnRef = _c.draggingColumnRef, draggingElementRef = _c.draggingElementRef, columnHeaderMouseDownHandler = _c.columnHeaderMouseDownHandler, setOrderedVisibleColumns = _c.setOrderedVisibleColumns, getColumnWidth = _c.getColumnWidth, getRowHeight = _c.getRowHeight;
    var _d = useState((_b = props.tableQueryOptions.orderBy) !== null && _b !== void 0 ? _b : ''), currentOrderBy = _d[0], setSortExpression = _d[1];
    useEffect(function () {
        var _a, _b;
        if (props.tableQueryOptions.orderBy !== currentOrderBy) {
            setSortExpression((_b = (_a = props.tableQueryOptions) === null || _a === void 0 ? void 0 : _a.orderBy) !== null && _b !== void 0 ? _b : '');
        }
    }, [props.tableQueryOptions]);
    useEffect(function () {
        setOrderedVisibleColumns(props.columns
            .filter(function (column) { return column.enabled === true; })
            .sort(function (a, b) { return a.index - b.index; }));
    }, [props.columns]);
    var tableCloneRefs = useRef({
        tableCloneRef: tableCloneRef,
        draggingColumnRef: draggingColumnRef,
        draggingElementRef: draggingElementRef
    });
    var handleRefreshClick = function () {
        props.onRefreshClicked(__assign({}, props.tableQueryOptions));
    };
    var handleSelectAllChecked = function (allSelected) {
        for (var i = 0; i < props.rows.length; i++) {
            props.rows[i].isChecked = allSelected;
        }
    };
    var collapseAll = function () {
        for (var i = 0; i < props.rows.length; i++) {
            props.rows[i].isExpanded = false;
        }
    };
    var pageChanged = function (currentPage) {
        handleSelectAllChecked(false);
        collapseAll();
        props.onTableChange({
            recordsPerPage: props.tableQueryOptions.recordsPerPage,
            pageNumber: currentPage,
            orderBy: currentOrderBy,
        });
    };
    var recordsPerPageChanged = function (newRecordsPerPage) {
        handleSelectAllChecked(false);
        collapseAll();
        props.onTableChange({
            recordsPerPage: newRecordsPerPage,
            pageNumber: props.tableQueryOptions.pageNumber,
            orderBy: currentOrderBy
        });
    };
    var handleSortButtonClick = function (newSortExpression) {
        if (!newSortExpression) {
            setSortExpression('');
        }
        else if (currentOrderBy === newSortExpression) {
            setSortExpression("".concat(newSortExpression, " desc"));
            newSortExpression += ' desc';
        }
        else {
            setSortExpression(newSortExpression);
        }
        props.onTableChange({
            recordsPerPage: props.tableQueryOptions.recordsPerPage,
            pageNumber: 1,
            orderBy: newSortExpression,
        });
    };
    return (React.createElement("div", { className: props.className },
        (!!props.totalItemCount || props.isLoading) &&
            React.createElement("div", null,
                !props.isLoading && isColumnDragging &&
                    React.createElement(ColumnDraggableTableClone, { columns: orderedVisibleColumns, rows: props.rows, currentOrderBy: currentOrderBy, expandedContentBuilder: props.expandedContentBuilder, showCheckColumn: !!props.onRowCheckboxClicked, ref: tableCloneRefs, rowHeightCalculator: getRowHeight, columnWidthCalculator: getColumnWidth }),
                !isColumnDragging &&
                    React.createElement("table", { ref: tableRef },
                        !props.isLoading &&
                            React.createElement(TableHead, { columns: orderedVisibleColumns, allRowsChecked: !props.rows.some(function (row) { return row.isChecked !== true; }), showExpandedContentColumn: !!props.expandedContentBuilder, onSelectAllChecked: handleSelectAllChecked, onDragHandleMouseDown: columnHeaderMouseDownHandler, onSortChanged: handleSortButtonClick, currentOrderBy: currentOrderBy, showCheckAllColumn: !!props.onRowCheckboxClicked, columnReorderEnabled: props.columnReorderEnabled }),
                        !props.isLoading &&
                            React.createElement(React.Fragment, null,
                                React.createElement(TableBody, { columns: props.columns, rows: props.rows, showCheckColumn: !!props.onRowCheckboxClicked, onRowCheckboxClicked: props.onRowCheckboxClicked, showExpandedContentColumn: !!props.expandedContentBuilder, expandedContentBuilder: props.expandedContentBuilder, onRowExpanderCellClicked: props.onRowExpanderCellClicked })),
                        !!props.isLoading &&
                            React.createElement(TableHeadSkeleton, null),
                        !!props.isLoading &&
                            React.createElement(TableBodySkeleton, { recordsPerPage: props.tableQueryOptions.recordsPerPage })),
                React.createElement(TablePager, { onPageChange: pageChanged, totalItemCount: props.totalItemCount, onRefreshClicked: handleRefreshClick, recordsPerPage: props.tableQueryOptions.recordsPerPage, pageNumber: props.tableQueryOptions.pageNumber, onRecordsPerPageChanged: recordsPerPageChanged, isLoading: props.isLoading, className: props.tablePagerClassName })),
        !props.isLoading && !props.totalItemCount &&
            React.createElement(EmptyState, null)));
});
