export var getTotalPageCount = function (totalItemCount, recordsPerPage) {
    var count = totalItemCount / recordsPerPage;
    return Math.ceil(count);
};
export var buildPageNumberButtonOptions = function (currentPage, totalPageCount, maxPageButtonOptions) {
    var startingPage = Math.min(currentPage, Math.max(Math.abs(currentPage - 3), 1), Math.max(totalPageCount - maxPageButtonOptions + 1, 1));
    var lastPage = Math.min(totalPageCount, startingPage + maxPageButtonOptions - 1);
    var pageNumbers = [];
    for (var i = startingPage; i <= lastPage; i++) {
        pageNumbers.push(i);
    }
    return pageNumbers;
};
export var buildRecordsShowingLabel = function (currentPage, recordsPerPage, totalItemCount) {
    var startingRecord = ((currentPage - 1) * recordsPerPage) + 1;
    var endingRecord = Math.min((currentPage * recordsPerPage), totalItemCount);
    return "".concat(startingRecord.toString(), " - ").concat(endingRecord);
};
