import React from 'react';
import Skeleton from 'react-loading-skeleton';
export default (function (_a) {
    var recordsPerPage = _a.recordsPerPage;
    var rows = [];
    for (var i = 0; i < recordsPerPage; i++) {
        rows.push(i);
    }
    return (React.createElement(React.Fragment, null, rows.map(function (row, index) {
        return React.createElement("tbody", { key: index },
            React.createElement("tr", null,
                React.createElement("td", { colSpan: 100 },
                    React.createElement(Skeleton, null))));
    })));
});
