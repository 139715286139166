import React from 'react';
import { Routes, Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import AgentApp from '../parts/agentApp/AgentApp';
import { useRouteBuilder } from './useRouteBuilder';
import RouteTypes from './routeType';
export default (function () {
    var _a = useRouteBuilder(), getRoutes = _a.getRoutes, getLoginRoute = _a.getLoginRoute;
    var loginRoute = getLoginRoute();
    return (React.createElement(BrowserRouter, null,
        React.createElement(Routes, null,
            React.createElement(Route, { path: "/agent", element: React.createElement(AgentApp, null) }, getRoutes(RouteTypes.Agent).map(function (route, index) {
                return React.createElement(Route, { key: route.pathName, index: index === 0 ? true : undefined, path: route.pathName, element: route.element });
            })),
            React.createElement(Route, { path: loginRoute.pathName, element: loginRoute.element }))));
});
